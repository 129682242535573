<template>
  <div v-if="loading">
    <v-row>
      <v-progress-linear
        v-if="loading"
        absolute
        color="#1bb394"
        :size="100"
        :indeterminate="loading"
      ></v-progress-linear>
    </v-row>
  </div>
  <div v-else-if="getEvents || getOneStation" v-resize="onResize">
    <!-- {{ getEvents }} -->
    <v-card elevation="2" class="mt-2 card_content">
      <div id="header-table">
        <v-row align="center">
          <v-col cols="5" xs="3" sm="3" md="3" lg="2" class="ml-4 pl-4">
            <v-img :src="require('../assets/station.jpg')" max-width="300px" />
          </v-col>
          <v-col cols="5" lg="5" md="5" sm="5" xs="6">
            <v-card-text>
              <span>สถานี:</span>
              <br />
              <div>
                <span>
                  <h1 v-if="!isMobile">{{ getOneStation.name }}</h1>

                  <h3 v-else>{{ getOneStation.name }}</h3>
                </span>
              </div>
            </v-card-text>
          </v-col>
        </v-row>
      </div>

      <br />
      <v-divider />

      <div id="body-table">
        <v-row align="center">
          <v-col cols="12" lg="10" sm="12" md="10" xs="10">
            <v-tabs v-model="tab" color="#1bb394" grow>
              <v-tab @click="isHistory = true">
                <v-icon left>mdi-bell-ring</v-icon>
                <span>เหตุการณ์</span>
              </v-tab>
              <v-tab @click="isHistory = false">
                <v-icon left>mdi-history</v-icon>
                <span>ประวัติ</span>
              </v-tab>
            </v-tabs>
          </v-col>

          <v-col
            lg="1"
            md="1"
            sm="11"
            v-if="isHistory != false"
            class="ml-3 mr-3"
          >
            <v-btn
              color="#1bb394"
              small
              block
              dark
              outlined
              @click="clearDialog = true"
            >
              clear
            </v-btn>
          </v-col>
          <v-col lg="1" md="1" sm="11" v-else class="ml-3 mr-3">
            <v-btn dark outlined small block color="#1bb394">
              <download-excel
                :data="getReports"
                :fields="modelEx"
                type="csv"
                name="history_water_control.csv"
              >
                <!-- <v-icon> mdi-download </v-icon> -->
                dowload
              </download-excel>
            </v-btn>
          </v-col>
        </v-row>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-layout column>
              <v-data-table
                :items="getEvents"
                :headers="headers"
                :items-per-page="5"
                item-key="id"
              >
                <template v-slot:[`item.type`]="{ item }">
                  <div
                    class="mx-2"
                    v-if="item.type == null || item.type == undefined"
                  >
                    -
                  </div>
                  <div v-else class="d-flex align-center">{{ item.type }}</div>
                </template>
                <template v-slot:[`item.user`]="{ item }">
                  <div class="mx-6" v-if="item.user == null">-</div>
                  <div v-else class="d-flex align-center">
                    {{ item.user.username }}
                  </div>
                </template>
              </v-data-table>
            </v-layout>
          </v-tab-item>

          <v-tab-item>
            <v-data-table
              :headers="hisHeaders"
              :items="getReports"
              :items-per-page="5"
            >
              <template v-slot:[`item.type`]="{ item }">
                <div
                  class="mx-2"
                  v-if="item.type == null || item.type == undefined"
                >
                  -
                </div>
                <div v-else class="d-flex align-center">{{ item.type }}</div>
              </template>
              <template v-slot:[`item.user`]="{ item }">
                <div class="mx-6" v-if="item.user == null">-</div>
                <div v-else class="d-flex align-center">
                  {{ item.user.username }}
                </div>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-card>

    <v-dialog persistent v-model="clearDialog" width="400">
      <v-card>
        <v-card-title>
          <span>Clear</span>
          <v-spacer></v-spacer>
          <span>
            <v-btn icon @click="clearDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </span>
        </v-card-title>
        <v-card-content>
          <br />
          <v-card-text class="body">
            <v-row justify="center" align="center">
              <v-icon x-large color="error"> mdi-text-box-minus-outline</v-icon>
            </v-row>
            <br />
            <v-row justify="center" align="center">
              Are you sure to clear this table ?
            </v-row>
          </v-card-text>
          <br />
          <v-card-actions>
            <v-btn block text color="error" @click="clearFn()">
              Clear now
            </v-btn>
          </v-card-actions>
        </v-card-content>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  name: "WatercontrolAppAlarm",

  data() {
    return {
      loading: true,
      name: "",
      isHistory: true,
      clearDialog: false,
      isMobile: false,
      tab: null,
      data: {
        id: "",
        macAddress: "",
      },
      headers: [
        {
          text: "Date Time Start",
          value: "dateStart",
          sortable: true,
          width: "12%",
        },
        {
          text: "Date Time End",
          value: "dateEnd",
          sortable: true,
          width: "12%",
        },
        { text: "Station", value: "macAddress", sortable: true },
        { text: "Type", value: "type", sortable: true, width: "13%" },
        { text: "Description", value: "description", sortable: true },
        { text: "Username", value: "user", sortable: false, width: "12%" },
      ],
      hisHeaders: [
        {
          text: "Date Time Start",
          value: "dateStart",
          sortable: true,
          width: "12%",
        },
        {
          text: "Date Time End",
          value: "dateEnd",
          sortable: true,
          width: "12%",
        },
        { text: "Station", value: "macAddress", sortable: true },
        { text: "Type", value: "type", sortable: true, width: "13%" },
        { text: "Description", value: "description", sortable: true },
        { text: "Username", value: "user", sortable: false, width: "12%" },
        // { text: "Status", value: "status", sortable: true },
      ],
      modelEx: {
        Time_Start: "dateStart",
        Time_End: "dateEnd",
        station: "macAddress",
        Pond: "pond.name",
        Pump: "pump.name",
        Type: "description",
      },
    };
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    getEvents() {
      // console.log(this.$store.getters.isEvents);
      return this.$store.getters.isEvents;
    },
    getOneStation() {
      return this.$store.getters.station;
    },
    getReports() {
      return this.$store.getters.reportStation;
    },
  },

  async created() {
    // console.log(this.$route.params.data);
    // if (this.$route.params.data != null) {
    //   this.data.id = this.$route.params.data.id;
    //   this.data.macAddress = this.$route.params.data.macAddress;
    //   this.data.name = this.$route.params.data.name;
    //   // console.log(this.data.name);

    //   Cookies.set("stationID", this.data.id);
    //   Cookies.set("macAddress", this.data.macAddress);
    //   Cookies.set("name", this.data.name);
    //   // console.log(this.data);
    // } else {
    this.data.id = Cookies.get("stationID");
    this.data.macAddress = Cookies.get("macAddress");

    // console.log(this.data);
    if (this.data.id != undefined) {
      this.$store.dispatch("getOneStation", this.data.id).then(() => {
        this.loading = false;
      });
    }
    // }
  },

  mounted() {
    // console.log(this.currentUser);
    if (!this.currentUser) {
      this.$router.push("/");
    } else {
      this.loaderPage();
    }
  },

  methods: {
    eventName() {
      let data = this.getEvents;
      const event = data.findIndex((e) => e.name == data.macAddress);

      if (event > -1) {
        this.name = data[event].station.name;
        console.log(this.name);
      }
    },

    onResize() {
      if (window.innerWidth <= 600) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },

    borderActions(data) {
      console.log(data);
      if (data.status == true) {
        return {
          borderLeft: "8px solid green",
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
        };
      }
      if (data.status == false) {
        return {
          borderLeft: "8px solid red",
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
        };
      }
    },
    clearFn() {
      this.$store.dispatch("setClear", this.data.id).then(() => {
        this.$store.dispatch("getEvents", this.data.id).then(() => {
          console.log("clear");
          this.clearDialog = false;
        });
      });
    },
    loaderPage() {
      this.data.id = Cookies.get("stationID");
      this.data.macAddress = Cookies.get("macAddress");

      this.$store.dispatch("getEvents", this.data.id);
      this.$store.dispatch("getReportByID", this.data.id);
    },
  },
};
</script>

<style scoped>
.clear {
  width: 100;
  background: red;
}

.card_content {
  opacity: 0;
  transform: translateX(20px);
  animation: slideIn 0.2s ease-in-out forwards 0.2s;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
</style>
