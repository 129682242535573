import Vue from "vue";
import Vuex from "vuex";

import { auth } from "./auth.module";
import { account } from "./accounts.module";
import { events } from "./events.module";
import { stations } from "./stations.module";
import { reports } from "./reports.module";
import { ponds } from "./ponds.module";
import { pumps } from "./pumps.module";
import { charts } from "./charts.module";
import { records } from "./records.module";
import { times } from "./time.module";

Vue.use(Vuex);

export default new Vuex.Store({
  // state: {},
  // getters: {},
  // mutations: {},
  // actions: {},
  modules: {
    auth,
    account,
    events,
    stations,
    reports,
    ponds,
    pumps,
    charts,
    records,
    times,
  },
});
