<template>
  <div v-if="loading">
    <v-row>
      <v-progress-linear
        v-if="loading"
        absolute
        color="#1bb394"
        :size="100"
        :indeterminate="loading"
      >
      </v-progress-linear>
    </v-row>
  </div>
  <div v-else class="container" v-resize="onResize">
    <!-- {{ getStations }} -->
    <v-row justify="start">
      <v-col
        class="d-flex justify-center pt-1"
        v-for="(item, index) in getStations"
        :key="index"
        cols="12"
        xs="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-card elevation="4" class="card" width="300" v-if="!isMobile">
          <v-card-title
            style="background: #2b9474; color: #f5f5f5; top: 0; padding-top: 0"
          >
            <v-row>
              <v-col cols="auto">
                <small class="card_title">สถานี</small>
                <h2 class="card_title_inner">{{ item.name }}</h2>
              </v-col>
            </v-row>
          </v-card-title>

          <v-list-item id="status" class="ma-0 pa-0" style="padding-top: 0">
            <v-row>
              <v-col>
                <v-list-item-content class="content">
                  <v-list-item-subtitle class="content_subtitle_status">
                    สถานะ
                  </v-list-item-subtitle>

                  <v-list-item-title
                    v-if="item.status == true"
                    class="content_title_status"
                    style="color: green"
                  >
                    <b>ONLINE</b>
                  </v-list-item-title>
                  <v-list-item-title
                    v-else
                    class="content_title_status"
                    style="color: red"
                  >
                    <b>OFFLINE</b>
                  </v-list-item-title>
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>

          <v-list-item
            id="flow"
            class="ma-0 pa-0"
            style="padding-top: 0; margin-top: 0"
          >
            <v-row>
              <v-col style="padding-top: 0; padding-bottom: 0">
                <v-list-item-content>
                  <v-list-item-subtitle class="content_subtitle_flow">
                    ปริมาณการไหล
                  </v-list-item-subtitle>
                  <br />
                  <v-list-item-title class="content_title_flow">
                    <v-row>
                      <v-col
                        cols="12"
                        class="d-flex justify-center mt-2"
                        style="font-size: 30px; font-weight: bold"
                      >
                        {{ item.totalFlow }}
                      </v-col>
                      <v-col
                        cols="12"
                        class="d-flex justify-center"
                        style="font-size: 12px; padding-top: 0"
                      >
                        <!-- <p>m <sup>3</sup>.</p> -->
                        <p>ลูกบาศก์เมตร</p>
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                  <br />
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>

          <v-list-item
            id="level"
            class="ma-0 pa-0"
            style="padding-top: 0; margin-top: 0"
          >
            <v-row>
              <v-col>
                <v-list-item-content>
                  <v-list-item-subtitle class="pt-1 content_subtitle_level">
                    ระดับน้ำ
                  </v-list-item-subtitle>
                  <br />
                  <v-row>
                    <v-col v-for="(level, index) in item.level" :key="index">
                      <v-list-item-title class="content_title_level">
                        <br />
                        <v-row>
                          <v-col
                            cols="12"
                            class="d-flex justify-center"
                            style="font-size: 15px; font-weight: bold"
                          >
                            บ่อที่ {{ level.name.slice(5) }}
                          </v-col>

                          <v-col
                            cols="12"
                            class="d-flex justify-center"
                            style="font-size: 30px; font-weight: bold"
                          >
                            {{ level.value }}
                          </v-col>
                          <v-col
                            cols="12"
                            class="d-flex justify-center"
                            style="font-size: 12px"
                          >
                            เมตร
                          </v-col>
                        </v-row>
                        <br />
                      </v-list-item-title>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>

          <v-list-item class="btnUp" style="margin-top: 0; padding-top: 0">
            <v-tooltip
              top
              v-if="getAccess != null || getAccess != undefined"
              style="padding-top: 0"
            >
              <template v-slot:activator="{ on, attr }">
                <div v-bind="attr" v-on="on">
                  <!-- <router-link
                    :to="{ name: 'Events', params: { data: item } }"
                    style="color: inherit; text-decoration: none"
                  > -->
                  <v-btn icon @click="event(item)">
                    <v-icon>mdi-alarm-light</v-icon>
                  </v-btn>
                  <!-- </router-link> -->
                </div>
              </template>
              <span>เหตุการณ์</span>
            </v-tooltip>

            <v-tooltip top v-if="getAccess != null">
              <template v-slot:activator="{ attr, on }">
                <div v-bind="attr" v-on="on">
                  <!-- <router-link
                    :to="{ name: 'ControlSystem', params: { data: item } }"
                    style="color: inherit; text-decoration: none"
                  > -->
                  <v-btn icon @click="controlSystem(item)">
                    <v-icon>mdi-tune-variant</v-icon>
                  </v-btn>
                  <!-- </router-link>  -->
                </div>
              </template>
              <span>แผงควบคุมระบบสูบน้ำ</span>
            </v-tooltip>

            <v-tooltip top v-else>
              <template v-slot:activator="{ attr, on }">
                <div v-bind="attr" v-on="on">
                  <router-link
                    :to="{ name: 'ControlSystem', params: { data: item } }"
                    style="color: inherit; text-decoration: none"
                  >
                    <v-btn icon @click="controlSystem(item)">
                      <v-icon>mdi-tune-variant</v-icon>
                    </v-btn>
                  </router-link>
                </div>
              </template>
              <span>การทำงานระบบสูบน้ำ</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ attr, on }">
                <div v-bind="attr" v-on="on">
                  <!-- <router-link
                    :to="{ name: 'Reports', params: { data: item } }"
                    style="color: inherit; text-decoration: none"
                  > -->
                  <v-btn icon @click="report(item)">
                    <v-icon>mdi-chart-line</v-icon>
                  </v-btn>
                  <!-- </router-link> -->
                </div>
              </template>
              <span>รายงาน</span>
            </v-tooltip>
          </v-list-item>
        </v-card>

        <v-card max-width="400" v-else>
          <v-list-item
            style="
              background: linear-gradient(to left, #f5f5f5 98%, #1bb394 98%);
            "
          >
            <v-row>
              <v-col cols="4">
                <v-list-item-content>
                  <v-list-item-title>
                    <small>สถานี</small>
                    <p style="font-size: 0.85em; font-weight: bold">
                      {{ item.name }}
                    </p>
                  </v-list-item-title>
                  <div class="my-2">
                    <v-list-item-subtitle
                      v-if="item.status"
                      style="color: green"
                    >
                      <span>
                        <v-icon small class="mb-1" color="success"
                          >mdi-rss
                        </v-icon>
                        Online
                      </span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else style="color: red">
                      <span>
                        <v-icon small color="error" class="mb-1"
                          >mdi-rss-off
                        </v-icon>
                        Offline
                      </span>
                    </v-list-item-subtitle>
                  </div>
                </v-list-item-content>
              </v-col>

              <v-col cols="5">
                <v-list-item-content>
                  <v-list-item-subtitle class="">
                    <small>ปริมาณการไหล: </small>
                  </v-list-item-subtitle>
                  <v-list-item-title class="">
                    <v-row align="center">
                      <v-col
                        cols="12"
                        class="d-flex justify-center"
                        style="font-size: 13px; font-weight: bold"
                      >
                        {{ item.totalFlow }}
                        <v-spacer></v-spacer>
                        ลบ.ม.
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-content>
                  <v-list-item-subtitle class="">
                    <small>ระดับน้ำ: </small>
                  </v-list-item-subtitle>

                  <v-list-item-title
                    class=""
                    v-for="(level, index) in item.level"
                    :key="index"
                  >
                    <v-row>
                      <v-col
                        class="d-flex justify-start"
                        style="font-size: 13px; font-weight: bold"
                      >
                        บ่อที่ {{ level.name.slice(5) }}:
                        <v-spacer></v-spacer>
                        {{ level.value }}
                        <v-spacer></v-spacer>
                        ม.
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-col>

              <v-col cols="3">
                <v-list-item-content class="content">
                  <div
                    v-if="getAccess != null || getAccess != undefined"
                    class="my-2"
                  >
                    <v-btn
                      small
                      @click="event(item)"
                      color="error3"
                      class="content_subtitle_status"
                    >
                      <v-icon>mdi-alarm-light</v-icon>
                    </v-btn>
                  </div>

                  <br />

                  <div class="my-2">
                    <router-link
                      :to="{
                        name: 'ControlSystem',
                        params: { data: item },
                      }"
                      style="color: inherit; text-decoration: none"
                    >
                      <v-btn
                        small
                        @click="controlSystem(item)"
                        class="content_subtitle_flow"
                      >
                        <v-icon>mdi-tune-variant</v-icon>
                      </v-btn>
                    </router-link>
                  </div>

                  <br />

                  <div class="my-2">
                    <v-btn
                      small
                      @click="report(item)"
                      class="content_subtitle_level"
                    >
                      <v-icon>mdi-chart-line</v-icon>
                    </v-btn>
                  </div>
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  data() {
    return {
      data: null,
      isMobile: false,
      isTablet: false,
      loading: true,
    };
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    getAccess() {
      // console.log(this.$store.state.auth.access);
      return this.$store.state.auth.access;
    },
    getStations() {
      return this.$store.getters.stations;
    },
  },

  mounted() {
    // console.log(this.currentUser);
    // if (!this.currentUser) {
    // this.$router.push("/");
    this.$store.dispatch("getStations").then((res) => {
      this.loading = false;
    });
    // } else {
    // this.$store.dispatch("getStations");

    // }
  },

  created() {
    this.$store.dispatch("auth/decode");
  },

  methods: {
    onResize() {
      if (window.innerWidth < 600) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },

    event(data) {
      console.log(data);
      Cookies.set("stationID", data._id);
      Cookies.set("macAddress", data.macAddress);
      this.$router.push("/events");
    },

    report(data) {
      console.log(data);
      Cookies.set("stationID", data._id);
      Cookies.set("macAddress", data.macAddress);
      this.$router.push("/reports");
    },

    controlSystem(data) {
      // console.log(this.$route.params.data);
      // if (this.$route.params.data != null) {
      // let station = this.$route.params.data;
      Cookies.set("stationID", data._id);
      Cookies.set("macAddress", data.macAddress);
      this.$router.push("/ControlSystem");
      // }
    },
  },
};
</script>

<style scoped>
font {
  font-size: 14px;
  font-weight: 200;
}

b {
  font-size: 24px;
  justify-content: center;
}
.card {
  border-radius: 10px;
}

.content_subtitle_status {
  display: flex;
  justify-content: center;

  font-size: 18px;

  opacity: 0;
  transform: translateX(20px);
  animation: slideIn 0.8s ease forwards 0.2s;
}

.content_subtitle_flow {
  display: flex;
  justify-content: center;

  font-size: 18px;

  opacity: 0;
  transform: translateX(20px);
  animation: slideIn 0.8s ease forwards 0.4s;
}

.content_subtitle_level {
  display: flex;
  justify-content: center;

  font-size: 18px;

  opacity: 0;
  transform: translateX(20px);
  animation: slideIn 0.8s ease forwards 0.6s;
}

.content_title_status {
  display: flex;
  justify-content: center;

  font-size: 25px;

  opacity: 0;
  transform: translateX(20px);
  animation: slideIn 0.8s ease forwards 0.3s;
}

.content_title_flow {
  display: flex;
  justify-content: center;

  font-size: 30px;

  opacity: 0;
  transform: translateX(20px);
  animation: slideIn 0.8s ease forwards 0.5s;
}

.content_title_level {
  opacity: 0;
  transform: translateX(20px);
  animation: slideIn 0.8s ease forwards 0.7s;
}

.btnUp {
  opacity: 0;
  display: flex;
  justify-content: center;
  transform: translateX(20px);
  animation: slideIn 0.8s ease-in-out forwards 0.9s;
}

@keyframes slideIn {
  0% {
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>
