var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loader)?_c('div',[(_vm.loader)?_c('v-progress-linear',{staticClass:"mb-2",attrs:{"size":100,"color":"#1bb394","indeterminate":_vm.loader,"absolute":"","top":""}}):_vm._e()],1):_c('div',[_c('div',{staticStyle:{"justify-content":"center"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"6"}},[_c('v-card',{attrs:{"elevation":"4"}},[_c('v-card-title',{staticClass:"d-flex justify-end"},[_c('h6',{staticClass:"mt-5 mb-5"},[_vm._v(_vm._s(_vm.getChart.text))]),_c('v-spacer'),_c('v-btn-toggle',{attrs:{"mandatory":""},model:{value:(_vm.toggle_exclusive),callback:function ($$v) {_vm.toggle_exclusive=$$v},expression:"toggle_exclusive"}},[_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.setDates(_vm.days[0])}}},[_vm._v("1 วัน")]),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.setDates(_vm.days[1])}}},[_vm._v("3 วัน")]),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.setDates(_vm.days[2])}}},[_vm._v("7 วัน")]),_c('v-menu',{ref:"menu",staticStyle:{"z-index":"20"},attrs:{"close-on-content-click":false,"return-value":_vm.dates,"transition":"slide-y-transition","left":"","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dates=$event},"update:return-value":function($event){_vm.dates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"color":"#1bb394","range":"","scrollable":"","no-title":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"#1bb394"},on:{"click":function($event){return _vm.searchDate()}}},[_vm._v(" Ok ")])],1)],1),(
                    _vm.getAccess != null &&
                    (_vm.getAccess.role == 'Admin' ||
                      _vm.getAccess.role == 'Superadmin')
                  )?_c('v-menu',{staticStyle:{"z-index":"20"},attrs:{"min-width":"auto","left":"","offset-y":"","transition":"slide-y-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var attrs = ref.attrs;
                  var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-wrench-outline")])],1)]}}],null,false,4067903353)},[_c('v-card',{staticClass:"pa-2"},[_c('v-card-text',[_c('v-text-field',{attrs:{"color":"#1bb394","label":"Set Time","suffix":"min"},model:{value:(_vm.setTimeLog.timeLog),callback:function ($$v) {_vm.$set(_vm.setTimeLog, "timeLog", $$v)},expression:"setTimeLog.timeLog"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"small":"","block":"","color":"#1bb394","dark":""},on:{"click":function($event){return _vm.setTime()}}},[_vm._v(" set now ")])],1)],1)],1):_vm._e()],1)],1),_c('v-card-text',[_c('div',{staticClass:"justify-center text-center"},[(_vm.loadingChart)?_c('v-progress-circular',{staticStyle:{"position":"relative","top":"20vh","z-index":"2"},attrs:{"indeterminate":"","color":"teal"}}):_vm._e()],1),_c('apexchart',{ref:"chart",staticClass:"d-flex justify-center",attrs:{"type":"line","options":_vm.getChart.options,"series":_vm.getChart.series,"loading":_vm.loading}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"6"}},[_c('v-card',{attrs:{"elevation":"4"}},[_c('v-card-title',{staticClass:"d-flex justify-end"},[_c('h6',{staticClass:"mt-5 mb-5"},[_vm._v(_vm._s(_vm.getTime.text))]),_c('v-spacer'),_c('v-menu',{ref:"menu3",staticStyle:{"z-index":"20"},attrs:{"close-on-content-click":false,"return-value":_vm.dates,"transition":"slide-y-transition","left":"","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dates=$event},"update:return-value":function($event){_vm.dates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar")])],1)]}}]),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[_c('v-date-picker',{attrs:{"color":"#1bb394","range":"","scrollable":"","no-title":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"#1bb394"},on:{"click":function($event){return _vm.searchDateTime()}}},[_vm._v(" Ok ")])],1)],1)],1),_c('v-card-text',[_c('div',{staticClass:"justify-center text-center"},[(_vm.loadingTime)?_c('v-progress-circular',{staticStyle:{"position":"relative","top":"20vh","z-index":"2"},attrs:{"indeterminate":"","color":"teal"}}):_vm._e()],1),_c('apexchart',{ref:"chart",staticClass:"d-flex justify-center",attrs:{"type":"bar","options":_vm.getTime.options,"series":_vm.getTime.series,"loading":_vm.loading}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"6"}},[_c('v-card',{attrs:{"elevation":"4"}},[_c('v-card-title',{staticClass:"d-flex justify-end"},[_c('h6',{staticClass:"mt-5 mb-5"},[_vm._v(_vm._s(_vm.getFlow.text))]),_c('v-spacer'),_c('v-menu',{ref:"menu2",staticStyle:{"z-index":"20"},attrs:{"close-on-content-click":false,"return-value":_vm.dates,"transition":"slide-y-transition","left":"","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dates=$event},"update:return-value":function($event){_vm.dates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar")])],1)]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"color":"#1bb394","range":"","scrollable":"","no-title":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"#1bb394"},on:{"click":function($event){return _vm.searchDateFlow()}}},[_vm._v(" Ok ")])],1)],1)],1),_c('v-card-text',[_c('div',{staticClass:"justify-center text-center"},[(_vm.loadingFlow)?_c('v-progress-circular',{staticStyle:{"position":"relative","top":"20vh","z-index":"2"},attrs:{"indeterminate":"","color":"teal"}}):_vm._e()],1),_c('apexchart',{ref:"chart",staticClass:"d-flex justify-center",attrs:{"type":"bar","options":_vm.getFlow.options,"series":_vm.getFlow.series,"loading":_vm.loading}})],1)],1)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"top":"","right":"","color":_vm.color},model:{value:(_vm.snackBar),callback:function ($$v) {_vm.snackBar=$$v},expression:"snackBar"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_vm._v(" "+_vm._s(_vm.text))]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"x-small":"","color":"white","icon":""},on:{"click":function($event){_vm.snackBar = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }