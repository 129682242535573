<template>
  <div v-if="loader">
    <v-progress-linear
      v-if="loader"
      :size="100"
      color="#1bb394"
      :indeterminate="loader"
      absolute
      top
      class="mb-2"
    >
    </v-progress-linear>
  </div>
  <div v-else>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1080 640"
      style="enable-background: new 0 0 1080 640"
      xml:space="preserve"
    >
      <g id="layouts">
        <g>
          <linearGradient
            id="SVGID_1_"
            gradientUnits="userSpaceOnUse"
            x1="1014.0596"
            y1="640"
            x2="1014.0596"
            y2="252.4503"
          >
            <stop offset="0" style="stop-color: #412312" />
            <stop offset="0.5642" style="stop-color: #8c633a" />
            <stop offset="0.7486" style="stop-color: #a57c52" />
            <stop offset="0.801" style="stop-color: #8a6443" />
            <stop offset="0.8946" style="stop-color: #60402a" />
            <stop offset="0.9636" style="stop-color: #492b1a" />
            <stop offset="1" style="stop-color: #412312" />
          </linearGradient>
          <path
            class="st0"
            d="M924.9,502.1V252.5h-39.7V640H1143v-37.9h-118.1C969.6,602.1,924.9,557.3,924.9,502.1z"
          />

          <linearGradient
            id="SVGID_00000060739705398757592760000000241325076894043068_"
            gradientUnits="userSpaceOnUse"
            x1="904.9868"
            y1="252.4503"
            x2="904.9868"
            y2="236.5563"
          >
            <stop offset="0" style="stop-color: #3c2415" />
            <stop offset="8.782995e-02" style="stop-color: #3d331e" />
            <stop offset="0.2346" style="stop-color: #394527" />
            <stop offset="0.3909" style="stop-color: #2e542f" />
            <stop offset="0.5592" style="stop-color: #1d5f34" />
            <stop offset="0.7481" style="stop-color: #016537" />
            <stop offset="1" style="stop-color: #006838" />
          </linearGradient>
          <path
            style="
              fill: url(#SVGID_00000060739705398757592760000000241325076894043068_);
            "
            d="M916.9,236.6h-31.8v15.9h39.7v0
			C924.9,243.7,921.3,236.6,916.9,236.6z"
          />
        </g>

        <linearGradient
          id="SVGID_00000123437830449496223440000006083125487424392106_"
          gradientUnits="userSpaceOnUse"
          x1="1033.9271"
          y1="602.1192"
          x2="1033.9271"
          y2="252.4503"
        >
          <stop offset="0" style="stop-color: #412312" />
          <stop offset="0.4972" style="stop-color: #8c633a" />
          <stop offset="0.72" style="stop-color: #a57c52" />
          <stop offset="0.8186" style="stop-color: #7b583a" />
          <stop offset="0.9418" style="stop-color: #50321f" />
          <stop offset="1" style="stop-color: #412312" />
        </linearGradient>
        <path
          style="
            fill: url(#SVGID_00000123437830449496223440000006083125487424392106_);
          "
          d="M924.9,252.5v249.7
		c0,55.2,44.8,100,100,100H1143V252.5L924.9,252.5z"
        />
        <path
          class="st3"
          d="M924.9,252.5v249.7c0,55.2,44.8,100,100,100H1143V252.5L924.9,252.5z"
        />
        <polygon
          class="st4"
          points="798.3,618 98.9,618 98.9,22 798.3,22 798.3,117.4 822.1,117.4 822.1,0 75.1,0 75.1,640 822.1,640 
		822.1,228.6 98.9,228.6 98.9,252.5 798.3,252.5 	"
        />
        <rect x="98.9" y="22" class="st5" width="699.3" height="206.6" />
        <rect x="820" y="228.6" class="st4" width="66" height="411.4" />
      </g>
      <g id="water">
        <linearGradient
          id="SVGID_00000073692210416464494170000005628959401198435506_"
          gradientUnits="userSpaceOnUse"
          x1="98.9404"
          y1="435.3003"
          x2="798.2781"
          y2="435.3003"
        >
          <stop offset="0" style="stop-color: #676767" />
          <stop offset="8.250325e-03" style="stop-color: #6f6e6e" />
          <stop offset="3.712930e-02" style="stop-color: #898787" />
          <stop offset="6.983375e-02" style="stop-color: #a2a0a0" />
          <stop offset="0.1068" style="stop-color: #b8b6b6" />
          <stop offset="0.1499" style="stop-color: #cac9c9" />
          <stop offset="0.2033" style="stop-color: #d8d8d8" />
          <stop offset="0.2782" style="stop-color: #e2e2e2" />
          <stop offset="0.486" style="stop-color: #e5e5e5" />
          <stop offset="0.7058" style="stop-color: #e2e2e2" />
          <stop offset="0.785" style="stop-color: #d8d8d8" />
          <stop offset="0.8415" style="stop-color: #cac9c9" />
          <stop offset="0.8871" style="stop-color: #b8b6b6" />
          <stop offset="0.9262" style="stop-color: #a2a0a0" />
          <stop offset="0.9607" style="stop-color: #898787" />
          <stop offset="0.9913" style="stop-color: #6f6e6e" />
          <stop offset="1" style="stop-color: #676767" />
        </linearGradient>

        <rect
          x="98.9"
          y="252.5"
          style="
            fill: url(#SVGID_00000073692210416464494170000005628959401198435506_);
          "
          width="699.3"
          height="365.7"
        />
        <rect
          :class="
            getPumps[0].pond.level.value >= getPumps[0].pond.level.highHigh
              ? 'red'
              : 'st7'
          "
          x="98.9"
          y="-620"
          width="699.3"
          height="1em"
          transform="scale(1,-1)"
          v-bind:style="{
            fontSize: fontSizeWater(getPumps[0].pond.level) + 'px',
          }"
        />
        />
      </g>
      <g id="line">
        <rect class="st8" width="75.1" height="640" />
        <line class="st9" x1="75.1" y1="252.5" x2="51.3" y2="252.5" />
        <line class="st9" x1="75.1" y1="259.8" x2="64.9" y2="259.8" />
        <line class="st9" x1="75.1" y1="267.1" x2="64.9" y2="267.1" />
        <line class="st9" x1="75.1" y1="274.4" x2="64.9" y2="274.4" />
        <line class="st9" x1="75.1" y1="281.7" x2="64.9" y2="281.7" />
        <line class="st9" x1="75.1" y1="289" x2="59.2" y2="289" />
        <line class="st9" x1="75.1" y1="296.3" x2="64.9" y2="296.3" />
        <line class="st9" x1="75.1" y1="303.6" x2="64.9" y2="303.6" />
        <line class="st9" x1="75.1" y1="311" x2="64.9" y2="311" />
        <line class="st9" x1="75.1" y1="318.3" x2="64.9" y2="318.3" />
        <line class="st9" x1="75.1" y1="325.6" x2="59.2" y2="325.6" />
        <line class="st9" x1="75.1" y1="332.9" x2="64.9" y2="332.9" />
        <line class="st9" x1="75.1" y1="340.2" x2="64.9" y2="340.2" />
        <line class="st9" x1="75.1" y1="347.5" x2="64.9" y2="347.5" />
        <line class="st9" x1="75.1" y1="354.8" x2="64.9" y2="354.8" />
        <line class="st9" x1="75.1" y1="362.2" x2="59.2" y2="362.2" />
        <line class="st9" x1="75.1" y1="369.5" x2="64.9" y2="369.5" />
        <line class="st9" x1="75.1" y1="376.8" x2="64.9" y2="376.8" />
        <line class="st9" x1="75.1" y1="384.1" x2="64.9" y2="384.1" />
        <line class="st9" x1="75.1" y1="391.4" x2="64.9" y2="391.4" />
        <line class="st9" x1="75.1" y1="398.7" x2="59.2" y2="398.7" />
        <line class="st9" x1="75.1" y1="406" x2="64.9" y2="406" />
        <line class="st9" x1="75.1" y1="413.4" x2="64.9" y2="413.4" />
        <line class="st9" x1="75.1" y1="420.7" x2="64.9" y2="420.7" />
        <line class="st9" x1="75.1" y1="428" x2="64.9" y2="428" />
        <line class="st9" x1="75.1" y1="435.3" x2="59.2" y2="435.3" />
        <line class="st9" x1="75.1" y1="442.6" x2="64.9" y2="442.6" />
        <line class="st9" x1="75.1" y1="449.9" x2="64.9" y2="449.9" />
        <line class="st9" x1="75.1" y1="457.2" x2="64.9" y2="457.2" />
        <line class="st9" x1="75.1" y1="464.6" x2="64.9" y2="464.6" />
        <line class="st9" x1="75.1" y1="471.9" x2="59.2" y2="471.9" />
        <line class="st9" x1="75.1" y1="479.2" x2="64.9" y2="479.2" />
        <line class="st9" x1="75.1" y1="486.5" x2="64.9" y2="486.5" />
        <line class="st9" x1="75.1" y1="493.8" x2="64.9" y2="493.8" />
        <line class="st9" x1="75.1" y1="501.1" x2="64.9" y2="501.1" />
        <line class="st9" x1="75.1" y1="508.4" x2="59.2" y2="508.4" />
        <line class="st9" x1="75.1" y1="515.8" x2="64.9" y2="515.8" />
        <line class="st9" x1="75.1" y1="523.1" x2="64.9" y2="523.1" />
        <line class="st9" x1="75.1" y1="530.4" x2="64.9" y2="530.4" />
        <line class="st9" x1="75.1" y1="537.7" x2="64.9" y2="537.7" />
        <line class="st9" x1="75.1" y1="545" x2="59.2" y2="545" />
        <line class="st9" x1="75.1" y1="552.3" x2="64.9" y2="552.3" />
        <line class="st9" x1="75.1" y1="559.6" x2="64.9" y2="559.6" />
        <line class="st9" x1="75.1" y1="567" x2="64.9" y2="567" />
        <line class="st9" x1="75.1" y1="574.3" x2="64.9" y2="574.3" />
        <line class="st9" x1="75.1" y1="581.6" x2="59.2" y2="581.6" />
        <line class="st9" x1="75.1" y1="588.9" x2="64.9" y2="588.9" />
        <line class="st9" x1="75.1" y1="596.2" x2="64.9" y2="596.2" />
        <line class="st9" x1="75.1" y1="603.5" x2="64.9" y2="603.5" />
        <line class="st9" x1="75.1" y1="610.8" x2="64.9" y2="610.8" />
        <line class="st9" x1="75.1" y1="618.2" x2="51.3" y2="618.2" />
      </g>
      <g id="pumps">
        <g id="pump01">
          <g id="pipe">
            <linearGradient
              id="SVGID_00000037654872272660140520000010290643260314309526_"
              gradientUnits="userSpaceOnUse"
              x1="290.1176"
              y1="340.6705"
              x2="385.4818"
              y2="340.6705"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.776963e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>

            <rect
              x="290.1"
              y="109.4"
              style="
                fill: url(#SVGID_00000037654872272660140520000010290643260314309526_);
              "
              width="95.4"
              height="462.5"
            />
            <polygon
              class="st4"
              points="385.5,228.6 393.4,220.7 369.6,180.9 			"
            />

            <linearGradient
              id="SVGID_00000082348509988814910900000016078010320014097822_"
              gradientUnits="userSpaceOnUse"
              x1="282.1705"
              y1="127.798"
              x2="393.4288"
              y2="127.798"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.776963e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>

            <rect
              x="282.2"
              y="125.3"
              style="
                fill: url(#SVGID_00000082348509988814910900000016078010320014097822_);
              "
              width="111.3"
              height="5"
            />

            <linearGradient
              id="SVGID_00000176022468301806933760000014373312831687316619_"
              gradientUnits="userSpaceOnUse"
              x1="277.2997"
              y1="105.4305"
              x2="398.2997"
              y2="105.4305"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.776963e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>

            <rect
              x="277.3"
              y="101.5"
              style="
                fill: url(#SVGID_00000176022468301806933760000014373312831687316619_);
              "
              width="121"
              height="7.9"
            />

            <linearGradient
              id="SVGID_00000088105410249030137710000004088920415706229167_"
              gradientUnits="userSpaceOnUse"
              x1="455.0182"
              y1="220.3692"
              x2="455.0182"
              y2="147.3262"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.776963e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000088105410249030137710000004088920415706229167_);
              "
              points="393.4,220.4 540.4,220.4 
              467.4,147.3 386.7,147.3 369.6,180.6 			"
            />
          </g>
          <g id="pump">
            <rect x="318.1" y="397" class="st14" width="39.6" height="1.5" />
            <rect x="308.9" y="419.6" class="st14" width="57.9" height="1.5" />
            <rect x="308.9" y="418.1" class="st14" width="57.9" height="1.5" />
            <path
              class="st14"
              d="M356.2,395.5h-36.6c-0.8,0-1.5,0.7-1.5,1.5l0,0h39.6l0,0C357.7,396.2,357,395.5,356.2,395.5z"
            />
            <path
              :class="pumpStatus(getPumps[0].pumps[0].runPump)"
              d="M306,428.6l1.6,4.9c0.7,2.1,1,4.3,1,6.5v42.3h58.6V441c0-2.9,0.6-5.7,1.8-8.4l1.7-4H306z"
            />
            <polygon
              class="st14"
              points="367.2,527.8 367.2,527.8 308.7,527.8 308.7,527.8 			"
            />
            <polygon
              :class="pumpStatus(getPumps[0].pumps[0].runPump)"
              points="366.4,530.8 365.2,535.4 364,539.8 311.9,539.8 310.7,535.4 309.5,530.8 			"
            />
            <path
              class="st16"
              d="M304.3,545.8l-0.5,1.4c-2.8,7-7.4,13.5-13.7,18.9l0,0h95.6l0,0c-6.2-5.4-10.9-11.9-13.7-18.9l-0.5-1.4
              H304.3z"
            />
            <path
              :class="pumpStatus(getPumps[0].pumps[0].runPump)"
              d="M308.9,425.6v-4.5v-3l9.2-19.5V397c0-0.8,0.9-1.5,1.9-1.5h35.7c1.1,0,1.9,0.7,1.9,1.5v1.5l9.2,19.5v3v4.5
              H308.9z"
            />
            <path
              class="st14"
              d="M356.7,395.1l-9-4h-19.5l-9.1,4c-0.6,0.3-1,0.8-1,1.3v2.1h39.6v-2.1C357.7,395.9,357.3,395.4,356.7,395.1z"
            />
            <path class="st17" d="M318.1,436.3" />
            <polygon
              :class="pumpStatus(getPumps[0].pumps[0].runPump)"
              points="367.2,527.8 367.5,504.5 376.6,489.5 298.6,489.5 308.6,504.5 308.7,527.8 			"
            />
            <path
              class="st18"
              d="M317.8,479.3L317.8,479.3c-1.1,0-1.9-0.9-1.9-1.9v-41.3c0-1.1,0.9-1.9,1.9-1.9h0c1.1,0,1.9,0.9,1.9,1.9
              v41.3C319.7,478.5,318.8,479.3,317.8,479.3z"
            />
            <path
              class="st18"
              d="M328.4,479.3L328.4,479.3c-1.1,0-1.9-0.9-1.9-1.9v-41.3c0-1.1,0.9-1.9,1.9-1.9h0c1.1,0,1.9,0.9,1.9,1.9
              v41.3C330.3,478.5,329.5,479.3,328.4,479.3z"
            />
            <path
              class="st18"
              d="M338.1,479.3L338.1,479.3c-1.1,0-1.9-0.9-1.9-1.9v-41.3c0-1.1,0.9-1.9,1.9-1.9h0c1.1,0,1.9,0.9,1.9,1.9
              v41.3C340,478.5,339.1,479.3,338.1,479.3z"
            />
            <path
              class="st18"
              d="M347.7,479.3L347.7,479.3c-1.1,0-1.9-0.9-1.9-1.9v-41.3c0-1.1,0.9-1.9,1.9-1.9h0c1.1,0,1.9,0.9,1.9,1.9
              v41.3C349.6,478.5,348.8,479.3,347.7,479.3z"
            />
            <path
              class="st18"
              d="M357.4,479.3L357.4,479.3c-1.1,0-1.9-0.9-1.9-1.9v-41.3c0-1.1,0.9-1.9,1.9-1.9h0c1.1,0,1.9,0.9,1.9,1.9
              v41.3C359.3,478.5,358.4,479.3,357.4,479.3z"
            />
            <rect x="306" y="427.1" class="st14" width="64.7" height="1.5" />
            <rect x="306" y="425.6" class="st14" width="64.7" height="1.5" />
            <rect
              x="308.5"
              y="539.8"
              :class="pumpStatus(getPumps[0].pumps[0].runPump)"
              width="58.9"
              height="3"
            />
            <rect
              x="298.8"
              y="542.8"
              :class="pumpStatus(getPumps[0].pumps[0].runPump)"
              width="78.2"
              height="3"
            />
            <polygon
              class="st18"
              points="377,527.8 377,530.8 298.8,530.8 298.8,527.8 308.7,527.8 308.7,527.8 367.2,527.8 367.2,527.8 			
              "
            />
            <rect x="296" y="482" class="st18" width="84" height="7.5" />
          </g>
          <g id="crop">
            <linearGradient
              id="SVGID_00000054262690959371120360000013564455963629906102_"
              gradientUnits="userSpaceOnUse"
              x1="290.6003"
              y1="477.0945"
              x2="386.1603"
              y2="477.0945"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.776963e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>
            <path
              style="
                fill: url(#SVGID_00000054262690959371120360000013564455963629906102_);
              "
              d="M290.6,384.9v184.4h95.6V384.9H290.6z
               M381.9,524.2c0,18.9-19.5,34.2-43.5,34.2c-12,0-22.9-3.8-30.8-10c-7.9-6.2-12.7-14.8-12.7-24.2v-95.6
              c0-18.9,19.5-34.2,43.5-34.2c24,0,43.5,15.3,43.5,34.2V524.2z"
            />
            <path
              class="st20"
              d="M381.9,428.6v95.6c0,18.9-19.5,34.2-43.5,34.2c-12,0-22.9-3.8-30.8-10c-7.9-6.2-12.7-14.8-12.7-24.2v-95.6
              c0-18.9,19.5-34.2,43.5-34.2C362.4,394.4,381.9,409.7,381.9,428.6z"
            />
          </g>
        </g>
        <g id="pump02">
          <g id="pipe">
            <linearGradient
              id="SVGID_00000157291174181570516140000017155141297066617487_"
              gradientUnits="userSpaceOnUse"
              x1="436.2261"
              y1="355.7616"
              x2="516.8221"
              y2="355.7616"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.776963e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>

            <rect
              x="436.2"
              y="109.4"
              style="
                fill: url(#SVGID_00000157291174181570516140000017155141297066617487_);
              "
              width="80.6"
              height="492.7"
            />
            <polygon
              class="st4"
              points="514.8,228.6 522.8,220.7 498.9,180.9 			"
            />

            <linearGradient
              id="SVGID_00000052097050961487121230000016949561274854098052_"
              gradientUnits="userSpaceOnUse"
              x1="429.5098"
              y1="127.798"
              x2="523.5385"
              y2="127.798"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.776963e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>

            <rect
              x="429.5"
              y="125.3"
              style="
                fill: url(#SVGID_00000052097050961487121230000016949561274854098052_);
              "
              width="94"
              height="5"
            />

            <linearGradient
              id="SVGID_00000179608670163183606170000006377846381459587970_"
              gradientUnits="userSpaceOnUse"
              x1="425.3932"
              y1="105.4305"
              x2="527.655"
              y2="105.4305"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.776963e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>

            <rect
              x="425.4"
              y="101.5"
              style="
                fill: url(#SVGID_00000179608670163183606170000006377846381459587970_);
              "
              width="102.3"
              height="7.9"
            />

            <linearGradient
              id="SVGID_00000127738100406755753900000015731201946442701723_"
              gradientUnits="userSpaceOnUse"
              x1="551.2035"
              y1="220.6622"
              x2="551.2035"
              y2="149.1391"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.776963e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000127738100406755753900000015731201946442701723_);
              "
              points="522.8,220.7 498.9,180.9 
				514.8,149.1 603.5,149.1 603.5,220.7 			"
            />
          </g>
          <g id="pump">
            <rect x="459.3" y="462.7" class="st14" width="32.6" height="1.2" />
            <rect x="451.7" y="481.3" class="st14" width="47.8" height="1.2" />
            <rect x="451.7" y="480" class="st14" width="47.8" height="1.2" />
            <path
              class="st14"
              d="M490.7,461.4h-30.2c-0.7,0-1.2,0.6-1.2,1.2l0,0H492l0,0C492,462,491.4,461.4,490.7,461.4z"
            />
            <path
              :class="pumpStatus(getPumps[0].pumps[1].runPump)"
              d="M449.4,488.7l1.3,4c0.6,1.7,0.9,3.5,0.9,5.3V533h48.3v-34c0-2.4,0.5-4.7,1.5-6.9l1.4-3.3H449.4z"
            />
            <polygon
              class="st14"
              points="499.8,570.5 499.8,570.5 451.5,570.5 451.5,570.5 			"
            />
            <polygon
              :class="pumpStatus(getPumps[0].pumps[1].runPump)"
              points="499.2,573 498.1,576.8 497.2,580.4 454.2,580.4 453.2,576.8 452.2,573 			"
            />
            <path
              class="st16"
              d="M447.9,585.4l-0.4,1.1c-2.3,5.8-6.1,11.1-11.3,15.6h0H515l0,0c-5.1-4.5-9-9.8-11.3-15.6l-0.4-1.1H447.9z"
            />
            <path
              :class="pumpStatus(getPumps[0].pumps[1].runPump)"
              d="M451.7,486.2v-3.7V480l7.6-16.1v-1.2c0-0.7,0.7-1.2,1.6-1.2h29.5c0.9,0,1.6,0.6,1.6,1.2v1.2l7.6,16.1v2.5
				v3.7H451.7z"
            />
            <path
              class="st14"
              d="M491.1,461.1l-7.4-3.3h-16.1l-7.5,3.3c-0.5,0.2-0.8,0.6-0.8,1.1v1.8H492v-1.8
				C492,461.7,491.6,461.3,491.1,461.1z"
            />
            <path class="st17" d="M459.3,495.1" />
            <polygon
              :class="pumpStatus(getPumps[0].pumps[1].runPump)"
              points="499.8,570.5 500,551.3 507.6,538.9 443.2,538.9 451.5,551.3 451.5,570.5 			"
            />
            <path
              class="st18"
              d="M459,530.5L459,530.5c-0.9,0-1.6-0.7-1.6-1.6v-34c0-0.9,0.7-1.6,1.6-1.6l0,0c0.9,0,1.6,0.7,1.6,1.6v34
				C460.6,529.8,459.9,530.5,459,530.5z"
            />
            <path
              class="st18"
              d="M467.8,530.5L467.8,530.5c-0.9,0-1.6-0.7-1.6-1.6v-34c0-0.9,0.7-1.6,1.6-1.6h0c0.9,0,1.6,0.7,1.6,1.6v34
				C469.4,529.8,468.7,530.5,467.8,530.5z"
            />
            <path
              class="st18"
              d="M475.7,530.5L475.7,530.5c-0.9,0-1.6-0.7-1.6-1.6v-34c0-0.9,0.7-1.6,1.6-1.6l0,0c0.9,0,1.6,0.7,1.6,1.6v34
				C477.3,529.8,476.6,530.5,475.7,530.5z"
            />
            <path
              class="st18"
              d="M483.7,530.5L483.7,530.5c-0.9,0-1.6-0.7-1.6-1.6v-34c0-0.9,0.7-1.6,1.6-1.6l0,0c0.9,0,1.6,0.7,1.6,1.6v34
				C485.3,529.8,484.6,530.5,483.7,530.5z"
            />
            <path
              class="st18"
              d="M491.7,530.5L491.7,530.5c-0.9,0-1.6-0.7-1.6-1.6v-34c0-0.9,0.7-1.6,1.6-1.6l0,0c0.9,0,1.6,0.7,1.6,1.6v34
				C493.3,529.8,492.5,530.5,491.7,530.5z"
            />
            <rect x="449.4" y="487.5" class="st14" width="53.3" height="1.2" />
            <rect x="449.4" y="486.2" class="st14" width="53.3" height="1.2" />
            <rect
              x="451.3"
              y="580.4"
              :class="pumpStatus(getPumps[0].pumps[1].runPump)"
              width="48.6"
              height="2.5"
            />
            <rect
              x="443.4"
              y="582.9"
              :class="pumpStatus(getPumps[0].pumps[1].runPump)"
              width="64.5"
              height="2.5"
            />
            <polygon
              class="st18"
              points="507.9,570.5 507.9,573 443.4,573 443.4,570.5 451.5,570.5 451.5,570.5 499.8,570.5 499.8,570.5 			
				"
            />
            <rect x="441.1" y="532.7" class="st18" width="69.3" height="6.2" />
          </g>
          <g id="crop">
            <linearGradient
              id="SVGID_00000139294841767668486600000016255524519055792302_"
              gradientUnits="userSpaceOnUse"
              x1="435.3927"
              y1="510.7285"
              x2="515.033"
              y2="510.7285"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.776963e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>
            <path
              style="
                fill: url(#SVGID_00000139294841767668486600000016255524519055792302_);
              "
              d="M435.4,419.3v182.8H515V419.3H435.4z
				 M511.5,557.4c0,18.7-16.2,33.9-36.3,33.9c-10,0-19.1-3.8-25.7-9.9c-6.6-6.1-10.6-14.6-10.6-24v-94.8c0-18.7,16.2-33.9,36.3-33.9
				c20,0,36.3,15.2,36.3,33.9V557.4z"
            />
            <path
              class="st20"
              d="M511.5,462.7v94.8c0,18.7-16.2,33.9-36.3,33.9c-10,0-19.1-3.8-25.7-9.9c-6.6-6.1-10.6-14.6-10.6-24v-94.8
				c0-18.7,16.2-33.9,36.3-33.9C495.2,428.7,511.5,443.9,511.5,462.7z"
            />
          </g>
        </g>
        <g id="pump03">
          <g id="pipe">
            <linearGradient
              id="SVGID_00000150796901982841408120000010654413438555751086_"
              gradientUnits="userSpaceOnUse"
              x1="563.1659"
              y1="356.8146"
              x2="643.7619"
              y2="356.8146"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.776963e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>

            <rect
              x="563.2"
              y="110.5"
              style="
                fill: url(#SVGID_00000150796901982841408120000010654413438555751086_);
              "
              width="80.6"
              height="492.7"
            />
            <polygon class="st4" points="641.8,229.7 649.7,221.7 625.9,182 			" />

            <linearGradient
              id="SVGID_00000173866092108675129830000012364397685786218648_"
              gradientUnits="userSpaceOnUse"
              x1="681.512"
              y1="221.4222"
              x2="681.512"
              y2="148.6722"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.776963e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000173866092108675129830000012364397685786218648_);
              "
              points="625.9,185 641.8,148.7 
				737.1,148.7 737.1,221.4 649.7,221.4 			"
            />

            <linearGradient
              id="SVGID_00000168821694553635736030000011831399354474165949_"
              gradientUnits="userSpaceOnUse"
              x1="556.4495"
              y1="128.851"
              x2="650.4782"
              y2="128.851"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.776963e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>

            <rect
              x="556.4"
              y="126.4"
              style="
                fill: url(#SVGID_00000168821694553635736030000011831399354474165949_);
              "
              width="94"
              height="5"
            />

            <linearGradient
              id="SVGID_00000170959277038969410500000002166785803150246554_"
              gradientUnits="userSpaceOnUse"
              x1="552.333"
              y1="106.4834"
              x2="654.5948"
              y2="106.4834"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.776963e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>

            <rect
              x="552.3"
              y="102.5"
              style="
                fill: url(#SVGID_00000170959277038969410500000002166785803150246554_);
              "
              width="102.3"
              height="7.9"
            />
          </g>
          <g id="pump">
            <rect x="585.7" y="462.3" class="st14" width="33" height="1.3" />
            <rect x="578" y="481" class="st14" width="48.3" height="1.3" />
            <rect x="578" y="479.8" class="st14" width="48.3" height="1.3" />
            <path
              class="st14"
              d="M617.4,461h-30.5c-0.7,0-1.3,0.6-1.3,1.3l0,0h33l0,0C618.6,461.6,618.1,461,617.4,461z"
            />
            <path
              :class="pumpStatus(getPumps[0].pumps[2].runPump)"
              d="M575.6,488.6l1.3,4.1c0.6,1.8,0.9,3.6,0.9,5.4v35.2h48.8v-34.4c0-2.4,0.5-4.7,1.5-7l1.4-3.3H575.6z"
            />
            <polygon
              class="st14"
              points="626.6,571.2 626.6,571.3 577.8,571.3 577.8,571.2 			"
            />
            <polygon
              :class="pumpStatus(getPumps[0].pumps[2].runPump)"
              points="625.9,573.7 624.9,577.6 623.9,581.3 580.5,581.3 579.5,577.6 578.5,573.7 			"
            />
            <path
              class="st16"
              d="M574.2,586.3l-0.4,1.1c-2.3,5.9-6.2,11.2-11.4,15.8l0,0H642l0,0c-5.2-4.5-9.1-9.9-11.4-15.8l-0.4-1.1H574.2
				z"
            />
            <path
              :class="pumpStatus(getPumps[0].pumps[2].runPump)"
              d="M578,486.1v-3.8v-2.5l7.6-16.3v-1.3c0-0.7,0.7-1.3,1.6-1.3H617c0.9,0,1.6,0.6,1.6,1.3v1.3l7.6,16.3v2.5v3.8
				H578z"
            />
            <path
              class="st14"
              d="M617.8,460.6l-7.5-3.3h-16.3l-7.6,3.3c-0.5,0.2-0.8,0.6-0.8,1.1v1.8h33v-1.8
				C618.6,461.3,618.3,460.9,617.8,460.6z"
            />
            <path class="st17" d="M585.6,495" />
            <polygon
              :class="pumpStatus(getPumps[0].pumps[2].runPump)"
              points="626.6,571.2 626.8,551.8 634.4,539.3 569.4,539.3 577.7,551.8 577.8,571.2 			"
            />
            <path
              class="st18"
              d="M585.4,530.8L585.4,530.8c-0.9,0-1.6-0.7-1.6-1.6v-34.4c0-0.9,0.7-1.6,1.6-1.6l0,0c0.9,0,1.6,0.7,1.6,1.6
				v34.4C587,530.1,586.3,530.8,585.4,530.8z"
            />
            <path
              class="st18"
              d="M594.2,530.8L594.2,530.8c-0.9,0-1.6-0.7-1.6-1.6v-34.4c0-0.9,0.7-1.6,1.6-1.6l0,0c0.9,0,1.6,0.7,1.6,1.6
				v34.4C595.8,530.1,595.1,530.8,594.2,530.8z"
            />
            <path
              class="st18"
              d="M602.3,530.8L602.3,530.8c-0.9,0-1.6-0.7-1.6-1.6v-34.4c0-0.9,0.7-1.6,1.6-1.6l0,0c0.9,0,1.6,0.7,1.6,1.6
				v34.4C603.9,530.1,603.2,530.8,602.3,530.8z"
            />
            <path
              class="st18"
              d="M610.3,530.8L610.3,530.8c-0.9,0-1.6-0.7-1.6-1.6v-34.4c0-0.9,0.7-1.6,1.6-1.6l0,0c0.9,0,1.6,0.7,1.6,1.6
				v34.4C611.9,530.1,611.2,530.8,610.3,530.8z"
            />
            <path
              class="st18"
              d="M618.4,530.8L618.4,530.8c-0.9,0-1.6-0.7-1.6-1.6v-34.4c0-0.9,0.7-1.6,1.6-1.6l0,0c0.9,0,1.6,0.7,1.6,1.6
				v34.4C620,530.1,619.2,530.8,618.4,530.8z"
            />
            <rect x="575.6" y="487.3" class="st14" width="53.9" height="1.3" />
            <rect x="575.6" y="486.1" class="st14" width="53.9" height="1.3" />
            <rect
              x="577.6"
              y="581.3"
              :class="pumpStatus(getPumps[0].pumps[2].runPump)"
              width="49.1"
              height="2.5"
            />
            <rect
              x="569.6"
              y="583.8"
              :class="pumpStatus(getPumps[0].pumps[2].runPump)"
              width="65.2"
              height="2.5"
            />
            <polygon
              class="st18"
              points="634.7,571.2 634.7,573.7 569.6,573.7 569.6,571.2 577.8,571.2 577.8,571.3 626.6,571.3 
				626.6,571.2 			"
            />
            <rect x="567.3" y="533" class="st18" width="70" height="6.3" />
          </g>
          <g id="crop">
            <linearGradient
              id="SVGID_00000077308108211963454780000014130509651201680518_"
              gradientUnits="userSpaceOnUse"
              x1="562.3325"
              y1="511.7815"
              x2="641.9727"
              y2="511.7815"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.776963e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>
            <path
              style="
                fill: url(#SVGID_00000077308108211963454780000014130509651201680518_);
              "
              d="M562.3,420.4v182.8H642V420.4H562.3z
				 M638.4,558.5c0,18.7-16.2,33.9-36.3,33.9c-10,0-19.1-3.8-25.7-9.9c-6.6-6.1-10.6-14.6-10.6-24v-94.8c0-18.7,16.2-33.9,36.3-33.9
				s36.3,15.2,36.3,33.9V558.5z"
            />
            <path
              class="st20"
              d="M638.4,463.7v94.8c0,18.7-16.2,33.9-36.3,33.9c-10,0-19.1-3.8-25.7-9.9c-6.6-6.1-10.6-14.6-10.6-24v-94.8
				c0-18.7,16.2-33.9,36.3-33.9S638.4,445,638.4,463.7z"
            />
          </g>
        </g>
        <g id="pump04">
          <g id="pipe">
            <linearGradient
              id="SVGID_00000103231137970058128580000007056527999564403346_"
              gradientUnits="userSpaceOnUse"
              x1="688.2348"
              y1="356.1602"
              x2="768.8308"
              y2="356.1602"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.776963e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>

            <rect
              x="688.2"
              y="109.8"
              style="
                fill: url(#SVGID_00000103231137970058128580000007056527999564403346_);
              "
              width="80.6"
              height="492.7"
            />
            <polygon class="st4" points="767.7,229 775.6,221.1 751.8,181.3 			" />

            <linearGradient
              id="SVGID_00000101093190946475209400000007571301865357718203_"
              gradientUnits="userSpaceOnUse"
              x1="681.5185"
              y1="128.1966"
              x2="775.5472"
              y2="128.1966"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.776963e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>

            <rect
              x="681.5"
              y="125.7"
              style="
                fill: url(#SVGID_00000101093190946475209400000007571301865357718203_);
              "
              width="94"
              height="5"
            />

            <linearGradient
              id="SVGID_00000036249759718273805980000013416052635133849531_"
              gradientUnits="userSpaceOnUse"
              x1="677.4019"
              y1="105.8291"
              x2="779.6637"
              y2="105.8291"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.776963e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>

            <rect
              x="677.4"
              y="101.9"
              style="
                fill: url(#SVGID_00000036249759718273805980000013416052635133849531_);
              "
              width="102.3"
              height="7.9"
            />

            <linearGradient
              id="SVGID_00000001635943938438468360000007603245551221398416_"
              gradientUnits="userSpaceOnUse"
              x1="838.5984"
              y1="221.0609"
              x2="838.5984"
              y2="148.4847"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.776963e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000001635943938438468360000007603245551221398416_);
              "
              points="775.6,221.1 925.4,221.1 
              853.9,148.5 768.8,148.5 751.8,181.6 			"
            />
          </g>
          <g id="pump">
            <rect x="711.3" y="463.1" class="st14" width="32.6" height="1.2" />
            <rect x="703.8" y="481.7" class="st14" width="47.8" height="1.2" />
            <rect x="703.8" y="480.4" class="st14" width="47.8" height="1.2" />
            <path
              class="st14"
              d="M742.7,461.8h-30.2c-0.7,0-1.2,0.6-1.2,1.2l0,0H744l0,0C744,462.4,743.4,461.8,742.7,461.8z"
            />
            <path
              :class="pumpStatus(getPumps[0].pumps[3].runPump)"
              d="M701.4,489.1l1.3,4c0.6,1.7,0.9,3.5,0.9,5.3v34.9h48.3v-34c0-2.4,0.5-4.7,1.5-6.9l1.4-3.3H701.4z"
            />
            <polygon
              class="st14"
              points="751.8,570.9 751.8,570.9 703.5,570.9 703.5,570.9 			"
            />
            <polygon
              :class="pumpStatus(getPumps[0].pumps[3].runPump)"
              points="751.2,573.4 750.1,577.2 749.2,580.8 706.2,580.8 705.2,577.2 704.2,573.4 			"
            />
            <path
              class="st16"
              d="M699.9,585.8l-0.4,1.1c-2.3,5.8-6.1,11.1-11.3,15.6h0H767l0,0c-5.1-4.5-9-9.8-11.3-15.6l-0.4-1.1H699.9z"
            />
            <path
              :class="pumpStatus(getPumps[0].pumps[3].runPump)"
              d="M703.8,486.6v-3.7v-2.5l7.6-16.1v-1.2c0-0.7,0.7-1.2,1.6-1.2h29.5c0.9,0,1.6,0.6,1.6,1.2v1.2l7.6,16.1v2.5
              v3.7H703.8z"
            />
            <path
              class="st14"
              d="M743.2,461.5l-7.4-3.3h-16.1l-7.5,3.3c-0.5,0.2-0.8,0.6-0.8,1.1v1.8H744v-1.8
              C744,462.1,743.7,461.7,743.2,461.5z"
            />
            <path class="st17" d="M711.3,495.5" />
            <polygon
              :class="pumpStatus(getPumps[0].pumps[3].runPump)"
              points="751.8,570.9 752,551.7 759.6,539.3 695.2,539.3 703.5,551.7 703.5,570.9 			"
            />
            <path
              class="st18"
              d="M711,530.9L711,530.9c-0.9,0-1.6-0.7-1.6-1.6v-34c0-0.9,0.7-1.6,1.6-1.6l0,0c0.9,0,1.6,0.7,1.6,1.6v34
              C712.6,530.2,711.9,530.9,711,530.9z"
            />
            <path
              class="st18"
              d="M719.8,530.9L719.8,530.9c-0.9,0-1.6-0.7-1.6-1.6v-34c0-0.9,0.7-1.6,1.6-1.6l0,0c0.9,0,1.6,0.7,1.6,1.6v34
              C721.4,530.2,720.7,530.9,719.8,530.9z"
            />
            <path
              class="st18"
              d="M727.8,530.9L727.8,530.9c-0.9,0-1.6-0.7-1.6-1.6v-34c0-0.9,0.7-1.6,1.6-1.6l0,0c0.9,0,1.6,0.7,1.6,1.6v34
              C729.3,530.2,728.6,530.9,727.8,530.9z"
            />
            <path
              class="st18"
              d="M735.7,530.9L735.7,530.9c-0.9,0-1.6-0.7-1.6-1.6v-34c0-0.9,0.7-1.6,1.6-1.6l0,0c0.9,0,1.6,0.7,1.6,1.6v34
              C737.3,530.2,736.6,530.9,735.7,530.9z"
            />
            <path
              class="st18"
              d="M743.7,530.9L743.7,530.9c-0.9,0-1.6-0.7-1.6-1.6v-34c0-0.9,0.7-1.6,1.6-1.6l0,0c0.9,0,1.6,0.7,1.6,1.6v34
              C745.3,530.2,744.6,530.9,743.7,530.9z"
            />
            <rect x="701.4" y="487.9" class="st14" width="53.3" height="1.2" />
            <rect x="701.4" y="486.6" class="st14" width="53.3" height="1.2" />
            <rect
              x="703.4"
              y="580.8"
              :class="pumpStatus(getPumps[0].pumps[3].runPump)"
              width="48.6"
              height="2.5"
            />
            <rect
              x="695.4"
              y="583.3"
              :class="pumpStatus(getPumps[0].pumps[3].runPump)"
              width="64.5"
              height="2.5"
            />
            <polygon
              class="st18"
              points="759.9,570.9 759.9,573.4 695.4,573.4 695.4,570.9 703.5,570.9 703.5,570.9 751.8,570.9 
              751.8,570.9 			"
            />
            <rect x="693.1" y="533.1" class="st18" width="69.3" height="6.2" />
          </g>
          <g id="crop">
            <linearGradient
              id="SVGID_00000169528798035836754950000008094441477110093491_"
              gradientUnits="userSpaceOnUse"
              x1="688.2348"
              y1="511.1271"
              x2="767.0417"
              y2="511.1271"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.776963e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>
            <path
              style="
                fill: url(#SVGID_00000169528798035836754950000008094441477110093491_);
              "
              d="M688.2,419.7v182.8H767V419.7H688.2z
               M763.5,557.8c0,18.7-16.1,33.9-35.9,33.9c-9.9,0-18.9-3.8-25.4-9.9c-6.5-6.1-10.5-14.6-10.5-24v-94.8
              c0-18.7,16.1-33.9,35.9-33.9s35.9,15.2,35.9,33.9V557.8z"
            />
            <path
              class="st20"
              d="M763.5,463.1v94.8c0,18.7-16.1,33.9-35.9,33.9c-9.9,0-18.9-3.8-25.4-9.9c-6.5-6.1-10.5-14.6-10.5-24v-94.8
              c0-18.7,16.1-33.9,35.9-33.9S763.5,444.3,763.5,463.1z"
            />
          </g>
        </g>

        <text transform="matrix(1 0 0 1 290 85)" class="st36 st37">
          ปั๊มที่ 1
        </text>
        <text transform="matrix(1 0 0 1 430 85)" class="st36 st37">
          ปั๊มที่ 2
        </text>
        <text transform="matrix(1 0 0 1 555 86)" class="st36 st37">
          ปั๊มที่ 3
        </text>
        <text transform="matrix(1 0 0 1 680 85)" class="st36 st37">
          ปั๊มที่ 4
        </text>
        <linearGradient
          id="SVGID_00000054255215121040040370000014294131517720123831_"
          gradientUnits="userSpaceOnUse"
          x1="911.1364"
          y1="161.8514"
          x2="911.1364"
          y2="99.4848"
          gradientTransform="matrix(0.721 -0.721 1.3271 1.3271 62.0681 665.1509)"
        >
          <stop offset="0" style="stop-color: #676767" />
          <stop offset="2.776963e-03" style="stop-color: #686868" />
          <stop offset="0.1657" style="stop-color: #959393" />
          <stop offset="0.3063" style="stop-color: #bcbbbb" />
          <stop offset="0.4177" style="stop-color: #d8d8d7" />
          <stop offset="0.486" style="stop-color: #e5e5e5" />
          <stop offset="0.5583" style="stop-color: #d8d8d7" />
          <stop offset="0.6761" style="stop-color: #bcbbbb" />
          <stop offset="0.8247" style="stop-color: #959393" />
          <stop offset="0.9971" style="stop-color: #686868" />
          <stop offset="1" style="stop-color: #676767" />
        </linearGradient>
        <polygon
          style="
            fill: url(#SVGID_00000054255215121040040370000014294131517720123831_);
          "
          points="937.4,219.4 930.2,226.6 
		847.4,143.8 854.6,136.6 	"
        />
        <path
          v-if="
            getPumps[0].pumps[0].runPump ||
            getPumps[0].pumps[1].runPump ||
            getPumps[0].pumps[2].runPump ||
            getPumps[0].pumps[3].runPump
          "
          class="water"
          d="M869.8,151.8h61.3c62.8,0,118.4,40.7,137.3,100.7v0H965.2v-5.3c0-19-15.4-34.4-34.4-34.4l0,0L869.8,151.8z"
        />

        <text transform="matrix(1 0 0 1 930 412)" class="st41">คลองแสนแสบ</text>
      </g>
      <g id="value">
        <text transform="matrix(1 0 0 1 2.5232 228.6094)" class="st39">
          เซนติเมตร
        </text>
        <text transform="matrix(1 0 0 1 2.523 260.3976)" class="st36">
          {{ Math.round(getPumps[0].pond.level.max) * 100 }}
        </text>
        <text transform="matrix(1 0 0 1 2.5229 332.6)" class="st36">
          {{ Math.round(getPumps[0].pond.level.max / 1.333333333) * 100 }}
        </text>
        <text transform="matrix(1 0 0 1 2.5229 552.3)" class="st36">
          {{ Math.round(getPumps[0].pond.level.max / 4) * 100 }}
        </text>
        <text transform="matrix(1 0 0 1 28 625.9607)" class="st36">0</text>
      </g>
      <g id="valueH">
        <text x="105" y="460" class="st40">
          {{ getPumps[0].pond.level.value }} เมตร
        </text>
      </g>
      <g id="name">
        <path
          class="st42"
          d="M1007.5,4.5H826.1v108.4h181.3c29.9,0,54.2-24.3,54.2-54.2v0C1061.6,28.8,1037.4,4.5,1007.5,4.5z"
        />
        <text transform="matrix(1 0 0 1 839.9006 43.1301)">
          <tspan x="5" y="30" class="st36 st43">สุขุมวิท</tspan>
          <tspan x="135" y="30" class="st36 st43">71</tspan>
        </text>
      </g>
      <g id="level">
        <rect
          v-if="
            getPumps[0].pond.level.medium != 0 &&
            getPumps[0].pond.level.max != 0 &&
            getPumps[0].pond.level.medium <= getPumps[0].pond.level.max
          "
          x="798.3"
          :y="rectMedium(getPumps[0].pond.level)"
          :class="checkMedium(getPumps[0].pond)"
          width="86.8"
          height="28.2"
        />
        <text
          v-if="
            getPumps[0].pond.level.medium != 0 &&
            getPumps[0].pond.level.max != 0 &&
            getPumps[0].pond.level.medium <= getPumps[0].pond.level.max
          "
          x="818"
          :y="levelMedium(getPumps[0].pond.level)"
          :class="checkMedium(getPumps[0].pond)"
        >
          Medium
        </text>
        <rect
          v-if="
            getPumps[0].pond.level.high != 0 &&
            getPumps[0].pond.level.max != 0 &&
            getPumps[0].pond.level.high <= getPumps[0].pond.level.max
          "
          x="798.9"
          :y="rectHigh(getPumps[0].pond.level)"
          :class="checkHi(getPumps[0].pond)"
          class="st44"
          width="86.8"
          height="28.2"
        />
        <text
          v-if="
            getPumps[0].pond.level.high != 0 &&
            getPumps[0].pond.level.max != 0 &&
            getPumps[0].pond.level.high <= getPumps[0].pond.level.max
          "
          x="825"
          :y="levelHigh(getPumps[0].pond.level)"
          :class="checkHi(getPumps[0].pond)"
        >
          High
        </text>
        <rect
          v-if="
            getPumps[0].pond.level.highHigh != 0 &&
            getPumps[0].pond.level.max != 0 &&
            getPumps[0].pond.level.highHigh <= getPumps[0].pond.level.max
          "
          x="798.9"
          :y="rectHihi(getPumps[0].pond.level)"
          :class="checkHihi(getPumps[0].pond)"
          class="st44"
          width="86.8"
          height="28.2"
        />
        <text
          v-if="
            getPumps[0].pond.level.highHigh != 0 &&
            getPumps[0].pond.level.max != 0 &&
            getPumps[0].pond.level.highHigh <= getPumps[0].pond.level.max
          "
          x="812"
          :y="levelHihi(getPumps[0].pond.level)"
          :class="checkHihi(getPumps[0].pond)"
        >
          HighHigh
        </text>
      </g>
    </svg>
  </div>
</template>
<script>
import Cookies from "js-cookie";

export default {
  name: "WatercontrolAppStation",

  data() {
    return {
      data: {
        id: "",
        macAddress: "",
      },
      pump: [],
      loader: true,
    };
  },

  computed: {
    getPumps() {
      return this.$store.getters.allPumps;
    },
    getStation() {
      return this.$store.getters.stations;
    },
  },

  created() {
    // console.log(this.$route.params.data);
    this.data.id = Cookies.get("stationID");
    this.data.macAddress = Cookies.get("macAddress");
    // console.log(this.data);
  },

  mounted() {
    this.$store.dispatch("getPumps", this.data).then((res) => {
      this.loader = false;
    });
    // console.log("data");
  },

  methods: {
    levelLowLow(y) {
      let size = ([y.highHigh] * 425) / y.max;

      // console.log(425 + size);
      return 968 - size;
    },
    levelLow(y) {
      // console.log(y);
      let size = ([y.highHigh] * 425) / y.max;

      // console.log(425 + size);
      return 968 - size;
    },

    levelHihi(y) {
      let size = ([y.highHigh] * 358) / y.max;

      // console.log(425 + size);
      return 620 - size;
    },
    levelHigh(y) {
      // console.log(y.high);
      let size = ([y.high] * 358) / y.max;

      // console.log(size);
      return 620 - size;
    },
    levelMedium(y) {
      // console.log(y.medium);
      let size = ([y.medium] * 358) / y.max;

      return 620 - size;
    },
    rectHihi(y) {
      let size = ([y.highHigh] * 358) / y.max;

      return 600 - size;
    },
    rectHigh(y) {
      let size = ([y.high] * 358) / y.max;

      return 600 - size;
    },
    rectMedium(y) {
      let size = ([y.medium] * 358) / y.max;

      return 600 - size;
    },
    fontSizeWater(d) {
      if (d.value <= d.max) {
        let size = (d.value * 368) / d.max;
        return size;
      } else {
        console.log("ปริมาณน้ำมากกว่าความจุบ่อ");
        let size = ([d.max + 0.2] * 358) / d.max;
        return size;
      }
    },
    waterY(h) {
      let size = (h.value * 454) / h.max;
      // console.log(998 - size);
      return 1010 - size;
    },
    pumpStatus(p) {
      // console.log(p);
      if (p == true) {
        return "st101";
      } else {
        return "st100";
      }
    },
    checkHihi(l) {
      let level = l.level;
      // console.log(level);
      if (level.value >= level.highHigh && level.value) {
        // console.log("hihi");
        return "hihi";
      } else {
        return "st44";
      }
    },
    checkHi(l) {
      let level = l.level;
      // console.log(level);

      if (level.value >= level.high && level.value < level.highHigh) {
        return "hi";
      } else {
        return "st44";
      }
    },
    checkMedium(l) {
      let level = l.level;
      if (level.value >= level.medium && level.value < level.high) {
        return "me";
      } else {
        return "st44";
      }
    },
  },
};
</script>

<style type="text/css" scoped>
.red {
  fill: #EF5350;
  opacity: 0.5;
}
.st0 {
  fill: url(#SVGID_1_);
}
.st1 {
  fill: url(#SVGID_00000091710285701015286480000013986991092221630638_);
}
.st2 {
  fill: url(#SVGID_00000112590488895457471850000005795917108573480835_);
}

.st3 {
  opacity: 0.76;
  fill: #00aeef;
}
.st4 {
  fill: #676767;
}
.st5 {
  fill: #e0e0e0;
}
.st6 {
  fill: url(#SVGID_00000066512024892622061110000003463900278300990348_);
}
.st7 {
  opacity: 0.3;
  fill: #5dccf5;
}
.st8 {
  fill: #ffffff;
}
.st9 {
  fill: none;
  stroke: #000000;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
.st10 {
  fill: url(#SVGID_00000112609459438794499200000015619987914220061840_);
}
.st11 {
  fill: url(#SVGID_00000151508710106362150450000001527133138189713080_);
}
.st12 {
  fill: url(#SVGID_00000176738161604239826500000003921090101740966035_);
}
.st13 {
  fill: url(#SVGID_00000056427267095518897690000015029019223727468941_);
}
.st14 {
  fill: #8f8f8f;
  stroke: #010101;
  stroke-miterlimit: 10;
}
.st15 {
  fill: #c1282d;
  stroke: #010101;
  stroke-miterlimit: 10;
}
.st16 {
  fill: #525252;
  stroke: #010101;
  stroke-miterlimit: 10;
}
.st17 {
  fill: none;
  stroke: #010101;
  stroke-miterlimit: 10;
}
.st18 {
  fill: #010101;
}
.st19 {
  fill: url(#SVGID_00000086657474743645839900000010166594005234395524_);
}
.st20 {
  fill: none;
  stroke: #ffffff;
  stroke-miterlimit: 10;
}
.st21 {
  fill: url(#SVGID_00000173858281217075793120000004462950221667052418_);
}
.st22 {
  fill: url(#SVGID_00000090989674494759315350000007548577687989549974_);
}
.st23 {
  fill: url(#SVGID_00000003827343020922447200000004710695433955956649_);
}
.st24 {
  fill: url(#SVGID_00000096751535227666853660000008778909935988277151_);
}
.st25 {
  fill: url(#SVGID_00000145046348166192716130000007016951749623927191_);
}
.st26 {
  fill: url(#SVGID_00000088131924340397526440000013762056690822800291_);
}
.st27 {
  fill: url(#SVGID_00000146490377479689089070000015480425731492767655_);
}
.st28 {
  fill: url(#SVGID_00000016048700506149701380000005171820318133013170_);
}
.st29 {
  fill: url(#SVGID_00000113354450743667692170000009784112703527424429_);
}
.st30 {
  fill: url(#SVGID_00000101098327824130388340000012790201789496267148_);
}
.st31 {
  fill: url(#SVGID_00000039099657936272295010000017990946282068492982_);
}
.st32 {
  fill: url(#SVGID_00000142878997394280574870000007023784032744352179_);
}
.st33 {
  fill: url(#SVGID_00000055668815813959294480000007702471280817992361_);
}
.st34 {
  fill: url(#SVGID_00000101784984889883565600000011436458140957122711_);
}
.st35 {
  fill: url(#SVGID_00000115475708366755027810000003750651574906549153_);
}
.st36 {
  font-size: 24px;
}
.st37 {
  font-size: 36px;
}
.st38 {
  fill: url(#SVGID_00000124873328659563009640000017949858975449097896_);
}
.st39 {
  font-size: 17px;
}
.st40 {
  font-size: 42px;
}
.st41 {
  font-size: 22px;
}
.st42 {
  fill: #ffffff;
  stroke: #000000;
  stroke-width: 8;
  stroke-miterlimit: 10;
}
.st43 {
  font-size: 42px;
}
.st44 {
  fill: #fff;
  stroke: #000;
  stroke-width: 1;
}
.st45 {
  font-size: 30px;
}
.st101 {
  fill: #009245;
  stroke: #000000;
  stroke-miterlimit: 10;
}
.st100 {
  fill: red;
  stroke: #000000;
  stroke-miterlimit: 10;
}

.me {
  fill: orange;
  stroke: #000;
  stroke-width: 1;

  animation: fadeIn 1s ease-in-out forwards 1s;
}
.hi {
  z-index: 100;
  fill: orangered;

  stroke: #000;
  stroke-width: 1;
  animation: fadeIn 1s ease-in-out forwards 1s;
}
.hihi {
  z-index: 10;
  fill: red;
  stroke: #000;
  stroke-width: 1;
  animation: fadeIn 1s ease-in-out infinite;
}

.th {
  font-size: 18px;
  animation: fadeIn 1s ease-in-out forwards 1s;
}
.water {
  opacity: 0.76;
  fill: #00aeef;
  animation: waterout 5s ease-in-out both;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes waterout {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.76;
  }
}
</style>

<!-- .st101 {
  fill: #009245;
  stroke: #000000;
  stroke-miterlimit: 10;
}
.st100 {
  fill: red;
  stroke: #000000;
  stroke-miterlimit: 10;
}
.st76 {
  fill: #fcfcfc;
  stroke: #000000;
  stroke-miterlimit: 10;
}
.st77 {
  fill: #fff;
  font-weight: 100;
  font-size: 18px;
  background-color: #009245;
}
.st78 {
  font-size: 14px;
}
.st79 {
  fill: #ffffff;
  stroke: #000000;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.st82 {
  font-family: "THSarabunPSK-Bold";
}
.st81 {
  font-size: 75px;
} -->
