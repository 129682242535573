import authHeader from "@/service/auth-header";
import axios from "axios";

const apiURL = process.env.VUE_APP_API_URL + "ponds/";

export const ponds = {
  state: {},
  getters: {},
  mutations: {
    SOCKET_STATUS(state, payload) {
      // console.log(payload)
      // const obj = JSON.parse(payload);
      // console.log(obj);
      // localStorage.setItem("online", JSON.stringify(obj));
      // if (state.pumps.length > 0) {
      //   for (const i in obj) {
      //     const index = state.pumps.findIndex(
      //       (p) => p.macAddress === obj[i].macAddress
      //     );
      //     if (index > -1) {
      //       state.stations[index].online = obj[i].online;
      //     }
      //   }
      // }
    },
  },
  actions: {},
};
