<template>
  <div>
    <v-card>
      <v-card-title>
        บัญชีผู้ใช้
        <v-spacer></v-spacer>
        <v-text-field
          ref="search"
          placeholder="ค้นหา"
          v-model="search"
          append-icon="mdi-magnify"
          class="mt-5 shrink"
          style="max-width: 250px"
          color="#1bb394"
          single-line
          filled
          dense
        />
      </v-card-title>
      <v-data-table
        item-key="id"
        :search="search"
        :headers="headers"
        :items="getUsers"
        :items-per-page="5"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon @click="resetPasswordId(item._id)" class="mr-2">
            <v-icon color="yellow darken-2">mdi-key</v-icon>
          </v-btn>
          <v-btn icon @click="editUserId(item)" class="mr-2">
            <v-icon color="orange darken-2">mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon @click="deleteUserId(item._id)">
            <v-icon color="error">mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-fab-transition>
      <v-btn
        @click="createDialog = true"
        color="#1bb394"
        fixed
        bottom
        right
        fab
      >
        <v-icon color="#fff">mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-dialog persistent v-model="resetDialog" width="350">
      <v-card>
        <v-card-title>
          <span>รีเซ็ตรหัสผ่าน</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form
            @submit.prevent="resetPasswordFn()"
            lazy-validation
            enctype="multipart/form-data"
          >
            <v-text-field
              color="#1bb394"
              name="newPassword"
              label="รหัสผ่านใหม่"
              v-model="password.new"
              :rules="[(v) => !!v || 'กรุณากรอกรหัสผ่าน']"
              :type="hideNewPassword ? 'text' : 'password'"
              :append-icon="
                hideNewPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
              "
              @click:append="hideNewPassword = !hideNewPassword"
            />
            <v-text-field
              color="#1bb394"
              name="confirmPassword"
              label="ยืนยันรหัสผ่านใหม่"
              v-model="password.confirm"
              :rules="[(v) => !!v || 'กรุณากรอกรหัสผ่าน']"
              :type="hideConfirmPassword ? 'text' : 'password'"
              :append-icon="
                hideConfirmPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
              "
              @click:append="hideConfirmPassword = !hideConfirmPassword"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text block color="success" @click="resetPasswordFn()">
            ยืนยัน
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="editDialog" width="350">
      <v-card>
        <v-card-title>
          <span> แก้ไขบัญชีผู้ใช้งาน</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form
            @submit.prevent="editUserFn()"
            lazy-validation
            enctype="multipart/form-data"
            autocomplete="off"
          >
            <v-text-field
              color="#1bb394"
              v-model="editUser.username"
              name="username"
              label="บัญชีผู้ใช้"
              :rules="[(v) => !!v || 'กรุณาระบุชื่อบัญชีผู้ใช้']"
              required
            />
            <v-text-field
              color="#1bb394"
              v-model="editUser.name"
              name="name"
              label="ชื่อ"
              :rules="[(v) => !!v || 'กรุณาระบุชื่อผู้ใช้']"
              required
            />
            <v-select
              color="#1bb394"
              :disabled="!isEditing"
              :items="checkRole(getAccess)"
              item-value="id"
              name="role"
              label="ตำแหน่ง"
              v-model="editUser.role"
              v-if="getAccess == 'Operator'"
              required
            />
            <v-select
              color="#1bb394"
              :disabled="isEditing"
              :items="checkRole(getAccess)"
              item-value="id"
              name="role"
              label="ตำแหน่ง"
              v-model="editUser.role"
              v-else
              required
            />
            <v-select
              :items="getStation"
              color="#1bb394"
              item-value="id"
              item-text="name"
              label="สถานี*"
              v-model="editUser.station"
              disabled
              v-if="
                editUser.role == 'Admin' &&
                (getAccess.role == 'Superadmin' || getAccess.role == 'Admin')
              "
            />
            <v-select
              :items="getStation"
              color="#1bb394"
              item-value="id"
              item-text="name"
              label="สถานี*"
              v-model="editUser.station"
              disabled
              v-if="
                editUser.role == 'Operator' &&
                (getAccess.role == 'Superadmin' || getAccess.role == 'Admin')
              "
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text block color="success" @click="editUserFn()">
            บันทึก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="deleteDialog" width="350">
      <v-card>
        <v-card-title>
          <span>ลบบัญชีผู้ใช้งาน</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <br />
        <v-card-text class="body">
          <v-row justify="center" align="center">
            <v-icon x-large color="error">
              mdi-account-multiple-remove-outline
            </v-icon>
          </v-row>
          <br />
          <v-row justify="center" align="center">
            คุณแน่ใจหรือว่าต้องการจะลบผู้ใช้ ?
          </v-row>
        </v-card-text>
        <br />
        <v-card-actions>
          <v-btn block text color="error" @click="deleteUserFn()">ตกลง</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="createDialog" width="350">
      <v-card>
        <v-card-title>
          <span> สร้างบัญชีผู้ใช้งาน</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form
            @submit.prevent="createUserFn()"
            lazy-validation
            enctype="multipart/form-data"
            autocomplete="off"
          >
            <v-text-field
              color="#1bb394"
              v-model="createUser.username"
              name="username"
              label="บัญชีผู้ใช้"
              :rules="[(v) => !!v || 'Username your required']"
              required
            />
            <v-text-field
              color="#1bb394"
              name="password"
              label="รหัสผ่าน"
              v-model="createUser.password"
              :rules="[(v) => !!v || 'Password is required']"
              :type="hidePassword ? 'text' : 'password'"
              :append-icon="
                hidePassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
              "
              @click:append="hidePassword = !hidePassword"
            />
            <v-text-field
              color="#1bb394"
              v-model="createUser.name"
              name="name"
              label="ชื่อ"
              :rules="[(v) => !!v || 'Name your required']"
              required
            />
            <!-- ['Oparator', 'Admin', 'Superadmin']" -->
            <v-select
              color="#1bb394"
              :items="checkRole(getAccess)"
              item-value="_id"
              name="role"
              label="ตำแหน่ง"
              v-model="createUser.role"
              required
            />

            <v-select
              color="#1bb394"
              :items="getStation"
              item-value="id"
              item-text="name"
              label="สถานี*"
              v-model="createUser.station"
              v-if="
                (createUser.role == 'Admin' || createUser.role == 'Operator') &&
                (getAccess.role == 'Superadmin' || getAccess.role == 'Admin')
              "
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text block color="success" @click="createUserFn()">
            สร้าง
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" top right :color="color">
      <v-row>
        <v-col cols="10"> {{ text }}</v-col>
        <v-col cols="2">
          <v-btn x-small color="white" icon @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "Accounts",

  data() {
    return {
      isEditing: null,
      text: "",
      color: "",
      snackbar: false,
      createDialog: false,
      deleteDialog: false,
      editDialog: false,
      resetDialog: false,
      search: "",
      hidePassword: false,
      hideNewPassword: false,
      hideConfirmPassword: false,
      headers: [
        { text: "ชื่อบัญชีผู้ใช้", value: "username", sortable: true },
        { text: "ชื่อ", value: "name", sortable: true },
        { text: "สถานี", value: "station.name", sortable: true },
        { text: "ตำแหน่ง", value: "role", sortable: true },
        {
          text: "เพิ่มเติม",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      password: { id: "", new: "", confirm: "" },
      resetPassword: { id: "", newPassword: "" },
      editUser: {
        id: "",
        name: "",
        username: "",
        role: "",
        station: "",
      },
      createUser: {
        name: "",
        username: "",
        password: "",
        role: "",
        station: "",
      },
    };
  },

  async mounted() {
    console.log(this.currentUser);
    if (!this.currentUser) {
      this.$router.push("/");
    } else if (
      this.getAccess.role == "Admin" ||
      this.getAccess.role == "Superadmin"
    ) {
      this.loadingUser();
    }
  },

  computed: {
    getAccess() {
      console.log(this.$store.state.auth.access);
      return this.$store.state.auth.access;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    getUsers() {
      let user = this.$store.getters.allUsers;

      if (this.getAccess.username != "pmAdmin") {
        for (let i = user.length - 1; i >= 0; i--) {
          if (user[i].username === "pmAdmin") {
            user.splice(i, 1);
          }
        }
      }
      return user;
    },
    getStation() {
      return this.$store.getters.stations;
    },
  },

  methods: {
    loadingUser() {
      this.$store.dispatch("getUsers");
      this.$store.dispatch("getStations");
    },

    checkRole(data) {
      // console.log(data);
      if (data == null) {
        this.$router.push("/");
      } else {
        if (data.role == "Superadmin") {
          return ["Operator", "Admin", "Superadmin"];
        } else if (data.role == "Admin") {
          return ["Operator", "Admin"];
        } else {
          return ["Operator"];
        }
      }
    },

    closeDialog() {
      this.deleteDialog = false;
      this.editDialog = false;
      this.createDialog = false;
      this.resetDialog = false;
      this.password.new = "";
      this.password.confirm = "";
    },

    clear() {
      this.createUser.username = null;
      this.createUser.password = null;
      this.createUser.name = null;
      this.createUser.role = null;
      this.createUser.station = null;
    },

    resetPasswordId(id) {
      this.resetDialog = true;
      this.password.id = id;
    },

    resetPasswordFn() {
      if (
        this.password.new === this.password.confirm &&
        this.password.new != undefined &&
        this.password.confirm
      ) {
        // console.log(this.password.id);
        this.resetPassword.id = this.password.id;
        this.resetPassword.newPassword = this.password.new;
        this.$store
          .dispatch("resetPassword", this.resetPassword)
          .then((res) => {
            this.resetDialog = false;
            this.color = "success";
            // this.text = "Success: Reset Password Complete.";
            this.text = "รีเซ็ตรหัสผ่านของคุณสำเร็จแล้ว";
            this.snackbar = true;
          })
          .catch((err) => {
            console.log(err);
            this.password.new = "";
            this.password.comfirm = "";
            this.color = "error";
            // this.text = " Error: You cannot reset password this account.";
            this.text = err;
            this.snackbar = true;
          });
      }
      if (this.password.new == "" || this.password.confirm == "") {
        this.color = "error";
        this.text = "กรุณากรอกรหัสผ่านให้ครบถ้วน";
        this.snackbar = true;
      } else {
        this.color = "error";
        this.text = "รหัสผ่านไม่ตรงกัน กรุณาตรวจสอบ";
        this.snackbar = true;
      }
    },

    editUserId(data) {
      console.log(data);
      this.editDialog = true;
      this.editUser.id = data._id;
      this.editUser.username = data.username;
      this.editUser.name = data.name;
      this.editUser.role = data.role;
      this.editUser.station = data.station;
    },

    editUserFn() {
      console.log(this.editUser);
      this.$store
        .dispatch("editUsers", this.editUser)
        .then((res) => {
          console.log(res);
          if ((res.data.message = "แก้ไขข้อมูลเรียบร้อย")) this.loadingUser();
          this.closeDialog(false);
          this.color = "success";
          this.text = "แก้ไขข้อมูลรียบร้อย";
          this.snackbar = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteUserId(id) {
      this.deleteDialog = true;
      this.userId = id;
    },

    deleteUserFn() {
      this.$store
        .dispatch("deleteUser", this.userId)
        .then(() => {
          this.closeDialog(false);
          this.loadingUser();
          this.color = "success";
          this.text = "ลบบัญชีผู้ใช้เรียบร้อย";
          this.snackbar = true;
        })
        .catch((err) => {
          console.log(err);
          this.color = "error";
          this.text = "ลบบัญชีผู้ใช้ล้มเหลว";
          this.snackbar = true;
        });
    },

    createUserFn() {
      console.log(this.getAccess);
      console.log(this.createUser);
      if (
        this.createUser.name === "" ||
        this.createUser.username === "" ||
        this.createUser.password === "" ||
        this.createUser.role === ""
      ) {
        this.snackbar = true;
        this.color = "error";
        this.text = "กรุณากรอกข้อมูลให้ครบถ้วน";
      } else {
        console.log(this.createUser);
        if (this.createUser.role == "Superadmin") {
          this.createUser.station = null;
        }
        if (this.getAccess.role != "Superadmin") {
          this.createUser.station = this.getAccess.station;
          console.log(this.createUser);
        }
        this.$store
          .dispatch("createUsers", this.createUser)
          .then((response) => {
            console.log(response);
            if (response.status == 201) {
              this.createDialog = false;
              this.clear();
              this.loadingUser();
              this.color = "success";
              this.text = "สร้างบัญชีผู้ใช้สำเร็จ";
              // this.text = "Success: Account has been successfully saved.";
              this.snackbar = true;
            } else {
              this.createDialog = false;
              this.clear();
              this.loadingUser();
              this.color = "error";
              this.text = "กรุณากรอกข้อมูลให้ครบถ้วน";
              this.snackbar = true;
            }
          });
      }
    },
    // },
  },
};
</script>

<style lang="scss" scoped></style>
