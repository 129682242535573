<template>
  <div v-if="loader">
    <v-progress-linear
      v-if="loader"
      :size="100"
      color="#1bb394"
      :indeterminate="loader"
      absolute
      top
      class="mb-2"
    >
    </v-progress-linear>
  </div>
  <div v-else>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1080 640"
      style="enable-background: new 0 0 1080 640"
      xml:space="preserve"
    >
      <g id="layouts">
        <g>
          <linearGradient
            id="SVGID_1_"
            gradientUnits="userSpaceOnUse"
            x1="982.5596"
            y1="640"
            x2="982.5596"
            y2="252.4503"
          >
            <stop offset="0" style="stop-color: #412312" />
            <stop offset="0.5642" style="stop-color: #8c633a" />
            <stop offset="0.7486" style="stop-color: #a57c52" />
            <stop offset="0.801" style="stop-color: #8a6443" />
            <stop offset="0.8946" style="stop-color: #60402a" />
            <stop offset="0.9636" style="stop-color: #492b1a" />
            <stop offset="1" style="stop-color: #412312" />
          </linearGradient>
          <path
            class="st0"
            d="M924.9,502.1V252.5h-39.7V640H1080v-36.5l-55.1-1.4C969.6,602.1,924.9,557.3,924.9,502.1z"
          />

          <linearGradient
            id="SVGID_00000000934875360793809710000004122997124234624926_"
            gradientUnits="userSpaceOnUse"
            x1="904.9868"
            y1="252.4503"
            x2="904.9868"
            y2="236.5563"
          >
            <stop offset="0" style="stop-color: #3c2415" />
            <stop offset="8.782995e-02" style="stop-color: #3d331e" />
            <stop offset="0.2346" style="stop-color: #394527" />
            <stop offset="0.3909" style="stop-color: #2e542f" />
            <stop offset="0.5592" style="stop-color: #1d5f34" />
            <stop offset="0.7481" style="stop-color: #016537" />
            <stop offset="1" style="stop-color: #006838" />
          </linearGradient>
          <path
            style="
              fill: url(#SVGID_00000000934875360793809710000004122997124234624926_);
            "
            d="M916.9,236.6h-31.8v15.9h39.7v0
          C924.9,243.7,921.3,236.6,916.9,236.6z"
          />
        </g>

        <linearGradient
          id="SVGID_00000023267231896025771230000011651431486587632779_"
          gradientUnits="userSpaceOnUse"
          x1="1002.4271"
          y1="603.5223"
          x2="1002.4271"
          y2="252.4503"
        >
          <stop offset="0" style="stop-color: #412312" />
          <stop offset="0.4972" style="stop-color: #8c633a" />
          <stop offset="0.72" style="stop-color: #a57c52" />
          <stop offset="0.8186" style="stop-color: #7b583a" />
          <stop offset="0.9418" style="stop-color: #50321f" />
          <stop offset="1" style="stop-color: #412312" />
        </linearGradient>
        <path
          style="
            fill: url(#SVGID_00000023267231896025771230000011651431486587632779_);
          "
          d="M924.9,252.5v249.7
        c0,55.2,44.8,100,100,100l55.1,1.4V252.5L924.9,252.5z"
        />
        <path
          class="st3"
          d="M924.9,252.5v249.7c0,55.2,44.8,100,100,100l55.1,1.4V252.5L924.9,252.5z"
        />
        <rect x="75.1" y="14" class="st4" width="747" height="224" />
        <polygon
          class="st5"
          points="798.3,618 98.9,618 98.9,22 798.3,22 798.3,117.4 822.1,117.4 822.1,0 75.1,0 75.1,640 822.1,640.9 
        822.1,229.5 98.9,228.6 98.9,252.5 798.3,252.5 	"
        />

        <rect x="516.6" y="229.5" class="st5" width="26.2" height="388.8" />

        <linearGradient
          id="SVGID_00000123402157742151175710000008672266524686175128_"
          gradientUnits="userSpaceOnUse"
          x1="98.2132"
          y1="435.3003"
          x2="353.2142"
          y2="435.3003"
          gradientTransform="matrix(-1 0 0 1 895.9567 0)"
        >
          <stop offset="0" style="stop-color: #676767" />
          <stop offset="8.250325e-03" style="stop-color: #6f6e6e" />
          <stop offset="3.712930e-02" style="stop-color: #898787" />
          <stop offset="6.983375e-02" style="stop-color: #a2a0a0" />
          <stop offset="0.1068" style="stop-color: #b8b6b6" />
          <stop offset="0.1499" style="stop-color: #cac9c9" />
          <stop offset="0.2033" style="stop-color: #d8d8d8" />
          <stop offset="0.2782" style="stop-color: #e2e2e2" />
          <stop offset="0.486" style="stop-color: #e5e5e5" />
          <stop offset="0.7058" style="stop-color: #e2e2e2" />
          <stop offset="0.785" style="stop-color: #d8d8d8" />
          <stop offset="0.8415" style="stop-color: #cac9c9" />
          <stop offset="0.8871" style="stop-color: #b8b6b6" />
          <stop offset="0.9262" style="stop-color: #a2a0a0" />
          <stop offset="0.9607" style="stop-color: #898787" />
          <stop offset="0.9913" style="stop-color: #6f6e6e" />
          <stop offset="1" style="stop-color: #676767" />
        </linearGradient>
        <rect
          x="542.7"
          y="252"
          style="
            fill: url(#SVGID_00000123402157742151175710000008672266524686175128_);
          "
          points="542.7,618.2 797.7,618.2 
          797.7,252.5 542.7,252.5 	"
          width="256"
          height="365.8"
        />

        <linearGradient
          id="SVGID_00000136390772569987686580000006674559656169579914_"
          gradientUnits="userSpaceOnUse"
          x1="379.3528"
          y1="435.3518"
          x2="798.2782"
          y2="435.3518"
          gradientTransform="matrix(-1 0 0 1 895.9567 0)"
        >
          <stop offset="0" style="stop-color: #676767" />
          <stop offset="8.250325e-03" style="stop-color: #6f6e6e" />
          <stop offset="3.712930e-02" style="stop-color: #898787" />
          <stop offset="6.983375e-02" style="stop-color: #a2a0a0" />
          <stop offset="0.1068" style="stop-color: #b8b6b6" />
          <stop offset="0.1499" style="stop-color: #cac9c9" />
          <stop offset="0.2033" style="stop-color: #d8d8d8" />
          <stop offset="0.2782" style="stop-color: #e2e2e2" />
          <stop offset="0.486" style="stop-color: #e5e5e5" />
          <stop offset="0.7058" style="stop-color: #e2e2e2" />
          <stop offset="0.785" style="stop-color: #d8d8d8" />
          <stop offset="0.8415" style="stop-color: #cac9c9" />
          <stop offset="0.8871" style="stop-color: #b8b6b6" />
          <stop offset="0.9262" style="stop-color: #a2a0a0" />
          <stop offset="0.9607" style="stop-color: #898787" />
          <stop offset="0.9913" style="stop-color: #6f6e6e" />
          <stop offset="1" style="stop-color: #676767" />
        </linearGradient>

        <rect
          x="97.7"
          y="252"
          style="
            fill: url(#SVGID_00000136390772569987686580000006674559656169579914_);
          "
          width="419"
          height="365.8"
        />
      </g>

      <!-- y="252.5" -->
      <g id="water">
        <rect
          :class="
            getPumps[0].pond.level.value >= getPumps[0].pond.level.highHigh
              ? 'red'
              : 'st8'
          "
          x="98.9"
          y="-618"
          transform="scale(1,-1)"
          width="414.5"
          height="1em"
          v-bind:style="{
            fontSize: waterYP1(getPumps[0].pond.level) + 'px',
          }"
        />

        <rect
          :class="
            getPumps[1].pond.level.value >= getPumps[1].pond.level.highHigh
              ? 'red'
              : 'st8'
          "
          x="544"
          y="-618"
          transform="scale(1,-1)"
          width="254"
          height="1em"
          v-bind:style="{
            fontSize: waterYP2(getPumps[1].pond.level) + 'px',
          }"
        />

        <!-- <rect
          x="98.9"
          y="-617.5"
          class="st8"
          width="214.6"
          height="1em"
          transform="scale(1,-1)"
          v-bind:style="{
            fontSize: fontSizeWaterL(getPumps[0].pond.level) + 'px',
          }"
        />
        <rect
          x="421.8"
          y="-617.5"
          class="st8"
          width="376.5"
          height="1em"
          transform="scale(1,-1)"
          v-bind:style="{
            fontSize: fontSizeWaterR(getPumps[1].pond.level) + 'px',
          }"
        /> -->
      </g>

      <!-- Pumps -->
      <g id="pumps">
        <g id="pump01">
          <g id="pipe">
            <linearGradient
              id="SVGID_00000067226452906685015890000014970766411723585435_"
              gradientUnits="userSpaceOnUse"
              x1="138.6048"
              y1="329.6613"
              x2="219.2008"
              y2="329.6613"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="0.0028" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>

            <rect
              x="138.605"
              y="112.917"
              style="
                fill: url(#SVGID_00000067226452906685015890000014970766411723585435_);
              "
              width="80.596"
              height="433.489"
            />
            <polygon
              class="st5"
              points="219.226,227.181 227.173,218.448 203.332,177.917 			"
            />

            <linearGradient
              id="SVGID_00000183937018117096945380000017137269450759810984_"
              gradientUnits="userSpaceOnUse"
              x1="131.8885"
              y1="131.3106"
              x2="225.9172"
              y2="131.3106"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="0.0028" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>

            <rect
              x="131.888"
              y="128.811"
              style="
                fill: url(#SVGID_00000183937018117096945380000017137269450759810984_);
              "
              width="94.029"
              height="5"
            />

            <linearGradient
              id="SVGID_00000147931638533983034540000005346792853801681069_"
              gradientUnits="userSpaceOnUse"
              x1="127.7719"
              y1="108.9431"
              x2="230.0337"
              y2="108.9431"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="0.0028" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>

            <rect
              x="127.772"
              y="104.97"
              style="
                fill: url(#SVGID_00000147931638533983034540000005346792853801681069_);
              "
              width="102.262"
              height="7.947"
            />

            <linearGradient
              id="SVGID_00000026880633312030644230000011766549626752851610_"
              gradientUnits="userSpaceOnUse"
              x1="256.3412"
              y1="218.448"
              x2="256.3412"
              y2="145.405"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="0.0028" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000026880633312030644230000011766549626752851610_);
              "
              points="227.173,218.448 
              203.332,177.917 219.201,145.405 309.35,145.405 309.35,218.361 			"
            />
          </g>
          <g id="pump">
            <rect
              x="161.242"
              y="403.321"
              class="st13"
              width="35.33"
              height="1.286"
            />
            <rect
              x="153.055"
              y="422.61"
              class="st13"
              width="51.703"
              height="1.286"
            />
            <rect
              x="153.055"
              y="421.324"
              class="st13"
              width="51.703"
              height="1.286"
            />
            <path
              class="st13"
              d="M195.32,402.035h-32.825c-0.692,0-1.253,0.561-1.253,1.253v0.033h35.33v-0.033
              C196.572,402.596,196.011,402.035,195.32,402.035z"
            />
            <path
              :class="pumpStatus(getPumps[0].pumps[0].runPump)"
              d="M150.47,430.326l1.426,4.188c0.616,1.811,0.928,3.672,0.928,5.54v36.18h52.259v-35.305
              c0-2.442,0.533-4.869,1.578-7.183l1.544-3.42H150.47z"
            />
            <polygon
              class="st13"
              points="205.088,515.196 205.08,515.228 152.825,515.228 152.817,515.196 			"
            />
            <polygon
              :class="pumpStatus(getPumps[0].pumps[0].runPump)"
              points="204.373,517.768 203.262,521.729 202.21,525.484 155.703,525.484 154.644,521.729 153.532,517.768 
                    "
            />
            <path
              class="st15"
              d="M152.976,530.628l-0.409,1.164c-2.112,6.01-5.674,11.537-10.438,16.196h0h73.035l0,0
              c-4.763-4.659-8.325-10.186-10.438-16.196l-0.409-1.164H152.976z"
            />
            <path
              :class="pumpStatus(getPumps[0].pumps[0].runPump)"
              d="M153.055,427.754v-3.858v-2.572l8.186-16.717v-1.286c0-0.71,0.772-1.286,1.723-1.286h31.884
              c0.952,0,1.723,0.576,1.723,1.286v1.286l8.186,16.717v2.572v3.858H153.055z"
            />
            <path
              class="st13"
              d="M195.702,401.665l-8.038-3.418h-17.42l-8.125,3.419c-0.542,0.228-0.877,0.656-0.877,1.12v1.821h35.33
              v-1.825C196.572,402.32,196.24,401.894,195.702,401.665z"
            />
            <path class="st16" d="M161.227,436.931" />
            <polygon
              :class="pumpStatus(getPumps[0].pumps[0].runPump)"
              points="205.088,515.196 205.313,495.277 213.486,482.423 143.807,482.423 152.748,495.277 
              152.817,515.196 			"
            />
            <path
              class="st17"
              d="M161.048,473.738h-0.228c-0.889,0-1.609-0.72-1.609-1.609v-35.359c0-0.889,0.72-1.609,1.609-1.609h0.228
              c0.889,0,1.609,0.72,1.609,1.609v35.359C162.658,473.017,161.937,473.738,161.048,473.738z"
            />
            <path
              class="st17"
              d="M170.527,473.738h-0.228c-0.889,0-1.609-0.72-1.609-1.609v-35.359c0-0.889,0.72-1.609,1.609-1.609h0.228
              c0.889,0,1.609,0.72,1.609,1.609v35.359C172.136,473.017,171.416,473.738,170.527,473.738z"
            />
            <path
              class="st17"
              d="M179.144,473.738h-0.228c-0.889,0-1.609-0.72-1.609-1.609v-35.359c0-0.889,0.72-1.609,1.609-1.609h0.228
              c0.889,0,1.609,0.72,1.609,1.609v35.359C180.754,473.017,180.033,473.738,179.144,473.738z"
            />
            <path
              class="st17"
              d="M187.762,473.738h-0.228c-0.889,0-1.609-0.72-1.609-1.609v-35.359c0-0.889,0.72-1.609,1.609-1.609h0.228
              c0.889,0,1.609,0.72,1.609,1.609v35.359C189.371,473.017,188.65,473.738,187.762,473.738z"
            />
            <path
              class="st17"
              d="M196.379,473.738h-0.228c-0.889,0-1.609-0.72-1.609-1.609v-35.359c0-0.889,0.72-1.609,1.609-1.609h0.228
              c0.889,0,1.609,0.72,1.609,1.609v35.359C197.988,473.017,197.267,473.738,196.379,473.738z"
            />
            <rect
              x="150.47"
              y="429.04"
              class="st15"
              width="57.735"
              height="1.286"
            />
            <rect
              x="150.47"
              y="427.754"
              class="st15"
              width="57.735"
              height="1.286"
            />
            <rect
              x="152.625"
              y="525.484"
              :class="pumpStatus(getPumps[0].pumps[0].runPump)"
              width="52.565"
              height="2.572"
            />
            <rect
              x="144.007"
              y="528.056"
              :class="pumpStatus(getPumps[0].pumps[0].runPump)"
              width="69.799"
              height="2.572"
            />
            <polygon
              class="st17"
              points="213.809,515.196 213.809,517.768 144.01,517.768 144.01,515.196 152.817,515.196 152.825,515.228 
              205.08,515.228 205.088,515.196 			"
            />
            <rect
              x="141.545"
              y="475.988"
              class="st17"
              width="74.969"
              height="6.43"
            />
          </g>
          <g id="crop">
            <linearGradient
              id="SVGID_00000098196707142587985410000012207087448143584386_"
              gradientUnits="userSpaceOnUse"
              x1="138.7822"
              y1="468.8218"
              x2="219.1584"
              y2="468.8218"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="0.0028" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>
            <path
              style="
                fill: url(#SVGID_00000098196707142587985410000012207087448143584386_);
              "
              d="M138.782,389.455v158.733h80.376
              V389.455H138.782z M215.577,509.38c0,16.276-16.394,29.471-36.607,29.471c-10.107,0-19.258-3.297-25.888-8.631
              c-6.621-5.334-10.719-12.704-10.719-20.839v-82.284c0-16.276,16.394-29.471,36.607-29.471s36.607,13.195,36.607,29.471V509.38z"
            />
            <path
              class="st19"
              d="M215.577,427.096v82.284c0,16.276-16.394,29.471-36.607,29.471c-10.107,0-19.258-3.297-25.888-8.631
              c-6.621-5.334-10.719-12.704-10.719-20.839v-82.284c0-16.276,16.394-29.471,36.607-29.471S215.577,410.82,215.577,427.096z"
            />
          </g>
        </g>
        <g id="pump02">
          <g id="pipe">
            <linearGradient
              id="SVGID_00000085225315296810802980000006048972856404075695_"
              gradientUnits="userSpaceOnUse"
              x1="247.9661"
              y1="329.6613"
              x2="328.5621"
              y2="329.6613"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="0.0028" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>

            <rect
              x="247.966"
              y="112.917"
              style="
                fill: url(#SVGID_00000085225315296810802980000006048972856404075695_);
              "
              width="80.596"
              height="433.489"
            />
            <polygon
              class="st5"
              points="328.562,227.966 334.714,218.448 310.873,178.713 			"
            />

            <linearGradient
              id="SVGID_00000152983848234474046520000001680708826906884999_"
              gradientUnits="userSpaceOnUse"
              x1="241.2497"
              y1="131.3106"
              x2="335.2784"
              y2="131.3106"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="0.0028" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>

            <rect
              x="241.25"
              y="128.811"
              style="
                fill: url(#SVGID_00000152983848234474046520000001680708826906884999_);
              "
              width="94.029"
              height="5"
            />

            <linearGradient
              id="SVGID_00000157991452096193092330000015100185065995164547_"
              gradientUnits="userSpaceOnUse"
              x1="237.1332"
              y1="108.9431"
              x2="339.395"
              y2="108.9431"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="0.0028" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>

            <rect
              x="237.133"
              y="104.97"
              style="
                fill: url(#SVGID_00000157991452096193092330000015100185065995164547_);
              "
              width="102.262"
              height="7.947"
            />

            <linearGradient
              id="SVGID_00000034804386205536079830000003087479893840379305_"
              gradientUnits="userSpaceOnUse"
              x1="449.0182"
              y1="218.448"
              x2="449.0182"
              y2="145.405"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="0.0028" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000034804386205536079830000003087479893840379305_);
              "
              points="327.938,145.405 
              310.873,178.713 334.714,218.448 587.163,218.448 587.163,145.405 			"
            />
          </g>
          <g id="pump">
            <rect
              x="270.905"
              y="403.041"
              class="st13"
              width="35.33"
              height="1.29"
            />
            <rect
              x="262.719"
              y="422.394"
              class="st13"
              width="51.703"
              height="1.29"
            />
            <rect
              x="262.719"
              y="421.104"
              class="st13"
              width="51.703"
              height="1.29"
            />
            <path
              class="st13"
              d="M304.983,401.751h-32.825c-0.692,0-1.253,0.561-1.253,1.253v0.038h35.33v-0.038
              C306.236,402.311,305.675,401.751,304.983,401.751z"
            />
            <path
              :class="pumpStatus(getPumps[0].pumps[1].runPump)"
              d="M260.134,430.135l1.426,4.202c0.616,1.817,0.928,3.684,0.928,5.558v36.3h52.259v-35.422
              c0-2.451,0.533-4.885,1.578-7.207l1.544-3.431H260.134z"
            />
            <polygon
              class="st13"
              points="314.752,515.287 314.743,515.32 262.489,515.32 262.48,515.287 			"
            />
            <polygon
              :class="pumpStatus(getPumps[0].pumps[1].runPump)"
              points="314.037,517.868 312.925,521.842 311.874,525.609 265.367,525.609 264.307,521.842 
              263.195,517.868 			"
            />
            <path
              class="st15"
              d="M262.639,530.77l-0.409,1.168c-2.112,6.03-5.674,11.575-10.438,16.25l0,0h73.035l0,0
              c-4.763-4.674-8.326-10.22-10.438-16.25l-0.409-1.168H262.639z"
            />
            <path
              :class="pumpStatus(getPumps[0].pumps[1].runPump)"
              d="M262.719,427.555v-3.871v-2.58l8.186-16.773v-1.29c0-0.713,0.772-1.29,1.723-1.29h31.884
              c0.952,0,1.723,0.578,1.723,1.29v1.29l8.186,16.773v2.58v3.871H262.719z"
            />
            <path
              class="st13"
              d="M305.365,401.379l-8.038-3.429h-17.42l-8.125,3.43c-0.542,0.229-0.877,0.658-0.877,1.124v1.827h35.33V402.5
              C306.236,402.037,305.903,401.609,305.365,401.379z"
            />
            <path class="st16" d="M270.89,436.763" />
            <polygon
              :class="pumpStatus(getPumps[0].pumps[1].runPump)"
              points="314.752,515.287 314.976,495.302 323.149,482.406 253.47,482.406 262.411,495.302 262.48,515.287 
                    "
            />
            <path
              class="st17"
              d="M270.712,473.691h-0.228c-0.889,0-1.609-0.72-1.609-1.609v-35.487c0-0.889,0.72-1.609,1.609-1.609h0.228
              c0.889,0,1.609,0.72,1.609,1.609v35.487C272.321,472.971,271.6,473.691,270.712,473.691z"
            />
            <path
              class="st17"
              d="M280.191,473.691h-0.228c-0.889,0-1.609-0.72-1.609-1.609v-35.487c0-0.889,0.72-1.609,1.609-1.609h0.228
              c0.889,0,1.609,0.72,1.609,1.609v35.487C281.8,472.971,281.079,473.691,280.191,473.691z"
            />
            <path
              class="st17"
              d="M288.808,473.691h-0.228c-0.889,0-1.609-0.72-1.609-1.609v-35.487c0-0.889,0.72-1.609,1.609-1.609h0.228
              c0.889,0,1.609,0.72,1.609,1.609v35.487C290.417,472.971,289.696,473.691,288.808,473.691z"
            />
            <path
              class="st17"
              d="M297.425,473.691h-0.228c-0.889,0-1.609-0.72-1.609-1.609v-35.487c0-0.889,0.72-1.609,1.609-1.609h0.228
              c0.889,0,1.609,0.72,1.609,1.609v35.487C299.034,472.971,298.314,473.691,297.425,473.691z"
            />
            <path
              class="st17"
              d="M306.042,473.691h-0.228c-0.889,0-1.609-0.72-1.609-1.609v-35.487c0-0.889,0.72-1.609,1.609-1.609h0.228
              c0.889,0,1.609,0.72,1.609,1.609v35.487C307.651,472.971,306.931,473.691,306.042,473.691z"
            />
            <rect
              x="260.134"
              y="428.845"
              class="st13"
              width="57.735"
              height="1.29"
            />
            <rect
              x="260.134"
              y="427.555"
              class="st13"
              width="57.735"
              height="1.29"
            />
            <rect
              x="262.288"
              y="525.61"
              :class="pumpStatus(getPumps[0].pumps[1].runPump)"
              width="52.565"
              height="2.58"
            />
            <rect
              x="253.671"
              y="528.19"
              :class="pumpStatus(getPumps[0].pumps[1].runPump)"
              width="69.799"
              height="2.58"
            />
            <polygon
              class="st17"
              points="323.472,515.287 323.472,517.868 253.673,517.868 253.673,515.287 262.48,515.287 262.489,515.32 
              314.743,515.32 314.752,515.287 			"
            />
            <rect
              x="251.209"
              y="475.949"
              class="st17"
              width="74.969"
              height="6.451"
            />
          </g>
          <g id="crop">
            <linearGradient
              id="SVGID_00000085968845037598067780000008035643369757518266_"
              gradientUnits="userSpaceOnUse"
              x1="247.9661"
              y1="469.1931"
              x2="328.5621"
              y2="469.1931"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="0.0028" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>
            <path
              style="
                fill: url(#SVGID_00000085968845037598067780000008035643369757518266_);
              "
              d="M247.966,389.752v158.881h80.596
              V389.752H247.966z M324.971,509.79c0,16.291-16.438,29.498-36.707,29.498c-10.134,0-19.311-3.3-25.958-8.639
              c-6.639-5.339-10.749-12.716-10.749-20.859v-82.361c0-16.291,16.438-29.498,36.707-29.498c20.269,0,36.707,13.207,36.707,29.498
              V509.79z"
            />
            <path
              class="st19"
              d="M324.971,427.428v82.361c0,16.291-16.438,29.498-36.707,29.498c-10.134,0-19.311-3.3-25.958-8.639
              c-6.639-5.339-10.749-12.716-10.749-20.859v-82.361c0-16.291,16.438-29.498,36.707-29.498
              C308.533,397.93,324.971,411.137,324.971,427.428z"
            />
          </g>
        </g>
        <g id="pump03">
          <g id="pipe">
            <linearGradient
              id="SVGID_00000012466110473732006540000008291740949177710773_"
              gradientUnits="userSpaceOnUse"
              x1="356.008"
              y1="302.8326"
              x2="436.604"
              y2="302.8326"
              gradientTransform="matrix(1 0 0 -1 0 640)"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.800000e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>

            <rect
              x="356"
              y="112.7"
              style="
                fill: url(#SVGID_00000012466110473732006540000008291740949177710773_);
              "
              width="80.6"
              height="448.9"
            />
            <polygon
              class="st5"
              points="436.6,226.3 442.6,218.4 418.7,177.9 			"
            />

            <linearGradient
              id="SVGID_00000100359943230289679570000015772538094189050043_"
              gradientUnits="userSpaceOnUse"
              x1="349.292"
              y1="508.895"
              x2="443.321"
              y2="508.895"
              gradientTransform="matrix(1 0 0 -1 0 640)"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.800000e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>

            <rect
              x="349.3"
              y="128.6"
              style="
                fill: url(#SVGID_00000100359943230289679570000015772538094189050043_);
              "
              width="94"
              height="5"
            />

            <linearGradient
              id="SVGID_00000075857819789232837020000017634057163724106930_"
              gradientUnits="userSpaceOnUse"
              x1="345.176"
              y1="531.2625"
              x2="447.438"
              y2="531.2625"
              gradientTransform="matrix(1 0 0 -1 0 640)"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.800000e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>

            <rect
              x="345.2"
              y="104.8"
              style="
                fill: url(#SVGID_00000075857819789232837020000017634057163724106930_);
              "
              width="102.3"
              height="7.9"
            />

            <linearGradient
              id="SVGID_00000120546642690247843040000002130427110411087256_"
              gradientUnits="userSpaceOnUse"
              x1="563.902"
              y1="421.552"
              x2="563.902"
              y2="494.595"
              gradientTransform="matrix(1 0 0 -1 0 640)"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.800000e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000120546642690247843040000002130427110411087256_);
              "
              points="442.6,218.4 418.7,177.9 
              436.6,145.4 709.1,145.4 709.1,218.4 			"
            />
          </g>
          <g id="pump">
            <rect x="379.9" y="426.5" class="st13" width="31.6" height="1.2" />
            <rect x="372.6" y="444" class="st13" width="46.2" height="1.2" />
            <rect x="372.6" y="442.8" class="st13" width="46.2" height="1.2" />
            <path
              class="st13"
              d="M410.5,425.4h-29.6c-0.6,0-1,0.5-1,1v0.1h31.6v-0.1C411.5,425.8,411,425.4,410.5,425.4z"
            />
            <path
              :class="pumpStatus(getPumps[0].pumps[2].runPump)"
              d="M370.2,451l1.3,3.8c0.6,1.6,0.8,3.3,0.8,5v32.7h46.7v-31.9c0-2.2,0.5-4.4,1.4-6.5l1.4-3.1L370.2,451
              L370.2,451z"
            />
            <polygon
              class="st13"
              points="419.1,527.7 419.1,527.7 372.4,527.7 372.3,527.7 			"
            />
            <polygon
              :class="pumpStatus(getPumps[0].pumps[2].runPump)"
              points="418.5,530 417.5,533.6 416.5,537 374.9,537 374,533.6 373,530 			"
            />
            <path
              class="st15"
              d="M370.5,541.6l-0.4,1.1c-2.1,5.4-5.6,10.4-10.3,14.6l0,0h71.8l0,0c-4.7-4.2-8.2-9.2-10.3-14.6l-0.4-1.1
              H370.5z"
            />
            <path
              :class="pumpStatus(getPumps[0].pumps[2].runPump)"
              d="M372.6,448.6v-3.5v-2.3l7.3-15.1v-1.2c0-0.6,0.7-1.2,1.5-1.2h28.5c0.9,0,1.5,0.5,1.5,1.2v1.2l7.3,15.1v2.3
              v3.5H372.6z"
            />
            <path
              class="st13"
              d="M410.7,425l-7.2-3.1h-15.6l-7.3,3.1c-0.5,0.2-0.8,0.6-0.8,1v1.6h31.6v-1.6
              C411.5,425.6,411.2,425.2,410.7,425z"
            />
            <path class="st18" d="M379.9,456.9" />
            <polygon
              :class="pumpStatus(getPumps[0].pumps[2].runPump)"
              points="419.1,527.7 419.3,509.7 426.6,498.1 364.3,498.1 372.3,509.7 372.3,527.7 			"
            />
            <path
              class="st17"
              d="M379.8,490.2h-0.5c-0.7,0-1.3-0.6-1.3-1.3v-32.3c0-0.7,0.6-1.3,1.3-1.3h0.5c0.7,0,1.3,0.6,1.3,1.3v32.3
              C381.1,489.6,380.6,490.2,379.8,490.2z"
            />
            <path
              class="st17"
              d="M388.3,490.2h-0.5c-0.7,0-1.3-0.6-1.3-1.3v-32.3c0-0.7,0.6-1.3,1.3-1.3h0.5c0.7,0,1.3,0.6,1.3,1.3v32.3
              C389.6,489.6,389,490.2,388.3,490.2z"
            />
            <path
              class="st17"
              d="M396,490.2h-0.5c-0.7,0-1.3-0.6-1.3-1.3v-32.3c0-0.7,0.6-1.3,1.3-1.3h0.5c0.7,0,1.3,0.6,1.3,1.3v32.3
              C397.3,489.6,396.7,490.2,396,490.2z"
            />
            <path
              class="st17"
              d="M403.7,490.2h-0.5c-0.7,0-1.3-0.6-1.3-1.3v-32.3c0-0.7,0.6-1.3,1.3-1.3h0.5c0.7,0,1.3,0.6,1.3,1.3v32.3
              C405,489.6,404.5,490.2,403.7,490.2z"
            />
            <path
              class="st17"
              d="M411.4,490.2H411c-0.7,0-1.3-0.6-1.3-1.3v-32.3c0-0.7,0.6-1.3,1.3-1.3h0.5c0.7,0,1.3,0.6,1.3,1.3v32.3
              C412.8,489.6,412.2,490.2,411.4,490.2z"
            />
            <rect x="370.2" y="449.8" class="st13" width="51.6" height="1.2" />
            <rect x="370.2" y="448.6" class="st15" width="51.6" height="1.2" />
            <rect
              x="372.2"
              y="537"
              :class="pumpStatus(getPumps[0].pumps[2].runPump)"
              width="47"
              height="2.3"
            />
            <rect
              x="364.5"
              y="539.3"
              :class="pumpStatus(getPumps[0].pumps[2].runPump)"
              width="62.4"
              height="2.3"
            />
            <polygon
              class="st17"
              points="426.9,527.7 426.9,530 364.5,530 364.5,527.7 372.3,527.7 372.4,527.7 419.1,527.7 419.1,527.7 			
              "
            />
            <rect x="362.3" y="492.2" class="st17" width="67.1" height="5.8" />
          </g>
          <g id="crop">
            <linearGradient
              id="SVGID_00000032643837297714796090000003680060862991456658_"
              gradientUnits="userSpaceOnUse"
              x1="356.306"
              y1="153.2675"
              x2="436.306"
              y2="153.2675"
              gradientTransform="matrix(1 0 0 -1 0 640)"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.800000e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>
            <path
              style="
                fill: url(#SVGID_00000032643837297714796090000003680060862991456658_);
              "
              d="M356.3,415.3v142.9h80V415.3H356.3z
               M432.7,523.2c0,14.7-16.3,26.5-36.4,26.5c-10.1,0-19.2-3-25.8-7.8c-6.6-4.8-10.7-11.4-10.7-18.8v-74.1
              c0-14.6,16.3-26.5,36.4-26.5s36.4,11.9,36.4,26.5L432.7,523.2L432.7,523.2z"
            />
            <path
              class="st19"
              d="M432.7,449.2v74.1c0,14.7-16.3,26.5-36.4,26.5c-10.1,0-19.2-3-25.8-7.8c-6.6-4.8-10.7-11.4-10.7-18.8v-74.1
              c0-14.6,16.3-26.5,36.4-26.5S432.7,434.5,432.7,449.2z"
            />
          </g>
        </g>
        <g id="pump4">
          <g id="pipe">
            <g>
              <linearGradient
                id="SVGID_00000069368378823313015750000002097599210307001993_"
                gradientUnits="userSpaceOnUse"
                x1="659.163"
                y1="291.8147"
                x2="739.759"
                y2="291.8147"
                gradientTransform="matrix(1 0 0 -1 0 640)"
              >
                <stop offset="0" style="stop-color: #676767" />
                <stop offset="2.800000e-03" style="stop-color: #686868" />
                <stop offset="0.1657" style="stop-color: #959393" />
                <stop offset="0.3063" style="stop-color: #bcbbbb" />
                <stop offset="0.4177" style="stop-color: #d8d8d7" />
                <stop offset="0.486" style="stop-color: #e5e5e5" />
                <stop offset="0.5583" style="stop-color: #d8d8d7" />
                <stop offset="0.6761" style="stop-color: #bcbbbb" />
                <stop offset="0.8247" style="stop-color: #959393" />
                <stop offset="0.9971" style="stop-color: #686868" />
                <stop offset="1" style="stop-color: #676767" />
              </linearGradient>
              <polygon
                style="
                  fill: url(#SVGID_00000069368378823313015750000002097599210307001993_);
                "
                points="659.2,114.4 659.2,156.2 
                659.2,254.7 659.2,581.9 739.8,581.9 739.8,254.7 739.8,218.7 739.8,114.4 				"
              />
            </g>
            <polygon
              class="st5"
              points="739.8,228.6 750.8,220.7 722.7,180.9 			"
            />

            <linearGradient
              id="SVGID_00000014624515388807680450000008606098445427155343_"
              gradientUnits="userSpaceOnUse"
              x1="652.447"
              y1="507.175"
              x2="746.476"
              y2="507.175"
              gradientTransform="matrix(1 0 0 -1 0 640)"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.800000e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>

            <rect
              x="652.4"
              y="130.3"
              style="
                fill: url(#SVGID_00000014624515388807680450000008606098445427155343_);
              "
              width="94"
              height="5"
            />

            <linearGradient
              id="SVGID_00000007423238999961435000000006264631648320548481_"
              gradientUnits="userSpaceOnUse"
              x1="648.33"
              y1="529.5435"
              x2="750.592"
              y2="529.5435"
              gradientTransform="matrix(1 0 0 -1 0 640)"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.800000e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>

            <rect
              x="648.3"
              y="106.5"
              style="
                fill: url(#SVGID_00000007423238999961435000000006264631648320548481_);
              "
              width="102.3"
              height="7.9"
            />
            <g>
              <linearGradient
                id="SVGID_00000132793969284194789790000011806139519022782143_"
                gradientUnits="userSpaceOnUse"
                x1="825.3492"
                y1="220.7471"
                x2="825.3492"
                y2="149.1394"
              >
                <stop offset="0" style="stop-color: #676767" />
                <stop offset="2.776963e-03" style="stop-color: #686868" />
                <stop offset="0.1657" style="stop-color: #959393" />
                <stop offset="0.3063" style="stop-color: #bcbbbb" />
                <stop offset="0.4177" style="stop-color: #d8d8d7" />
                <stop offset="0.486" style="stop-color: #e5e5e5" />
                <stop offset="0.5583" style="stop-color: #d8d8d7" />
                <stop offset="0.6761" style="stop-color: #bcbbbb" />
                <stop offset="0.8247" style="stop-color: #959393" />
                <stop offset="0.9971" style="stop-color: #686868" />
                <stop offset="1" style="stop-color: #676767" />
              </linearGradient>
              <polygon
                style="
                  fill: url(#SVGID_00000132793969284194789790000011806139519022782143_);
                "
                points="739.8,149.1 722.7,180.9 
                750.8,220.7 928,220.7 856.4,149.1 				"
              />

              <linearGradient
                id="SVGID_00000070803661380443701140000004867792346353014912_"
                gradientUnits="userSpaceOnUse"
                x1="911.1364"
                y1="161.8514"
                x2="911.1364"
                y2="99.4848"
                gradientTransform="matrix(0.721 -0.721 1.3271 1.3271 62.0681 665.1509)"
              >
                <stop offset="0" style="stop-color: #676767" />
                <stop offset="2.776963e-03" style="stop-color: #686868" />
                <stop offset="0.1657" style="stop-color: #959393" />
                <stop offset="0.3063" style="stop-color: #bcbbbb" />
                <stop offset="0.4177" style="stop-color: #d8d8d7" />
                <stop offset="0.486" style="stop-color: #e5e5e5" />
                <stop offset="0.5583" style="stop-color: #d8d8d7" />
                <stop offset="0.6761" style="stop-color: #bcbbbb" />
                <stop offset="0.8247" style="stop-color: #959393" />
                <stop offset="0.9971" style="stop-color: #686868" />
                <stop offset="1" style="stop-color: #676767" />
              </linearGradient>
              <polygon
                style="
                  fill: url(#SVGID_00000070803661380443701140000004867792346353014912_);
                "
                points="926.3,208.3 926.3,220.7 
                924.3,220.7 930.2,226.6 937.4,219.4 				"
              />

              <linearGradient
                id="SVGID_00000126324312121773905810000006322824337869480375_"
                gradientUnits="userSpaceOnUse"
                x1="911.1364"
                y1="161.8514"
                x2="911.1364"
                y2="99.4848"
                gradientTransform="matrix(0.721 -0.721 1.3271 1.3271 62.0681 665.1509)"
              >
                <stop offset="0" style="stop-color: #676767" />
                <stop offset="2.776963e-03" style="stop-color: #686868" />
                <stop offset="0.1657" style="stop-color: #959393" />
                <stop offset="0.3063" style="stop-color: #bcbbbb" />
                <stop offset="0.4177" style="stop-color: #d8d8d7" />
                <stop offset="0.486" style="stop-color: #e5e5e5" />
                <stop offset="0.5583" style="stop-color: #d8d8d7" />
                <stop offset="0.6761" style="stop-color: #bcbbbb" />
                <stop offset="0.8247" style="stop-color: #959393" />
                <stop offset="0.9971" style="stop-color: #686868" />
                <stop offset="1" style="stop-color: #676767" />
              </linearGradient>
              <polygon
                style="
                  fill: url(#SVGID_00000126324312121773905810000006322824337869480375_);
                "
                points="854.6,136.6 847.4,143.8 
                852.7,149.1 867.1,149.1 				"
              />

              <linearGradient
                id="SVGID_00000170269858736222960950000014178411228577625005_"
                gradientUnits="userSpaceOnUse"
                x1="889.4979"
                y1="220.7475"
                x2="889.4979"
                y2="149.139"
              >
                <stop offset="0" style="stop-color: #676767" />
                <stop offset="2.776963e-03" style="stop-color: #686868" />
                <stop offset="0.1657" style="stop-color: #959393" />
                <stop offset="0.3063" style="stop-color: #bcbbbb" />
                <stop offset="0.4177" style="stop-color: #d8d8d7" />
                <stop offset="0.486" style="stop-color: #e5e5e5" />
                <stop offset="0.5583" style="stop-color: #d8d8d7" />
                <stop offset="0.6761" style="stop-color: #bcbbbb" />
                <stop offset="0.8247" style="stop-color: #959393" />
                <stop offset="0.9971" style="stop-color: #686868" />
                <stop offset="1" style="stop-color: #676767" />
              </linearGradient>
              <polygon
                style="
                  fill: url(#SVGID_00000170269858736222960950000014178411228577625005_);
                "
                points="926.3,208.3 867.1,149.1 
                852.7,149.1 924.3,220.7 926.3,220.7 				"
              />

              <linearGradient
                id="SVGID_00000011032528832542719110000004736843610839331247_"
                gradientUnits="userSpaceOnUse"
                x1="911.1364"
                y1="161.8514"
                x2="911.1364"
                y2="99.4848"
                gradientTransform="matrix(0.721 -0.721 1.3271 1.3271 62.0681 665.1509)"
              >
                <stop offset="0" style="stop-color: #676767" />
                <stop offset="2.776963e-03" style="stop-color: #686868" />
                <stop offset="0.1657" style="stop-color: #959393" />
                <stop offset="0.3063" style="stop-color: #bcbbbb" />
                <stop offset="0.4177" style="stop-color: #d8d8d7" />
                <stop offset="0.486" style="stop-color: #e5e5e5" />
                <stop offset="0.5583" style="stop-color: #d8d8d7" />
                <stop offset="0.6761" style="stop-color: #bcbbbb" />
                <stop offset="0.8247" style="stop-color: #959393" />
                <stop offset="0.9971" style="stop-color: #686868" />
                <stop offset="1" style="stop-color: #676767" />
              </linearGradient>
              <polygon
                style="
                  fill: url(#SVGID_00000011032528832542719110000004736843610839331247_);
                "
                points="926.3,208.3 867.1,149.1 
                852.7,149.1 924.3,220.7 926.3,220.7 				"
              />
            </g>
          </g>
          <g id="pump">
            <rect x="685" y="455" class="st13" width="29" height="1.1" />
            <rect x="678.2" y="471.1" class="st13" width="42.4" height="1.1" />
            <rect x="678.2" y="470" class="st13" width="42.4" height="1.1" />
            <path
              class="st13"
              d="M713,453.9h-27.2c-0.5,0-0.9,0.4-0.9,0.9v0.2h29v-0.2C714,454.3,713.5,453.9,713,453.9z"
            />
            <path
              :class="pumpStatus(getPumps[1].pumps[0].runPump)"
              d="M676.1,477.6l1.2,3.5c0.5,1.5,0.8,3.1,0.8,4.6V516h42.9v-29.6c0-2,0.4-4.1,1.3-6l1.3-2.9L676.1,477.6
              L676.1,477.6z"
            />
            <polygon
              class="st13"
              points="720.9,548.7 720.9,548.7 678.1,548.7 678,548.7 			"
            />
            <polygon
              :class="pumpStatus(getPumps[1].pumps[0].runPump)"
              points="720.4,550.8 719.4,554.1 718.6,557.3 680.4,557.3 679.5,554.1 678.6,550.8 			"
            />
            <path
              class="st17"
              d="M674.9,561.6l-0.4,1c-2,5-5.4,9.7-10,13.6l0,0h70l0,0c-4.6-3.9-8-8.5-10-13.6l-0.4-1H674.9z"
            />
            <path
              :class="pumpStatus(getPumps[1].pumps[0].runPump)"
              d="M678.2,475.4v-3.2V470l6.7-14V455c0-0.6,0.6-1.1,1.4-1.1h26.2c0.8,0,1.4,0.5,1.4,1.1v1.1l6.7,14v2.2v3.2
              H678.2z"
            />
            <path
              class="st13"
              d="M713.2,453.6l-6.6-2.9h-14.3l-6.7,2.9c-0.4,0.2-0.7,0.5-0.7,0.9v1.5h29v-1.5
              C714,454.1,713.7,453.8,713.2,453.6z"
            />
            <path class="st16" d="M685,483.1" />
            <polygon
              :class="pumpStatus(getPumps[1].pumps[0].runPump)"
              points="720.9,548.7 721.1,532 727.8,521.2 670.7,521.2 678,532 678,548.7 			"
            />
            <path
              class="st17"
              d="M684.9,513.9h-0.4c-0.7,0-1.2-0.5-1.2-1.2v-29.9c0-0.7,0.5-1.2,1.2-1.2h0.4c0.7,0,1.2,0.5,1.2,1.2v29.9
              C686.1,513.4,685.6,513.9,684.9,513.9z"
            />
            <path
              class="st17"
              d="M692.7,513.9h-0.4c-0.7,0-1.2-0.5-1.2-1.2v-29.9c0-0.7,0.5-1.2,1.2-1.2h0.4c0.7,0,1.2,0.5,1.2,1.2v29.9
              C693.9,513.4,693.4,513.9,692.7,513.9z"
            />
            <path
              class="st17"
              d="M699.8,513.9h-0.4c-0.7,0-1.2-0.5-1.2-1.2v-29.9c0-0.7,0.5-1.2,1.2-1.2h0.4c0.7,0,1.2,0.5,1.2,1.2v29.9
              C701,513.4,700.4,513.9,699.8,513.9z"
            />
            <path
              class="st17"
              d="M706.8,513.9h-0.4c-0.7,0-1.2-0.5-1.2-1.2v-29.9c0-0.7,0.5-1.2,1.2-1.2h0.4c0.7,0,1.2,0.5,1.2,1.2v29.9
              C708,513.4,707.5,513.9,706.8,513.9z"
            />
            <path
              class="st17"
              d="M713.9,513.9h-0.4c-0.7,0-1.2-0.5-1.2-1.2v-29.9c0-0.7,0.5-1.2,1.2-1.2h0.4c0.7,0,1.2,0.5,1.2,1.2v29.9
              C715.1,513.4,714.6,513.9,713.9,513.9z"
            />
            <rect x="676.1" y="476.5" class="st13" width="47.4" height="1.1" />
            <rect x="676.1" y="475.4" class="st13" width="47.4" height="1.1" />
            <rect
              x="677.9"
              y="557.3"
              :class="pumpStatus(getPumps[1].pumps[0].runPump)"
              width="43.1"
              height="2.2"
            />
            <rect
              x="670.8"
              y="559.4"
              :class="pumpStatus(getPumps[1].pumps[0].runPump)"
              width="57.3"
              height="2.2"
            />
            <polygon
              class="st17"
              points="728.1,548.7 728.1,550.8 670.8,550.8 670.8,548.7 678,548.7 678.1,548.7 720.9,548.7 720.9,548.7 
                    "
            />
            <rect x="668.8" y="515.8" class="st17" width="61.5" height="5.4" />
          </g>
          <g id="crop">
            <linearGradient
              id="SVGID_00000009561722434986220760000001442032216865863043_"
              gradientUnits="userSpaceOnUse"
              x1="659.461"
              y1="126.2458"
              x2="739.461"
              y2="126.2458"
              gradientTransform="matrix(1 0 0 -1 0 640)"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.800000e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>
            <path
              style="
                fill: url(#SVGID_00000009561722434986220760000001442032216865863043_);
              "
              d="M659.5,444.4v138.8h80V444.4H659.5z
               M735.9,549.2c0,14.2-16.3,25.8-36.4,25.8c-10.1,0-19.2-2.9-25.8-7.5c-6.6-4.7-10.7-11.1-10.7-18.2v-71.9
              c0-14.2,16.3-25.8,36.4-25.8c20.1,0,36.4,11.5,36.4,25.8L735.9,549.2L735.9,549.2z"
            />
            <path
              class="st19"
              d="M735.9,477.3v71.9c0,14.2-16.3,25.8-36.4,25.8c-10.1,0-19.2-2.9-25.8-7.5c-6.6-4.7-10.7-11.1-10.7-18.2
              v-71.9c0-14.2,16.3-25.8,36.4-25.8C719.6,451.5,735.9,463,735.9,477.3z"
            />
          </g>
        </g>

        <text transform="matrix(1 0 0 1 150 93)" class="st35 st36">
          ปั๊มที่ 1
        </text>
        <text transform="matrix(1 0 0 1 256 93)" class="st35 st36">
          ปั๊มที่ 2
        </text>
        <text transform="matrix(1 0 0 1 364 93)" class="st35 st36">
          ปั๊มที่ 3
        </text>
        <text transform="matrix(1 0 0 1 668 93)" class="st35 st36">
          ปั๊มที่ 1
        </text>

        <path
          v-if="
            getPumps[1].pumps[0].runPump ||
            getPumps[0].pumps[0].runPump ||
            getPumps[0].pumps[1].runPump ||
            getPumps[0].pumps[2].runPump
          "
          class="st38"
          d="M869.8,151.8h61.3c62.8,0,118.4,40.7,137.3,100.7v0H965.2v-5.3c0-19-15.4-34.4-34.4-34.4l0,0L869.8,151.8z"
        />
      </g>

      <!-- Value Gauge -->
      <g id="valueMeterLeft">
        <rect class="st39" width="75.1" height="640" />
        <text transform="matrix(1 0 0 1 2.5 228.6094)" class="st35">
          เซนติเมตร
        </text>
        <text transform="matrix(1 0 0 1 2.5 260.3976)" class="st36">
          {{ Math.round(getPumps[0].pond.level.max) * 100 }}
        </text>
        <text transform="matrix(1 0 0 1 2.5 334)" class="st36">
          {{ Math.round(getPumps[0].pond.level.max / 1.333333333) * 100 }}
        </text>
        <text transform="matrix(1 0 0 1 2.5 552.3)" class="st36">
          {{ Math.round(getPumps[0].pond.level.max / 4) * 100 }}
        </text>
        <text transform="matrix(1 0 0 1 15 626)" class="st36">0</text>
      </g>

      <g id="valueMeterRight">
        <rect x="822.1" y="220.7" class="st39" width="72.5" height="419.3" />
        <text transform="matrix(1 0 0 1 824 236)" class="st35">เซนติเมตร</text>
        <text transform="matrix(1 0 0 1 850 260.0804)" class="st36">
          {{ Math.round(getPumps[1].pond.level.max) * 100 }}
        </text>
        <text transform="matrix(1 0 0 1 850 384)" class="st36">
          {{ Math.round(getPumps[1].pond.level.max / 1.333333333) * 100 }}
        </text>
        <text transform="matrix(1 0 0 1 850 504)" class="st36">
          {{ Math.round(getPumps[1].pond.level.max / 4) * 100 }}
        </text>
        <text transform="matrix(1 0 0 1 865 626)" class="st36">0</text>
      </g>

      <g id="line">
        <g id="left">
          <rect class="st42" width="75.1" height="640" />
          <line class="st43" x1="75.1" y1="252.5" x2="51.3" y2="252.5" />
          <line class="st43" x1="75.1" y1="259.8" x2="64.9" y2="259.8" />
          <line class="st43" x1="75.1" y1="267.1" x2="64.9" y2="267.1" />
          <line class="st43" x1="75.1" y1="274.4" x2="64.9" y2="274.4" />
          <line class="st43" x1="75.1" y1="281.7" x2="64.9" y2="281.7" />
          <line class="st43" x1="75.1" y1="289" x2="59.2" y2="289" />
          <line class="st43" x1="75.1" y1="296.3" x2="64.9" y2="296.3" />
          <line class="st43" x1="75.1" y1="303.6" x2="64.9" y2="303.6" />
          <line class="st43" x1="75.1" y1="311" x2="64.9" y2="311" />
          <line class="st43" x1="75.1" y1="318.3" x2="64.9" y2="318.3" />
          <line class="st43" x1="75.1" y1="325.6" x2="59.2" y2="325.6" />
          <line class="st43" x1="75.1" y1="332.9" x2="64.9" y2="332.9" />
          <line class="st43" x1="75.1" y1="340.2" x2="64.9" y2="340.2" />
          <line class="st43" x1="75.1" y1="347.5" x2="64.9" y2="347.5" />
          <line class="st43" x1="75.1" y1="354.8" x2="64.9" y2="354.8" />
          <line class="st43" x1="75.1" y1="362.2" x2="59.2" y2="362.2" />
          <line class="st43" x1="75.1" y1="369.5" x2="64.9" y2="369.5" />
          <line class="st43" x1="75.1" y1="376.8" x2="64.9" y2="376.8" />
          <line class="st43" x1="75.1" y1="384.1" x2="64.9" y2="384.1" />
          <line class="st43" x1="75.1" y1="391.4" x2="64.9" y2="391.4" />
          <line class="st43" x1="75.1" y1="398.7" x2="59.2" y2="398.7" />
          <line class="st43" x1="75.1" y1="406" x2="64.9" y2="406" />
          <line class="st43" x1="75.1" y1="413.4" x2="64.9" y2="413.4" />
          <line class="st43" x1="75.1" y1="420.7" x2="64.9" y2="420.7" />
          <line class="st43" x1="75.1" y1="428" x2="64.9" y2="428" />
          <line class="st43" x1="75.1" y1="435.3" x2="59.2" y2="435.3" />
          <line class="st43" x1="75.1" y1="442.6" x2="64.9" y2="442.6" />
          <line class="st43" x1="75.1" y1="449.9" x2="64.9" y2="449.9" />
          <line class="st43" x1="75.1" y1="457.2" x2="64.9" y2="457.2" />
          <line class="st43" x1="75.1" y1="464.6" x2="64.9" y2="464.6" />
          <line class="st43" x1="75.1" y1="471.9" x2="59.2" y2="471.9" />
          <line class="st43" x1="75.1" y1="479.2" x2="64.9" y2="479.2" />
          <line class="st43" x1="75.1" y1="486.5" x2="64.9" y2="486.5" />
          <line class="st43" x1="75.1" y1="493.8" x2="64.9" y2="493.8" />
          <line class="st43" x1="75.1" y1="501.1" x2="64.9" y2="501.1" />
          <line class="st43" x1="75.1" y1="508.4" x2="59.2" y2="508.4" />
          <line class="st43" x1="75.1" y1="515.8" x2="64.9" y2="515.8" />
          <line class="st43" x1="75.1" y1="523.1" x2="64.9" y2="523.1" />
          <line class="st43" x1="75.1" y1="530.4" x2="64.9" y2="530.4" />
          <line class="st43" x1="75.1" y1="537.7" x2="64.9" y2="537.7" />
          <line class="st43" x1="75.1" y1="545" x2="59.2" y2="545" />
          <line class="st43" x1="75.1" y1="552.3" x2="64.9" y2="552.3" />
          <line class="st43" x1="75.1" y1="559.6" x2="64.9" y2="559.6" />
          <line class="st43" x1="75.1" y1="567" x2="64.9" y2="567" />
          <line class="st43" x1="75.1" y1="574.3" x2="64.9" y2="574.3" />
          <line class="st43" x1="75.1" y1="581.6" x2="59.2" y2="581.6" />
          <line class="st43" x1="75.1" y1="588.9" x2="64.9" y2="588.9" />
          <line class="st43" x1="75.1" y1="596.2" x2="64.9" y2="596.2" />
          <line class="st43" x1="75.1" y1="603.5" x2="64.9" y2="603.5" />
          <line class="st43" x1="75.1" y1="610.8" x2="64.9" y2="610.8" />
          <line class="st43" x1="75.1" y1="618.2" x2="51.3" y2="618.2" />
        </g>
        <g id="right">
          <line class="st43" x1="822.1" y1="618.2" x2="846" y2="618.2" />
          <line class="st43" x1="822.1" y1="606" x2="833.1" y2="606" />
          <line class="st43" x1="822.1" y1="593.8" x2="833.1" y2="593.8" />
          <line class="st43" x1="822.1" y1="581.6" x2="833.1" y2="581.6" />
          <line class="st43" x1="822.1" y1="569.4" x2="833.1" y2="569.4" />
          <line class="st43" x1="822" y1="557.2" x2="837.9" y2="557.2" />
          <line class="st43" x1="822.1" y1="545" x2="833.1" y2="545" />
          <line class="st43" x1="822.1" y1="532.8" x2="833.1" y2="532.8" />
          <line class="st43" x1="822.1" y1="520.6" x2="833.1" y2="520.6" />
          <line class="st43" x1="822.1" y1="508.4" x2="833.1" y2="508.4" />
          <line class="st43" x1="822" y1="496.3" x2="837.9" y2="496.3" />
          <line class="st43" x1="822.1" y1="484.1" x2="833.1" y2="484.1" />
          <line class="st43" x1="822.1" y1="471.9" x2="833.1" y2="471.9" />
          <line class="st43" x1="822.1" y1="459.7" x2="833.1" y2="459.7" />
          <line class="st43" x1="822.1" y1="447.5" x2="833.1" y2="447.5" />
          <line class="st43" x1="822" y1="435.3" x2="837.9" y2="435.3" />
          <line class="st43" x1="822.1" y1="423.1" x2="833.1" y2="423.1" />
          <line class="st43" x1="822.1" y1="410.9" x2="833.1" y2="410.9" />
          <line class="st43" x1="822.1" y1="398.7" x2="833.1" y2="398.7" />
          <line class="st43" x1="822.1" y1="386.5" x2="833.1" y2="386.5" />
          <line class="st43" x1="822" y1="374.4" x2="837.9" y2="374.4" />
          <line class="st43" x1="822.1" y1="362.2" x2="833.1" y2="362.2" />
          <line class="st43" x1="822.1" y1="350" x2="833.1" y2="350" />
          <line class="st43" x1="822.1" y1="337.8" x2="833.1" y2="337.8" />
          <line class="st43" x1="822.1" y1="325.6" x2="833.1" y2="325.6" />
          <line class="st43" x1="822" y1="313.4" x2="837.9" y2="313.4" />
          <line class="st43" x1="822.1" y1="301.2" x2="833.1" y2="301.2" />
          <line class="st43" x1="822.1" y1="289" x2="833.1" y2="289" />
          <line class="st43" x1="822.1" y1="276.8" x2="833.1" y2="276.8" />
          <line class="st43" x1="822.1" y1="264.6" x2="833.1" y2="264.6" />
          <line class="st43" x1="822.1" y1="252.5" x2="846" y2="252.5" />
        </g>
      </g>

      <!-- Water guage on pumps  -->
      <g id="valueLEFT">
        <text transform="matrix(1 0 0 1 168 600)" class="st44">
          <tspan x="0" y="0">
            {{ getPumps[0].pond.level.value }}
          </tspan>
          <tspan x="100" y="0 ">ม.</tspan>
        </text>
      </g>

      <g id="valueRight">
        <text transform="matrix(1 0 0 1 640 618)" class="st44">
          <tspan x="0" y="0">
            {{ getPumps[1].pond.level.value }}
          </tspan>
          <tspan x="100" y="0">ม.</tspan>
        </text>
      </g>

      <!-- Name pumps -->
      <g id="name">
        <path
          class="st45"
          d="M1007.5,4.5H826.1v108.4h181.3c29.9,0,54.2-24.3,54.2-54.2v0C1061.6,28.8,1037.4,4.5,1007.5,4.5z"
        />
        <text transform="matrix(1 0 0 1 839.9006 43.1301)">
          <tspan x="5" y="30" class="st41">สุขุมวิท</tspan>
          <tspan x="135" y="30" class="st41">55</tspan>
        </text>

        <text transform="matrix(1 0 0 1 940 415)" class="st49">คลองแสนแสบ</text>
      </g>
      <g id="level">
        <!-- left -->

        <!-- medium -->
        <rect
          v-if="
            getPumps[0].pond.level.medium != 0 &&
            getPumps[0].pond.level.max != 0 &&
            getPumps[0].pond.level.medium <= getPumps[0].pond.level.max
          "
          :class="checkMedium(getPumps[0].pond)"
          :y="rectMedium(getPumps[0].pond.level)"
          x="455"
          class="st50"
          width="51"
          height="28"
        />
        <text
          v-if="
            getPumps[0].pond.level.medium != 0 &&
            getPumps[0].pond.level.max != 0 &&
            getPumps[0].pond.level.medium <= getPumps[0].pond.level.max
          "
          :class="checkMedium(getPumps[0].pond)"
          :y="levelMedium(getPumps[0].pond.level)"
          x="472"
        >
          MD
        </text>
        <!-- hi -->
        <rect
          v-if="
            getPumps[0].pond.level.high != 0 &&
            getPumps[0].pond.level.max != 0 &&
            getPumps[0].pond.level.high <= getPumps[0].pond.level.max
          "
          :class="checkHi(getPumps[0].pond)"
          :y="rectHigh(getPumps[0].pond.level)"
          x="455"
          class="st50"
          width="51"
          height="28"
        />
        <text
          v-if="
            getPumps[0].pond.level.high != 0 &&
            getPumps[0].pond.level.max != 0 &&
            getPumps[0].pond.level.high <= getPumps[0].pond.level.max
          "
          :class="checkHi(getPumps[0].pond)"
          :y="levelHigh(getPumps[0].pond.level)"
          x="468"
        >
          High
        </text>
        <!-- hihi -->
        <rect
          v-if="
            getPumps[0].pond.level.highHigh != 0 &&
            getPumps[0].pond.level.max != 0 &&
            getPumps[0].pond.level.highHigh <= getPumps[0].pond.level.max
          "
          :class="checkHihi(getPumps[0].pond)"
          :y="rectHihi(getPumps[0].pond.level)"
          x="455"
          class="st50"
          width="51"
          height="28"
        />
        <text
          v-if="
            getPumps[0].pond.level.highHigh != 0 &&
            getPumps[0].pond.level.max != 0 &&
            getPumps[0].pond.level.highHigh <= getPumps[0].pond.level.max
          "
          :class="checkHihi(getPumps[0].pond)"
          :y="levelHihi(getPumps[0].pond.level)"
          x="472"
        >
          HH
        </text>

        <!-- right  -->
        <!-- Hihi -->

        <rect
          v-if="
            getPumps[1].pond.level.highHigh != 0 &&
            getPumps[1].pond.level.max != 0 &&
            getPumps[1].pond.level.highHigh <= getPumps[1].pond.level.max
          "
          :class="checkHihi(getPumps[1].pond)"
          :y="rectHihi(getPumps[1].pond.level)"
          x="552"
          class="st50"
          width="51"
          height="28"
        />
        <text
          v-if="
            getPumps[1].pond.level.highHigh != 0 &&
            getPumps[1].pond.level.max != 0 &&
            getPumps[1].pond.level.highHigh <= getPumps[1].pond.level.max
          "
          :class="checkHihi(getPumps[1].pond)"
          :y="levelHihi(getPumps[1].pond.level)"
          x="567"
        >
          HH
        </text>

        <!-- hi -->
        <rect
          v-if="
            getPumps[1].pond.level.high != 0 &&
            getPumps[1].pond.level.max != 0 &&
            getPumps[1].pond.level.high <= getPumps[1].pond.level.max
          "
          :y="rectHigh(getPumps[1].pond.level)"
          :class="checkHi(getPumps[1].pond)"
          x="551.6"
          class="st51"
          width="51"
          height="28"
        />
        <text
          v-if="
            getPumps[1].pond.level.high != 0 &&
            getPumps[1].pond.level.max != 0 &&
            getPumps[1].pond.level.high <= getPumps[1].pond.level.max
          "
          :class="checkHi(getPumps[1].pond)"
          :y="levelHigh(getPumps[1].pond.level)"
          x="563"
        >
          High
        </text>

        <!-- meduim -->
        <rect
          v-if="
            getPumps[1].pond.level.medium != 0 &&
            getPumps[1].pond.level.max != 0 &&
            getPumps[1].pond.level.medium <= getPumps[1].pond.level.max
          "
          :y="rectMedium(getPumps[1].pond.level)"
          :class="checkMedium(getPumps[1].pond)"
          x="551.6"
          class="st50"
          width="51"
          height="28"
        />
        <text
          v-if="
            getPumps[1].pond.level.medium != 0 &&
            getPumps[1].pond.level.max != 0 &&
            getPumps[1].pond.level.medium <= getPumps[1].pond.level.max
          "
          :class="checkMedium(getPumps[1].pond)"
          :y="levelMedium(getPumps[1].pond.level)"
          x="567"
        >
          MD
        </text>
      </g>
    </svg>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  name: "WatercontrolAppStation55",

  data() {
    return {
      data: {
        id: "",
        macAddress: "",
      },
      loader: true,
    };
  },

  computed: {
    getPumps() {
      return this.$store.getters.allPumps;
    },
    getStation() {
      return this.$store.getters.stations;
    },
  },

  created() {
    this.data.id = Cookies.get("stationID");
    this.data.macAddress = Cookies.get("macAddress");
    // console.log(this.data);
  },

  mounted() {
    this.$store.dispatch("getPumps", this.data).then((res) => {
      this.loader = false;
    });
  },

  methods: {
    pumpStatus(p) {
      if (p == true) {
        return "st101";
      } else {
        return "st100";
      }
    },
    fontSizeWaterL(w) {
      let size = (w.value * 364.5) / w.max;
      // console.log(size);
      return size;
    },
    fontSizeWaterR(w) {
      // console.log(w)
      let size = ([w.value] * 364.5) / w.max;
      return 1009 - size;
    },
    waterYP1(h) {
      if (h.value <= h.max) {
        let size = (h.value * 350) / h.max;
        return size;
      } else {
        console.log("ปริมาณน้ำมากกว่าความจุบ่อ");
        let size = ([h.max] * 350) / h.max;
        return size;
      }
    },

    waterYP2(h) {
      if (h.value <= h.max) {
        let size = (h.value * 364.5) / h.max;
        return size;
      } else {
        console.log("ปริมาณน้ำมากกว่าความจุบ่อ");
        let size = ([h.max] * 364.5) / h.max;
        return size;
      }
    },
    checkHihi(l) {
      let level = l.level;
      // console.log(level);
      if (level.value >= level.highHigh && level.value) {
        // console.log("hihi");
        return "hihi";
      } else {
        return "st50";
      }
    },
    checkHi(l) {
      let level = l.level;
      // console.log(level);

      if (level.value >= level.high && level.value < level.highHigh) {
        return "hi";
      } else {
        return "st50 ";
      }
    },
    checkMedium(l) {
      let level = l.level;
      if (level.value >= level.medium && level.value < level.high) {
        return "me";
      } else {
        return "st50 ";
      }
    },
    rectHihi(y) {
      let size = ([y.highHigh] * 358) / y.max;

      return 612 - size;
    },
    rectHigh(y) {
      let size = ([y.high] * 358) / y.max;

      return 612 - size;
    },
    rectMedium(y) {
      let size = ([y.medium] * 358) / y.max;

      return 612 - size;
    },
    levelHihi(y) {
      let size = ([y.highHigh] * 358) / y.max;

      // console.log(425 + size);
      return 632 - size;
    },
    levelHigh(y) {
      // console.log(y.high);
      let size = ([y.high] * 358) / y.max;

      // console.log(size);
      return 632 - size;
    },
    levelMedium(y) {
      // console.log(y.medium);
      let size = ([y.medium] * 358) / y.max;

      return 632 - size;
    },
  },
};
</script>

<style scoped>
.red {
  fill: #EF5350;
  opacity: 0.5;
}
.st101 {
  fill: #009245;
  stroke: #000000;
  stroke-miterlimit: 10;
}
.st100 {
  fill: red;
  stroke: #000000;
  stroke-miterlimit: 10;
}
.st0 {
  fill: url(#SVGID_1_);
}
.st1 {
  fill: url(#SVGID_00000090272121297605327820000013821223404708831872_);
}
.st2 {
  fill: url(#SVGID_00000096745256340991455290000006405541786689845918_);
}
.st3 {
  opacity: 0.76;
  fill: #00aeef;
}
.st4 {
  fill: #e0e0e0;
}
.st5 {
  fill: #676767;
}
.st6 {
  fill: url(#SVGID_00000145738338328239483990000003056642161761460638_);
}
.st7 {
  fill: url(#SVGID_00000119115907678709706490000005714832998819918746_);
}
.st8 {
  opacity: 0.3;
  fill: #5dccf5;
}
.st9 {
  fill: url(#SVGID_00000093870593520457605000000009280541257318380207_);
}
.st10 {
  fill: url(#SVGID_00000139282918683919711480000000956183956072064660_);
}
.st11 {
  fill: url(#SVGID_00000041290054192473574890000015471953997009092282_);
}
.st12 {
  fill: url(#SVGID_00000006692416924497407270000012441711445766431408_);
}
.st13 {
  fill: #8f8f8f;
  stroke: #010101;
  stroke-miterlimit: 10;
}
.st14 {
  fill: #c1282d;
  stroke: #010101;
  stroke-miterlimit: 10;
}
.st15 {
  fill: #525252;
  stroke: #010101;
  stroke-miterlimit: 10;
}
.st16 {
  fill: none;
  stroke: #010101;
  stroke-miterlimit: 10;
}
.st17 {
  fill: #010101;
}
.st18 {
  fill: url(#SVGID_00000060733921785175317980000002089572238394155663_);
}
.st19 {
  fill: none;
  stroke: #ffffff;
  stroke-miterlimit: 10;
}
.st20 {
  fill: url(#SVGID_00000133530544189025258200000002746260531957166515_);
}
.st21 {
  fill: url(#SVGID_00000045613697827871933760000011870499871094014861_);
}
.st22 {
  fill: url(#SVGID_00000080172311902431992890000003268277771901188494_);
}
.st23 {
  fill: url(#SVGID_00000010997916092946235870000007515905617716549794_);
}
.st24 {
  fill: url(#SVGID_00000142177571912562565830000003171590689835539881_);
}
.st25 {
  fill: url(#SVGID_00000132803797923719351570000011786857780169492612_);
}
.st26 {
  fill: url(#SVGID_00000117660876176634473110000015220877622125017004_);
}
.st27 {
  fill: url(#SVGID_00000017478077957438186670000005319682252070089611_);
}
.st28 {
  fill: url(#SVGID_00000122690548080874716840000018148371889736324480_);
}
.st29 {
  fill: url(#SVGID_00000150799623230816088930000009738085039254839946_);
}
.st30 {
  fill: url(#SVGID_00000172440491611842546300000017302479956402497950_);
}
.st31 {
  fill: url(#SVGID_00000042718513557827103130000004607544453268779937_);
}
.st32 {
  fill: url(#SVGID_00000103232711611184519610000012678982472073249722_);
}
.st33 {
  fill: url(#SVGID_00000016789886167645602330000003847621393514483350_);
}
.st34 {
  fill: url(#SVGID_00000130614370059246258880000009431971302883013784_);
}
.st35 {
  font-size: 17px;
}
.st36 {
  font-size: 24px;
}
.st37 {
  fill: url(#SVGID_00000019660358377121582010000000198197723149407672_);
}
.st38 {
  opacity: 0.88;
  fill: #5dccf5;
}
.st39 {
  fill: #ffffff;
}

.st41 {
  font-size: 42px;
}
.st42 {
  display: none;
  fill: #ffffff;
}
.st43 {
  fill: none;
  stroke: #000000;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
.st44 {
  font-size: 42px;
}
.st45 {
  fill: #ffffff;
  stroke: #000000;
  stroke-width: 8;
  stroke-miterlimit: 10;
}
.st46 {
  font-size: 42px;
}
.st47 {
  fill: #1a1a1b;
}
.st48 {
  font-size: 30px;
}
.st49 {
  font-size: 22px;
}
.st50 {
  fill: #fff;
  stroke: #000;
  stroke-width: 1;
}

.me {
  fill: orange;
  stroke: #000;
  stroke-width: 1;

  animation: fadeIn 1s ease-in-out forwards 1s;
}
.hi {
  fill: orangered;

  stroke: #000;
  stroke-width: 1;
  animation: fadeIn 1s ease-in-out forwards 1s;
}
.hihi {
  fill: red;
  stroke: #000;
  stroke-width: 1;
  animation: fadeIn 1s ease-in-out infinite;
}
.th {
  font-size: 18px;
  animation: fadeIn 1s ease-in-out forwards 1s;
}
.water {
  opacity: 0.76;
  fill: #00aeef;
  animation: waterout 5s ease-in-out both;
}

@keyframes fadeIn {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.7;
  }
}

@keyframes waterout {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.76;
  }
}
</style>
