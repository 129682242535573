import authHeader from "@/service/auth-header";
import axios from "axios";

const apiURLPump = process.env.VUE_APP_API_URL + "pumps/";
const apiURLPond = process.env.VUE_APP_API_URL + "ponds/";

export const pumps = {
  state: {
    pumps: [],
    ponds: [],
    pond: [],
  },
  getters: {
    allPumps: (state) => state.pumps,
    ponds: (state) => state.ponds,
    onePond: (state) => state.pond,
  },
  mutations: {
    SOCKET_STATUS(state, payload) {
      console.log(payload);
      // let indexPump;
      const index = state.pumps.findIndex((p) => p.pond._id === payload.pondID);
      if (index > -1) {
        const indexPump = state.pumps[index].pumps.findIndex(
          (d) => d._id == payload.pumpID
        );

        // console.log(payload);
        switch (payload.type) {
          case "On":
            state.pumps[index].pumps[indexPump].On = payload.on;
            break;
          case "RunPump":
            state.pumps[index].pumps[indexPump].runPump = payload.runPump;
            break;
          // case "Alarm":
          //   state.pumps[index].pumps[indexPump].alarm = payload.alarm;
          //   break;
          case "Mode":
            state.pumps[index].pumps[indexPump].Mode = payload.Mode;
            break;
          case "Flow":
            state.pumps[index].pumps[indexPump].flow = payload.flow;
            break;
          case "Min":
            state.pumps[index].pumps[indexPump].time.min = payload.min;
            break;
          case "Hour":
            state.pumps[index].pumps[indexPump].time.hour = payload.hour;
            break;
          case "Low":
            state.pumps[index].pumps[indexPump].level.low = payload.low;
            break;
          case "LowLow":
            state.pumps[index].pumps[indexPump].level.lowlow = payload.lowlow;
            break;
          case "Medium":
            state.pumps[index].pond.level.medium = payload.medium;
            break;
          case "High":
            state.pumps[index].pond.level.high = payload.high;
            break;
          case "HiHi":
            state.pumps[index].pond.level.highHigh = payload.highhigh;
            break;
          case "Level":
            // console.log("ss");
            state.pumps[index].pond.level.value = payload.level;
            break;
          case "Warning":
            // console.log("ss");
            state.pumps[index].pond.warning = payload.warning;
            break;
          case "Alarm":
            console.log("alarm");
            state.pumps[index].pumps[indexPump].alarm[
              payload.indexAlarm
            ].status = payload.status;
            console.log(state.pumps[index].alarm);
            break;
        }
      }

      // if (payload.type == "Pump" && index > -1) {
      //   indexPump = state.pumps[index].pumps.findIndex(
      //     (d) => d._id == payload.pumpID
      //   );

      //   if (indexPump > -1) {
      //     console.log(indexPump);
      //     state.pumps[index].pumps[indexPump].runPump = payload.pump.runPump;
      //     state.pumps[index].pumps[indexPump].On = payload.pump.On;
      //     state.pumps[index].pumps[indexPump].Mode = payload.pump.Mode;
      //     state.pumps[index].pumps[indexPump].level.low =
      //       payload.pump.level.low;
      //     state.pumps[index].pumps[indexPump].level.lowlow =
      //       payload.pump.level.lowlow;
      //     state.pumps[index].pumps[indexPump].alarm = payload.pump.alarm;

      //     // console.log(payload.pump.flow);

      //     // console.log(state.pumps[index].pumps[indexPump].alarm);
      //   }
      // } else if (payload.type == "Pond" && index > -1) {
      //   state.pumps[index].pond.level.value = payload.pond.level.value;
      //   state.pumps[index].pond.level.max = payload.pond.level.max;
      //   state.pumps[index].pond.level.highHigh = payload.pond.level.highHigh;
      //   state.pumps[index].pond.level.high = payload.pond.level.high;
      //   state.pumps[index].pond.level.medium = payload.pond.level.medium;
      //   state.pumps[index].pond.warning = payload.pond.warning;
      // } else if (payload.type == "Flow" && index > -1) {
      //   indexPump = state.pumps[index].pumps.findIndex(
      //     (d) => d._id == payload.pumpID
      //   );
      //   state.pumps[index].pumps[indexPump].flow = payload.flow;
      // } else if (payload.type == "Hour" && index > -1) {
      //   indexPump = state.pumps[index].pumps.findIndex(
      //     (d) => d._id == payload.pumpID
      //   );
      //   state.pumps[index].pumps[indexPump].time.hour = payload.hour;
      //   state.pumps[index].pumps[indexPump].time.min = payload.pump.time.min;
      // } else if (payload.type == "Min" && index > -1) {
      //   indexPump = state.pumps[index].pumps.findIndex(
      //     (d) => d._id == payload.pumpID
      //   );
      //   state.pumps[index].pumps[indexPump].time.min = payload.min;
      // }
    },

    setPumps(state, pumps) {
      state.pumps = pumps;
    },
    getPumps(state, pump) {
      state.pumps = pump;
    },
    setLevel(state, ponds) {
      console.log(state);
      console.log(ponds);
      const index = state.pumps.findIndex((p) => p.pond._id === ponds.id);
      console.log(index);

      const indexPond = state.pumps[index].pond.level;
      console.log(indexPond);

      if (index > -1) {
        indexPond.highHigh = ponds.highHigh;
        indexPond.high = ponds.high;
        indexPond.medium = ponds.medium;
        indexPond.max = ponds.max;
      }
      console.log(state.pumps[index].pond.level);
    },
    setOn(state, data) {
      console.log(state);
      console.log(data);
      const index = state.pumps.findIndex((p) => p.pond._id === data.pondID);
      console.log(index);
      if (index > -1) {
        const indexpump = state.pumps[index].pumps.findIndex(
          (d) => d._id === data.pump._id
        );
        const indexPump = state.pumps[index].On;

        console.log(indexPump);

        console.log(pumps);

        if (indexpump > -1) {
          state.pumps[index].pumps[indexpump].On = data.pump.On;
        }
        console.log(state.pumps[index].pond.level);
      }
    },
    setMode(state, data) {
      console.log(state);
      console.log(data);
      const index = state.pumps.findIndex((p) => p.pond._id === data.pondID);

      //console.log(index);

      if (index > -1) {
        const indexpump = state.pumps[index].pumps.findIndex(
          (d) => d._id === data.pump._id
        );
        console.log(indexpump);

        if (indexpump > -1) {
          state.pumps[index].pumps[indexpump].Mode = data.pump.Mode;

          state.pumps[index].pumps[indexpump].level.low = data.pump.level.low;
          state.pumps[index].pumps[indexpump].level.lowlow =
            data.pump.level.lowlow;
        }
      }
      console.log(state.pumps[index]);
    },

    // setPump(state, data) {
    //   console.log(state);
    //   console.log(data);

    //   const index = state.pumps.findIndex((s) => s.pond._id === data.pondID);

    // },
  },
  actions: {
    // POND
    async getPonds({ commit }, data) {
      console.log(data);
      return axios
        .get(apiURLPond + data.id, { headers: authHeader() })
        .then((res) => {
          console.log(res);
          commit("setPumps", res.data.ponds);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editPond({ commit }, data) {
      return axios
        .put(
          apiURLPond + "setLevel/" + data.id,
          { station: data.id, name: data.name },
          { headers: authHeader() }
        )
        .then((res) => {
          commit("setPond", data);
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addPonds({ commit }, data) {
      return axios
        .post(
          apiURLPond,
          { station: data.id, name: data.name },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deletePonds({ commit }, id) {
      return axios
        .delete(apiURLPond + id, { headers: authHeader() })
        .them((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    setPond({ commit }, data) {
      console.log(data);
      return axios
        .put(
          apiURLPond + "setLevel/" + data.id,
          {
            id: data.id,
            highHigh: data.highHigh,
            high: data.high,
            medium: data.medium,
            max: data.max,
            macAddress: data.macAddress,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res);
          commit("setLevel", data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // PUMPs
    async getPumps(context, data) {
      // console.log(data);
      return axios
        .get(apiURLPump + data.id, { headers: authHeader() })
        .then((res) => {
          console.log(res.data);
          context.commit("setPumps", res.data.pumps);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    addPumps(context, data) {
      return axios
        .post(
          apiURLPump,
          {
            name: data.name,
            station: data.station,
            pond: data.pond,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res);
          return res;
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    },

    editPumps(context, data) {
      return axios
        .put(
          apiURLPump,
          {
            name: data.name,
            station: data.station,
            pond: data.pond,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res);
          return res;
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    },

    deletePump(context, id) {
      return axios
        .delete(apiURLPump + id, { headers: authHeader() })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    setOn({ commit }, data) {
      console.log(data);
      return axios
        .put(
          apiURLPump + "setOn/" + data.id,
          { On: data.On, macAddress: data.macAddress },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res);
          commit("setOn", res.data);
          return res;
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    },

    setMode({ commit }, data) {
      return axios
        .put(
          apiURLPump + "setMode/" + data.id,
          {
            Mode: data.Mode,
            macAddress: data.macAddress,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res);
          commit("setMode", res.data);
          return res;
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    },

    setReset(context, data) {
      console.log(data);
      return axios
        .put(
          apiURLPump + "setReset/" + data.id,
          {
            reset: data.reset,
            macAddress: data.macAddress,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res);
          return res;
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    },

    setStopBuzzer(context, data) {
      return axios
        .put(
          apiURLPump + "setStopBuzzer/" + data.id,
          {
            stopBuzzer: data.stopBuzzer,
            macAddress: data.macAddress,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res);
          return res;
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    },

    setResetTime(context, data) {
      console.log(data);
      return axios
        .put(
          apiURLPump + "setResetTime/" + data.id,
          { macAddress: data.macAddress },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res);
          return res;
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    },

    setLow({ commit }, data) {
      console.log(data);
      return axios
        .put(
          apiURLPump + "setLow/" + data.id,
          {
            low: data.low,
            lowlow: data.lowlow,
            macAddress: data.macAddress,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res);
          commit("setMode", res.data);
          return res;
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    },
  },
};
