import axios from "axios";
import authHeader from "@/service/auth-header";

const apiURL = process.env.VUE_APP_API_URL + "stations/";

export const stations = {
  state: {
    station: [],
    stations: [],
    stationsAll: [],
    nameAll: [],
  },
  getters: {
    station: (state) => state.station,
    stations: (state) => state.stations,
    allStation: (state) => state.stationsAll,
    nameStation: (state) => state.nameAll,
  },

  mutations: {
    SOCKET_STATION(state, payload) {
      //console.log(payload);
      // console.log(state);

      const index = state.stations.findIndex(
        (s) => s.macAddress == payload.macAddress
      );

      if (index > -1) {
        switch (payload.type) {
          case "Flow":
            state.stations[index].totalFlow = payload.totalFlow;
            break;
          case "Status":
            state.stations[index].status = payload.status;
            if (state.station.macAddress == payload.macAddress) {
              state.station.status = payload.status;
            }
            break;
          case "Level":
            state.stations[index].level = payload.level;
            break;
        }
      }
    },

    getStations(state, stations) {
      state.stations = stations;
    },
    getNameStation(state, name) {
      state.nameAll = name;
    },
    setStations(state, stations) {
      state.stations = stations;
    },
    getOneStation(state, station) {
      state.station = station;
    },
  },
  actions: {
    async getNames(context) {
      console.log("123");
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          console.log(res.data.data);
          context.commit("getNameStation", res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getOneStation({ commit }, id) {
      return axios
        .get(apiURL + id, { headers: authHeader() })
        .then((res) => {
          console.log(res);
          commit("getOneStation", res.data.station);
          return res.data.station
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getStations({ commit }) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          console.log(res.data.data);
          commit("getStations", res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // async getAllStation({ commit }) {
    //   return axios
    //     .get(apiURL, { headers: authHeader() })
    //     .then((res) => {
    //       console.log(res.data);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    // addStation({ commit }, data) {
    //   console.log(data);
    //   return axios
    //     .post(apiURL, { headers: authHeader() })
    //     .then((res) => {
    //       console.log(res);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    // deletStation(context, id) {
    //   return axios
    //     .delete(apiURL + id, { headers: authHeader() })
    //     .then((res) => {
    //       console.log(res);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    // editStation({ commit }, data) {
    //   console.log(data);

    //   return axios
    //     .put(
    //       apiURL + data.id,
    //       { stationName: data.stationName },
    //       { headers: authHeader() }
    //     )
    //     .then((res) => {
    //       console.log(res);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    setTime(context, data) {
      console.log(data);
      return axios
        .put(
          apiURL + "setTime/" + data.id,
          { timeLog: data.timeLog },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res);
          // context.commit("setChart", res.data.data);
          return res;
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    },
  },
};
