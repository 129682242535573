<template>
  <div v-resize="onResize">
    <div v-if="getPumps.length > 1">
      <v-tabs grow v-model="menu" align-with-title color="#2b9474">
        <v-tabs-slider color="#2b9474"></v-tabs-slider>
        <v-tab v-for="(item, i) in getPumps" :key="i" class="ml-0">
          {{ item.pond.name }}
        </v-tab>
      </v-tabs>
    </div>

    <v-window v-model="menu">
      <v-window-item v-for="(item, i) in getPumps" :key="i">
        <v-tabs
          grow
          show-arrows
          v-model="item.tab"
          align-with-title
          color="#2b9474"
        >
          <v-tabs-slider color="#2b9474"></v-tabs-slider>
          <v-tab class="mx-0" v-for="(child, index) in item.pumps" :key="index">
            <div>
              {{ child.name }}
            </div>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="item.tab" class="mt-0 pt-0">
          <div>
            <v-tab-item v-for="(child, c) in item.pumps" :key="c" class="ma-2">
              <!-- {{ child.name }} -->
              <v-row align="center" justify="space-around" v-if="!isMobile" class="mt-2">
                <v-col cols="auto">
                  <v-card
                    :min-width="isTablet == true ? '250' : '150'"
                    id="card_level"
                  >
                    <v-card-text>
                      <span
                        class="d-flex justify-center"
                        style="font-size: 12px"
                      >
                        ระดับน้ำ
                      </span>

                      <span
                        class="d-flex justify-center mt-2 mb-2"
                        style="
                          font-size: 40px;
                          font-weight: bold;
                        "
                      >
                        {{ item.pond.level.value }}
                      </span>
                      <span
                        class="d-flex justify-center"
                        style="font-size: 12px"
                      >
                        เมตร
                      </span>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="auto">
                  <v-card
                    :min-width="isTablet == true ? '250' : '150'"
                    id="card_level"
                  >
                    <v-card-text>
                      <span
                        class="d-flex justify-center"
                        style="font-size: 12px"
                      >
                        ปริมาณการไหล
                      </span>
                      <span
                        class="d-flex justify-center mt-2 mb-2"
                        style="
                          font-size: 40px;
                          font-weight: bold;
                        "
                      >
                        {{ child.flow }}
                      </span>
                      <span
                        class="d-flex justify-center"
                        style="font-size: 12px"
                      >
                        ลูกบาศก์เมตร
                      </span>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="auto">
                  <v-card
                    :min-width="isTablet == true ? '250' : '150'"
                    id="card_level"
                  >
                    <v-card-text>
                      <span
                        class="d-flex justify-center mb-2"
                        style="font-size: 12px"
                      >
                        ระยะเวลา
                      </span>
                      <v-row justify="space-around">
                        <v-col>
                          <span
                            class="d-flex justify-center mb-2"
                            style="font-size: 40px; font-weight: bold"
                          >
                            {{ child.time.hour }}
                          </span>

                          <span
                            class="d-flex justify-center"
                            style="font-size: 12px"
                          >
                            ชั่วโมง
                          </span>
                        </v-col>
                        <v-col>
                          <span
                            class="d-flex justify-center mb-2"
                            style="font-size: 40px; font-weight: bold"
                          >
                            {{ child.time.min }}
                          </span>

                          <span
                            class="d-flex justify-center"
                            style="font-size: 12px"
                          >
                            นาที
                          </span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row align="center" justify="space-around" v-else>
                <v-col cols="auto">
                  <v-card elevation="4" min-width="150" id="card_level">
                    <v-card-text>
                      <span
                        class="d-flex justify-center"
                        style="font-size: 12px"
                      >
                        ระดับน้ำ
                      </span>

                      <span
                        class="d-flex justify-center mt-2 mb-2"
                        style="font-size: 40px; font-weight: bold"
                      >
                        {{ item.pond.level.value }}
                      </span>
                      <span
                        class="d-flex justify-center"
                        style="font-size: 12px"
                      >
                        เมตร
                      </span>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="auto">
                  <v-card elevation="4" min-width="150" id="card_level">
                    <v-card-text>
                      <span
                        class="d-flex justify-center"
                        style="font-size: 12px"
                      >
                        ปริมาณการไหล
                      </span>
                      <span
                        class="d-flex justify-center mt-2 mb-2"
                        style="font-size: 40px; font-weight: bold"
                      >
                        {{ child.flow }}
                      </span>
                      <span
                        class="d-flex justify-center"
                        style="font-size: 12px"
                      >
                        ลูกบาศก์เมตร
                      </span>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="auto">
                  <v-card
                    elevation="4"
                    min-width="150"
                    max-width=""
                    id="card_level"
                  >
                    <v-card-text>
                      <span
                        class="d-flex justify-center mb-2"
                        style="font-size: 12px"
                      >
                        ระยะเวลา
                      </span>
                      <v-row justify="space-around">
                        <v-col>
                          <span
                            class="d-flex justify-center mb-2"
                            style="font-size: 40px; font-weight: bold"
                          >
                            {{ child.time.hour }}
                          </span>

                          <span
                            class="d-flex justify-center"
                            style="font-size: 12px"
                          >
                            ชั่วโมง
                          </span>
                        </v-col>
                        <v-col>
                          <span
                            class="d-flex justify-center mb-2"
                            style="font-size: 40px; font-weight: bold"
                          >
                            {{ child.time.min }}
                          </span>

                          <span
                            class="d-flex justify-center"
                            style="font-size: 12px"
                          >
                            นาที
                          </span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
          </div>
        </v-tabs-items>
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  name: "WatercontrolAppSumary",

  data() {
    return {
      data: {
        id: "",
        macAddress: "",
      },
      isMobile: false,
      isTablet: false,
      tab: null,
      menu: null,
    };
  },

  computed: {
    getPumps() {
      return this.$store.getters.allPumps;
    },
  },

  async created() {
    // console.log(this.$route.params.data);
    this.data.id = Cookies.get("stationID");
    this.data.macAddress = Cookies.get("macAddress");
    // console.log(this.data);
  },

  mounted() {
    this.$store.dispatch("getPumps", this.data);
  },

  methods: {
    onResize() {
      if (window.innerWidth <= 600) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
      if (window.innerWidth > 600 && window.innerWidth < 1500) {
        this.isTablet = true;
      } else {
        this.isTablet = false;
      }
    },
  },
};
</script>

<style scoped>
#card_level {
  border-radius: 20px;
  box-shadow: inset;
}
#card_flow {
  border-radius: 10px;
}

.card_title {
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 1s ease-in forwards 0.3s;
}

.card_content_level {
  opacity: 0;
  margin-top: 0%;
  transform: translateY(20px);
  animation: slideUp 1s ease-in forwards 0.6s;
}

.card_content_pumps {
  margin-top: 0;
}
.name {
  font-size: 15px;
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 1s ease-in-out forwards 0.7s;
}
ul {
  opacity: 0;
  animation: slideUp 1s ease-in forwards 0.8s;
  font-size: 15px;
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
</style>
