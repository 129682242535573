import axios from "axios";
import authHeader from "../service/auth-header";

const apiURL = process.env.VUE_APP_API_URL + "reports/";

export const reports = {
  state: {
    reports: [],
    report: [],
  },
  getters: {
    reportsStation: (state) => state.reports,
    reportStation: (state) => state.report,
  },
  mutations: {
    getReport(state, report) {
      state.report = report;
    },
    getReports(state, reports) {
      state.reports = reports;
    },
    getReportBySearch(state, reportBySearch) {
      state.reports = reportBySearch;
    },
  },
  actions: {
    setReports({ commit }, id) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          console.log(res.data.report);
          commit("getReports", res.data.report);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getReportByID({ commit }, id) {
      // console.log("reID")
      // console.log(id)
      return axios
        .get(apiURL + id, { headers: authHeader() })
        .then((res) => {
          // console.log(res.data.report);
          commit("getReport", res.data.report);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getReportBySearch({ commit }, data) {
      //
      return axios
        .post(
          apiURL + "search",
          {
            station: data.id,
            eventStart: data.eventStart,
            eventEnd: data.eventEnd,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          commit("getReportBySearch", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
