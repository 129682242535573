import axios from "axios";
import authHeader from "@/service/auth-header";

const apiURL = process.env.VUE_APP_API_URL + "records/";

export const records = {
  state: {
    records: [],
    time: [],
  },
  getters: {
    records: (state) => state.records,
    time: (state) => state.records,
  },
  mutations: {
    setRecords(state, records) {
      state.records = records;
      state.records.options.tooltip.y = {
        formatter: function (val) {
          return val + " ลบ.ม.";
        },
      };
    },
 
  },
  actions: {
    getFlow({ commit }, data) {
      console.log(data);
      return axios
        .put(
          apiURL + "flow/",
          { macAddress: data.macAddress, start: data.start, end: data.end },
          { Headers: authHeader() }
        )
        .then((res) => {
          console.log(res);
          commit("setRecords", res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  
  },
};
