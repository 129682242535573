import axios from "axios";
import authHeader from "@/service/auth-header";

const apiURL = process.env.VUE_APP_API_URL + "users/";

export const account = {
  state: {
    users: [],
  },
  getters: {
    allUsers: (state) => state.users,
  },
  mutations: {
    setUser(state, user) {
      state.users = user;
    },
  },
  actions: {
    async getUsers(context) {
      return axios
        .get(apiURL, { headers: authHeader() })
        .then((res) => {
          console.log(res.data.data);
          context.commit("setUser", res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    createUsers(context, data) {
      console.log(data);
      return axios
        .post(
          apiURL,
          {
            name: data.name,
            username: data.username,
            password: data.password,
            role: data.role,
            station: data.station,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res);
          return res;
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    },
    editUsers(context, data) {
      // console.log(data);
      return axios
        .put(
          apiURL + data.id,
          {
            name: data.name,
            username: data.username,
            password: data.password,
            role: data.role,
            station: data.station,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res);
          return res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetPassword(context, data) {
      // console.log(data);
      return axios
        .put(
          apiURL + "resetPassword/" + data.id,
          {
            id: data,
            newPassword: data.newPassword,
          },
          { headers: authHeader() }
        )
        .then(() => {
          console.log("Reset your password is Successfully");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteUser(context, id) {
      console.log(id);
      return axios
        .delete(apiURL + id, { headers: authHeader() })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
