<template>
  <v-app
    id="login"
    class="secondary"
    style="background: linear-gradient(to top, #f5f5f5 50%, #1bb394 50%)"
  >
    <v-main>
      <v-container fuild fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 lg4>
            <v-card class="pa-2" elevation="2">
              <span class="d-flex flex-no-wrap justify-center pt-2 pb-2">
                <v-img :src="require('../assets/lo-go.png')" max-width="160" />
              </span>

              <v-card-text>
                <div class="layout column align-center">
                  <h3 class="d-flex flex-no-wrap justify-center pt-2 pb-4">
                    สำนักงานจัดการคุณภาพน้ำ กรุงเทพมหานคร
                  </h3>
                </div>
                <v-form @keyup.native.enter="login()">
                  <v-text-field
                    v-model="user.username"
                    color="#1bb394"
                    name="username"
                    label="Username"
                    append-icon="mdi-account"
                    type="text"
                    @keypress="noCharactor($event)"
                  />
                  <v-text-field
                    v-model="user.password"
                    color="#1bb394"
                    name="password"
                    label="Password"
                    :append-icon="
                      hidePassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
                    "
                    :type="hidePassword ? 'password' : 'text'"
                    @click:append="hidePassword = !hidePassword"
                    @keypress="noCharactor($event)"
                  />
                </v-form>
              </v-card-text>

              <v-card-actions class="ma-2">
                <v-btn class="white--text" block color="#1bb394" @click="login">
                  Login
                </v-btn>
              </v-card-actions>
              <v-card-actions class="ma-2">
                <v-btn text block to="/">
                  <h5>Back to Home page</h5>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>

        <v-snackbar v-model="snackbar" top right :color="color">
          <v-row>
            <v-col cols="10"> {{ text }}</v-col>
            <v-col cols="2">
              <v-btn x-small color="white" icon @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "WatercontrolAppLogin",

  data() {
    return {
      color: "",
      text: "",
      snackbar: false,
      isMobile: false,
    };
  },

  computed: {
    loggedIn() {
      console.log(this.$store.state.auth.status.loggedIn);
      return this.$store.state.auth.status.loggedIn;
    },
    getAccess() {
      console.log(this.$store.state.auth.access);
      return this.$store.state.auth.access;
    },
  },

  mounted() {},

  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },

  methods: {
    isResize() {
      if (window.innerWidth <= 600) this.isMobile = true;
      else this.isMobile = false;
    },
    login() {
      if (!this.user.username || !this.user.password) {
        console.log("ddd");
        this.snackbar = true;
        this.color = "error";
        this.text = "Incorect username or password !!";
        this.return;
      } else {
        this.$store.dispatch("auth/login", this.user).then(() => {
          this.$store.dispatch("auth/decode").then(() => {
            if (this.getAccess.firstLogin == true) {
              this.$router.push("/resetPassword");
            } else {
              this.$router.push("/");
            }
          }),
            (err) => {
              this.err = true;
              this.text = "Incorect username or password";
              this.snackbar = true;
            };
        });
      }
    },

    noCharactor(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9]+$/.test(char)) {
        return true;
      } else {
        e.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
