<template>
  <v-app
    id="login"
    class="secondary"
    style="background: linear-gradient(to top, #f5f5f5 50%, #1bb394 50%)"
  >
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md6 lg4>
        <v-card elevation="2" max-width="500">
          <span class="d-flex flex-no-wrap justify-center pt-2 pb-2">
            <v-img :src="require('../assets/lo-go.png')" max-width="160" />
          </span>

          <v-card-text>
            <div class="layout column align-center">
              <h3 class="d-flexustify-center pt-2 pb-4">รีเซ็ตรหัสผ่าน</h3>
            </div>
            <v-form @keyup.native.enter="save">
              <v-text-field
                color="#1bb394"
                name="newPassword"
                label="New Password"
                v-model="password.new"
                :rules="[rules.required]"
                :append-icon="
                  hideNewPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
                "
                :type="hideNewPassword ? 'password' : 'text'"
                @click:append="hideNewPassword = !hideNewPassword"
                @keypress="noCharactor($event)"
              />
              <v-text-field
                color="#1bb394"
                name="confirmPassword"
                label="Confirm Password"
                v-model="password.confirm"
                :rules="[rules.required]"
                :append-icon="
                  hideConfirmPassword
                    ? 'mdi-eye-off-outline'
                    : 'mdi-eye-outline'
                "
                :type="hideConfirmPassword ? 'password' : 'text'"
                @click:append="hideConfirmPassword = !hideConfirmPassword"
                @keypress="noCharactor($event)"
              />
            </v-form>
          </v-card-text>

          <v-card-actions class="ma-2">
            <v-btn
              class="white--text"
              block
              color="#1bb394"
              @click="savePassword"
            >
              save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      showResult: false,
      result: "",
      isMobile: false,
      hideNewPassword: true,
      hideConfirmPassword: true,
      rules: {
        required: (value) => !!value || "Require new password",
      },
      password: {
        new: null,
        confirm: null,
      },
      payload: {
        id: "",
        newPassword: "",
      },
    };
  },

  mounted() {
    this.$store.dispatch("auth/decode");
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getAccess() {
      return this.$store.state.auth.access;
    },
  },

  created() {
    if (this.getAccess == null) this.$router.push("/login");
  },

  methods: {
    isResize() {
      if (window.innerWidth <= 600) this.isMobile = true;
      else this.isMobile = false;
    },
    savePassword() {
      if (this.password.new == this.password.confirm) {
        this.payload.id = this.getAccess.id;
        this.payload.newPassword = this.password.new;
        this.$store.dispatch("auth/resetPassword", this.payload).then(
          () => {
            this.$router.push("/");
          },
          (err) => {
            this.message = err.res && err.res;
            console.log(message);
          }
        );
      }
    },
    noCharactor(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9]+$/.test(char)) {
        return true;
      } else {
        e.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
