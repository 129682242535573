import authHeader from "@/service/auth-header";
import axios from "axios";

const apiURL = process.env.VUE_APP_API_URL + "charts/";

export const charts = {
  state: {
    charts: [],
  },
  getters: {
    charts: (state) => state.charts,
  },
  mutations: {
    setChart(state, charts) {
      state.charts = charts;
      state.charts.options.tooltip.y = {
        formatter: function (val) {
          return val + " เมตร";
        },
      };
      state.charts.options.chart.toolbar.export.csv = {
        filename: state.charts.options.chart.toolbar.export.csv.filename,
        columnDelimiter: ",",
        headerCategory: "Date Time",
        headerValue: "value",
        dateFormatter(timestamp) {
          return new Date(timestamp).toLocaleString("th-TH", {
            timeZone: "Asia/Bangkok",
          });
        },
      };
    },
  },
  actions: {
    getCharts({ commit }, data) {
      console.log(data);
      return axios
        .put(
          apiURL,
          {
            station: data.station,
            type: data.type,
            start: data.start,
            end: data.end,
          },
          { headers: authHeader() }
        )
        .then((res) => {
          console.log(res);
          commit("setChart", res.data.data);
          return res;
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    },
  },
};
