<template>
  <div v-resize="onResize" style="z-index: 10">
    <nav>
      <div v-if="!isMobile">
        <v-app-bar dark app overflow-y elevation="1" color="white">
          <v-toolbar flat color="white">
            <!-- <v-app-bar-nav-icon color="black"   @click.stop="miniSidebar = !miniSidebar" /> -->
            <v-toolbar-title class="text-uppercase grey--text">
              <a @click.stop="miniSidebar = !miniSidebar">
                <v-icon color="black">mdi-menu</v-icon>
              </a>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-list light class="pa-0">
              <v-list-item class="ma-0">
                <v-list-item-avatar color="#1bb394" v-if="getAccess != null">
                  <v-icon color="white">mdi-account</v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="grey--text">
                  <v-row>
                    <v-col cols="12" v-if="getAccess != null">
                      <div style="font-size: 16px">{{ getAccess.name }}</div>
                      <div style="font-size: 12px">
                        @ {{ getAccess.username }}
                      </div>
                    </v-col>
                    <v-col v-else>
                      <v-btn
                        small
                        block
                        outlined
                        color="#1bb394"
                        @click="loginDialog = true"
                      >
                        <span>login</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-list-item-content>

                <v-menu
                  left
                  offset-y
                  transition="slide-x-reverse-transition"
                  v-if="getAccess != null"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small icon color="grey" v-bind="attrs" v-on="on">
                      <v-icon>mdi-menu-down</v-icon>
                    </v-btn>
                  </template>

                  <!-- Login -->
                  <v-list>
                    <v-list-item @click="changePassword = true">
                      <v-list-item-action>
                        <v-icon>mdi-lock-outline</v-icon>
                      </v-list-item-action>
                      <v-list-item-title>เปลี่ยนรหัสผ่าน</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="thisLogout()">
                      <v-list-item-action>
                        <v-icon>mdi-logout</v-icon>
                      </v-list-item-action>
                      <v-list-item-title> ออกจากระบบ </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item>
            </v-list>
          </v-toolbar>
          <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            bottom
            color="#1bb394"
          ></v-progress-linear>
        </v-app-bar>

        <v-navigation-drawer
          v-model="drawer"
          :mini-variant="miniSidebar"
          permanent
          dark
          app
          color="#2b9474"
          :src="require('../assets/side.png')"
          ><!-- :src="require('../assets/side.png')" -->
          <v-list nav dense>
            <v-list-item class="d-flex flex-no-wrap justify-center px-2">
              <v-img
                v-if="miniSidebar"
                :src="require('../assets/lo-go.png')"
                max-width="30"
                class="ma-2"
              />

              <v-img
                v-else
                :src="require('../assets/lo-go.png')"
                max-width="100"
                class="ma-2"
              />
            </v-list-item>
            <br v-if="!miniSidebar" />
            <v-list-item v-if="!miniSidebar">
              <v-list-item-title class="pt-1">
                <div style="text-align: center">
                  <h3>ระบบควบคุมบ่อสูบน้ำระยะไกล</h3>
                  <br />
                  <h4>สำนักการระบายน้ำ</h4>
                  <h5>กรุงเทพมหานคร</h5>
                  <small>Department of Drainage and Sewerage</small>
                </div>
              </v-list-item-title>
            </v-list-item>
            <br v-if="!miniSidebar" />
            <v-list-item
              v-for="item in items"
              :key="item.title"
              :to="item.route"
              :param="item.param"
              link
              router
              @click="checkMenu()"
            >
              <!-- {{ item }} -->
              <v-list-item-icon>
                <v-icon color="#f5f5f5" fix>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="color: #f5f5f5">
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <P v-if="getAccess != undefined">
              <v-list-group
                color="#fff"
                v-if="
                  (getAccess.role == 'Superadmin' && homePath) ||
                  (getAccess.role == 'Admin' && homePath)
                "
              >
                <template v-slot:activator>
                  <v-list-item-icon>
                    <v-icon>mdi-cog</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="pt-1">ตั้งค่า</v-list-item-title>
                </template>
                <v-list-item
                  v-for="child in settingMenu"
                  :key="child.title"
                  :to="child.route"
                  :param="child.param"
                  link
                  router
                >
                  <v-list-item-icon>
                    <v-icon>{{ child.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ child.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-group>
            </P>

            <v-list-item></v-list-item>
          </v-list>
        </v-navigation-drawer>
      </div>

      <div v-else>
        <v-app-bar
          class="line"
          color="white"
          elevation="1"
          height="70em"
          app
          flat
          dark
          overflow-y
        >
          <v-row justify="space-between">
            <!-- <v-app-bar-nav-icon color="black"   @click.stop="miniSidebar = !miniSidebar" /> -->
            <v-toolbar-title class="grey--text">
              <v-btn icon @click.stop="drawer = !drawer">
                <v-icon color="black">mdi-menu</v-icon>
              </v-btn>
            </v-toolbar-title>
            <v-img
              :src="require('../assets/lo-go.png')"
              max-width="30"
              :class="getAccess != null ? '' : 'ml-12'"
            />
            <v-list color="white" class="pa-0">
              <v-list-item class="ma-0">
                <v-list-item-content class="grey--text">
                  <v-row>
                    <v-col cols="12" v-if="getAccess != null">
                      <v-menu
                        left
                        offset-y
                        transition="slide-y-transition"
                        v-if="getAccess != null"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            small
                            icon
                            color="grey"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <!-- <v-icon>mdi-menu-down</v-icon> -->
                            <v-icon>mdi-account</v-icon>
                          </v-btn>
                        </template>

                        <!-- Login -->
                        <v-list>
                          <v-list-item>
                            <v-list-item-avatar
                              color="#1bb394"
                              v-if="getAccess != null"
                            >
                              <v-icon color="white">mdi-account</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title style="font-size: 16px">
                              {{ getAccess.name }}
                              <v-list-item-subtitle style="font-size: 12px">
                                @ {{ getAccess.username }}
                              </v-list-item-subtitle>
                            </v-list-item-title>
                          </v-list-item>

                          <v-divider></v-divider>
                          <v-list-item @click="changePassword = true">
                            <v-list-item-action>
                              <v-icon>mdi-lock-outline</v-icon>
                            </v-list-item-action>
                            <v-list-item-title
                              >เปลี่ยนรหัสผ่าน</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item @click="thisLogout()">
                            <v-list-item-action>
                              <v-icon>mdi-logout</v-icon>
                            </v-list-item-action>
                            <v-list-item-title> ออกจากระบบ </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                    <v-col v-else>
                      <v-btn
                        small
                        block
                        outlined
                        color="#1bb394"
                        @click="loginDialog = true"
                      >
                        <span>login</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-row>
        </v-app-bar>

        <v-navigation-drawer
          v-model="drawer"
          dark
          app
          color="#2b9474"
          :src="require('../assets/side.png')"
          ><!-- :src="require('../assets/side.png')" -->
          <v-list-item class="d-flex flex-no-wrap justify-center px-2">
            <v-img
              :src="require('../assets/lo-go.png')"
              max-width="100"
              class="ma-2"
            />
          </v-list-item>
          <br />
          <v-list-item>
            <v-list-item-title class="pt-1">
              <div style="text-align: center">
                <h4>ระบบควบคุมบ่อสูบน้ำระยะไกล</h4>
                <br />
                <h5>สำนักการระบายน้ำ</h5>
                <h6>กรุงเทพมหานคร</h6>
                <small>Department of Drainage and Sewerage</small>
              </div>
            </v-list-item-title>
          </v-list-item>
          <br />

          <v-list nav dense>
            <v-list-item
              v-for="item in items"
              :key="item.title"
              :to="item.route"
              :param="item.param"
              link
              router
              @click="checkMenu()"
            >
              <!-- {{ item }} -->
              <v-list-item-icon>
                <v-icon color="#f5f5f5" fix>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="color: #f5f5f5">
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <div v-if="getAccess != undefined">
              <v-list-group
                no-action
                color="#fff"
                v-if="
                  (getAccess.role == 'Superadmin' && homePath) ||
                  (getAccess.role == 'Admin' && homePath)
                "
              >
                <template v-slot:activator>
                  <v-list-item-icon>
                    <v-icon>mdi-cog</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="pt-1">ตั้งค่า</v-list-item-title>
                </template>

                <div>
                  <v-list-item
                    v-for="child in settingMenu"
                    :key="child.title"
                    :to="child.route"
                    :param="child.param"
                    link
                    router
                  >
                    <v-list-item-title class="pl-14">
                      {{ child.title }}
                    </v-list-item-title>
                    <v-list-item-icon>
                      <v-icon>{{ child.icon }}</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </div>
              </v-list-group>
            </div>
          </v-list>
        </v-navigation-drawer>
      </div>

      <v-dialog persistent v-model="loginDialog" height="540" max-width="400">
        <v-card v-if="!loading" height="540">
          <div class="d-flex justify-center">
            <v-img
              :src="require('../assets/lo-go.png')"
              max-width="80"
              class="ma-4"
            />
          </div>

          <v-card-text>
            <div style="text-align: center; justify: center">
              <h3>ระบบควบคุมบ่อสูบน้ำระยะไกล</h3>
              <h4>สำนักการระบายน้ำ</h4>
              <h5>กรุงเทพมหานคร</h5>
              <small>Department of Drainage and Sewerage</small>
            </div>
            <br />
            <v-form @keyup.native.enter="login()">
              <v-text-field
                v-model="user.username"
                color="#1bb394"
                name="username"
                label="Username"
                append-icon="mdi-account"
                type="text"
                @keypress="noCharactor($event)"
              />
              <v-text-field
                v-model="user.password"
                color="#1bb394"
                name="password"
                label="Password"
                :append-icon="
                  hidePassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
                "
                :type="hidePassword ? 'password' : 'text'"
                @click:append="hidePassword = !hidePassword"
                @keypress="noCharactor($event)"
              />
            </v-form>
          </v-card-text>
          <v-card-actions class="ma-1">
            <v-btn class="white--text" block color="#1bb394" @click="login()">
              Login
            </v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn small text block @click="loginDialog = false">
              <h5>cancel</h5>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="changePassword" max-width="400">
        <v-card>
          <v-card-title>
            <span>เปลี่ยนรหัสผ่าน</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="changePassword = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form class="pa-2">
              <v-text-field
                color="#1bb394"
                name="oldPassword"
                label="รหัสผ่านเก่า"
                v-model="password.old"
                :type="password.hideOld ? 'password' : 'text'"
                :append-icon="
                  password.hideOld ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
                "
                @click:append="password.hideOld = !password.hideOld"
              ></v-text-field>
              <v-text-field
                color="#1bb394"
                name="newPassword"
                label="รหัสผ่านใหม่"
                v-model="password.new"
                :type="password.hideNew ? 'password' : 'text'"
                :append-icon="
                  password.hideNew ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
                "
                @click:append="password.hideNew = !password.hideNew"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn text block @click="chagePassword()" color="success">
              ยืนยัน
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar v-model="snackbar" top right :color="color">
        <v-row>
          <v-col cols="10"> {{ text }}</v-col>
          <v-col cols="2">
            <v-btn x-small color="white" icon @click="snackbar = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-snackbar>
    </nav>

    <!-- <notifications group="alarm">
      <template slot="body" slot-scope="props">
        <v-card
          style="border-left: 5px solid green"
          max-width="400"
          height="150"
        >
          <v-card-text>
            <v-row>
              <v-col cols="10">
                <p class="text-h7">
                  <b>
                    {{ props.item.title }}
                  </b>
                </p>
                <p>
                  <v-row>
                    <v-col justify="center" class="mt-2">
                      {{ props.item.text }}
                    </v-col>
                    <v-col cols="1" class="mt-2" justify="center" align="end">
                      <v-icon x-large color="success">
                        mdi-checkbox-marked-circle-outline
                      </v-icon>
                    </v-col>
                  </v-row>
                </p>
              </v-col>
              <v-col cols="2" class="ma-0">
                <a class="close" @click="props.close">
                  <v-icon>mdi-close</v-icon>
                </a>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </notifications> -->
  </div>
</template>

<script>
export default {
  name: "Navbar",

  data() {
    return {
      color: "",
      text: "",
      snackbar: false,
      // alarm
      payload: "",

      // login
      hidePassword: true,
      rules: {
        required: (value) => !!value || "Required",
      },
      user: [
        {
          username: null,
          password: null,
        },
      ],
      loginDialog: false,

      //Navbar
      loading: false,
      path: null,
      homePath: true,
      items: null,
      isMobile: false,
      data: [],
      changePassword: null,
      drawer: null,
      miniSidebar: false,
      password: {
        old: "",
        hideOld: true,
        new: "",
        hideNew: true,
      },

      pass: {
        id: "",
        newPassword: "",
        password: "",
      },
      homeMenu: [
        {
          title: "หน้าหลัก",
          icon: "mdi-view-dashboard",
          route: "/",
        },
      ],
      viewerMenu: [
        {
          title: "หน้าหลัก",
          icon: "mdi-view-dashboard",
          route: "/",
        },
      ],

      settingMenu: [
        {
          title: "บัญชีผู้ใช้งาน",
          icon: "mdi-account",
          route: "/accounts",
        },
      ],
      dashboardMenu: [
        {
          title: "หน้าหลัก",
          icon: "mdi-home",
          route: "/",
        },
        {
          title: "เหตุการณ์",
          icon: "mdi-alarm-light",
          route: "/events",
        },
        {
          title: "ควบคุมระบบสูบน้ำ",
          icon: "mdi-tune-variant",
          route: "/ControlSystem",
        },

        {
          title: "รายงาน",
          icon: "mdi-chart-line",
          route: "/reports",
          param: "item.data",
        },
      ],
      dashboardMenu2: [
        {
          title: "หน้าหลัก",
          icon: "mdi-home",
          route: "/",
        },
        {
          title: "การทำงานสถานีสูบน้ำ",
          icon: "mdi-tune-variant",
          route: "/ControlSystem",
        },
        {
          title: "รายงาน",
          icon: "mdi-chart-line",
          route: "/reports",
          param: "item.data",
        },
      ],
    };
  },

  computed: {
    getAccess() {
      // console.log(this.$store.state.auth.access);
      return this.$store.state.auth.access;
    },
    loggedIn() {
      // console.log(this.$store.state.auth.status.loggedIn);
      return this.$store.state.auth.status.loggedIn;
    },
    getStations() {
      return this.$store.getters.stations;
    },
  },

  created() {
    if (this.loggedIn == true && this.getAccess == null) {
      this.$store.dispatch("auth/decode");
      this.checkMenu();
    } else {
      this.checkMenu();
    }
  },

  watch: {
    $route(to, from) {
      // console.log(to.name);
      if (this.getAccess == null) {
        if (
          to.name.search("ControlSystem") === 0 ||
          to.name.search("Reports") === 0
        ) {
          this.items = this.dashboardMenu2;
          this.homePath = false;
        }
      } else {
        if (
          to.name.search("Events") === 0 ||
          to.name.search("ControlSystem") === 0 ||
          to.name.search("Reports") === 0
        ) {
          this.items = this.dashboardMenu;
          this.homePath = false;
        } else {
          this.items = this.homeMenu;
          this.homePath = true;
        }
      }
      if (
        to.name.search("Dashboard") === 0 ||
        to.name.search("Accounts") === 0
      ) {
        this.items = this.homeMenu;
        this.homePath = true;
      }
    },
    loading(val) {
      if (!val) return;

      setTimeout(() => (this.loading = false), 500);
    },
  },

  mounted() {
    this.sockets.subscribe("ALARM", (payload) => {
      const msg = payload;
      console.log(msg);

      this.notification(msg);
    });
    this.sockets.subscribe("noti", (payload) => {
      console.log(payload);
      if (
        this.getAccess.role == "Superadmin" ||
        this.getAccess.station == payload.station
      ) {
        this.snackbar = true;
        this.text = payload.text;
        this.color = "error";
      }
    });

    // this.sockets.unsubscribe("ALARM");
  },

  methods: {
    noCharactor(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9]+$/.test(char)) {
        return true;
      } else {
        e.preventDefault();
      }
    },

    notification(data) {
      console.log(data);
      this.$notify({
        group: "alarm",
        title: data.pump.macAddress,
        text: data.pump.description,
        duration: -3,
        type: "warn",
        data: data,
        speed: 3000,
      });
    },

    checkMenu() {
      this.path = this.$router.currentRoute.name;
      // console.log(this.path);
      if (this.getAccess == null) {
        if (
          this.path.search("ControlSystem") === 0 ||
          this.path.search("Reports") === 0
        ) {
          this.items = this.dashboardMenu2;
          this.homePath = true;
        }
      } else {
        if (
          this.path.search("Events") === 0 ||
          this.path.search("ControlSystem") === 0 ||
          this.path.search("Reports") === 0
        ) {
          if (
            this.getAccess.role === "Superadmin" ||
            this.getAccess.role === "Admin" ||
            this.getAccess.role === "Operator"
          ) {
            this.items = this.dashboardMenu;
            this.homePath = false;
            console.log(this.items);
          } else {
            this.items = this.viewerMenu;
            this.homePath = false;
          }
        } else {
          this.items = this.homeMenu;
          this.homePath = true;
        }
      }

      if (
        this.path.search("Dashboard") === 0 ||
        this.path.search("Accounts") === 0
      ) {
        this.items = this.homeMenu;
        this.homePath = true;
      }
    },

    clearField() {
      this.user.username = "";
      this.user.password = "";
    },

    login() {
      if (!this.user.username || !this.user.password) {
        this.snackbar = true;
        this.color = "error";
        this.text = "ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง";
        this.return;
      } else {
        this.$store
          .dispatch("auth/login", this.user)
          .then((res) => {
            this.loginDialog = false;
            this.loading = true;
            this.checkMenu();
            this.$store.dispatch("getStations");
            this.$store.dispatch("auth/decode").then((res) => {
              if (this.getAccess.firstLogin == true) {
                this.$router.push("/resetPassword");
                this.loading = true;
                this.checkMenu();
              } else {
                if (this.path.search("Dashboard") !== 0) {
                  // console.log(route);
                  this.$router.push("/");
                }
              }
            });
          })
          .catch((err) => {
            console.log(err.response.data.error);
            this.color = "red";
            this.text = err.response.data.error.message;
            this.snackbar = true;
          });
      }
    },

    changePageSuperAdmin() {
      this.checkMenu();
    },

    onResize() {
      if (window.innerWidth < 800) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },

    openDialog() {
      this.changePassword = true;
    },

    thisLogout() {
      this.path = this.$router.currentRoute.name;
      try {
        this.$store.dispatch("auth/logout").then(() => {
          this.$store.dispatch("auth/decode");
          this.loading = true;
          this.clearField();
          this.$store.dispatch("getStations");
          if (this.path.search("Dashboard") !== 0) {
            // console.log("route");
            this.$router.push("/");
          }
        });
      } catch (error) {
        console.log(error);
      }
    },

    chagePassword() {
      if (this.password.old == "" || this.password.new == "") {
        this.text = "Error: กรุณาป้อนรหัสผ่านให้ครบถ้วน";
        this.color = "red accent-2";
        this.snackbar = true;
      } else {
        console.log(this.getAccess);
        this.pass.id = this.getAccess.id;
        this.pass.newPassword = this.password.new;
        this.pass.password = this.password.old;
        console.log(this.pass);
        this.$store.dispatch("auth/changePassword", this.pass).then(
          (res) => {
            console.log(res);
            this.changePassword = false;
            this.color = "success accent-2";
            this.text = res.message;
            this.snackbar = true;
          },
          (err) => {
            console.log(err);
            this.text = err.response.data.error.message;
            this.color = "red accent-2";
            this.snackbar = true;
          }
        );
      }
    },
  },
};
</script>

<style scoped>
.menu {
  top: 0;
  right: 0;
  margin-left: 10em;
}

.hamburger {
  margin: 0 auto;
  margin-top: 30px;
  width: 30px;
  height: 30px;
  position: relative;
}
.hamburger .bar {
  padding: 0;
  width: 20px;
  height: 4px;
  background-color: black;
  display: block;
  border-radius: 4px;
  transition: all 0.4s ease-in-out;
  position: absolute;
}
.bar1 {
  top: 0;
}

.bar2,
.bar3 {
  top: 13.5px;
}
</style>
