<template>
  <div v-resize="onResize" v-if="getPumps || getStation">
    <v-card flat class="mt-0 pt-0" tile>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <!-- <v-card v-if="getPumps.length > 1" class="ma-1">
            <v-tabs grow v-model="menu" align-with-title color="#2b9474">
              <v-tabs-slider color="#2b9474"></v-tabs-slider>
              <v-tab
                v-for="(item, index) in getPumps"
                :key="index"
                class="ml-0"
              >
                {{ item.pond.name }}
              </v-tab>
            </v-tabs>
          </v-card> -->

          <v-window v-model="menu" class="pa-1">
            <v-window-item v-for="(item, index) in getPumps" :key="index">
              <v-row>
                <!-- sumary -->

                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-card id="content_control" elevation="4">
                    <v-tabs
                      v-if="getPumps.length > 1"
                      grow
                      v-model="menu"
                      align-with-title
                      color="#2b9474"
                    >
                      <v-tabs-slider color="#2b9474"></v-tabs-slider>
                      <v-tab
                        v-for="(item, index) in getPumps"
                        :key="index"
                        class="ml-0"
                      >
                        {{ item.pond.name }}
                      </v-tab>
                    </v-tabs>
                    <v-tabs
                      grow
                      v-model="item.tabs"
                      color="#1bb394"
                      background-color="white"
                      show-arrows
                    >
                      <v-tabs-slider color="#1bb394"></v-tabs-slider>
                      <v-tab v-for="(child, i) in item.pumps" :key="i">
                        ปั๊มที่ {{ i + 1 }}
                      </v-tab>
                    </v-tabs>
                    <!-- control panel -->

                    <v-tabs-items v-model="item.tabs">
                      <v-tab-item
                        v-for="(child, index) in item.pumps"
                        :key="'pump' + index"
                      >
                        <!-- <v-row align="center"> -->
                        <!-- <v-card
                    class="ml-3 mt-2 mb-5 pb-0 pt-0"
                    :max-width="isMobile == false ? '239' : 'auto'"
                    elevation="4"
                   
                  > -->
                        <v-card-title>
                          <span>{{ child.name }}</span>
                          <v-spacer></v-spacer>
                          <v-menu
                            transition="slide-y-transition"
                            open-on-hover
                            offset-y
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <span>
                                <v-btn
                                  :class="blink(child.alarm)"
                                  icon
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <!-- Alarm -->
                                  <v-icon> mdi-alarm-light </v-icon>
                                </v-btn>
                              </span>
                            </template>

                            <v-list
                              v-for="(childing, index) in child.alarm"
                              :key="index"
                            >
                              <v-list-item>
                                <v-list-item-title>
                                  <span>
                                    <v-row>
                                      <v-col cols="8">
                                        {{ childing.name }} :</v-col
                                      >
                                      <v-col cols="2">
                                        <span
                                          :class="
                                            childing.status == true
                                              ? 'alarmOn'
                                              : 'alarmOff'
                                          "
                                        />
                                      </v-col>
                                    </v-row>
                                  </span>
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                          <v-menu
                            offset-x
                            right
                            transition="slide-y-transition"
                          >
                            <template v-slot:activator="{ attrs, on }">
                              <v-btn icon small v-bind="attrs" v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item @click="resetTimeOpen(child)">
                                <v-list-item-action>
                                  <v-icon>mdi-update</v-icon>
                                </v-list-item-action>
                                <v-list-item-title>
                                  Reset time
                                </v-list-item-title>
                              </v-list-item>

                              <!-- <v-list-item @click="resetOpen(child)">
                              <v-list-item-action>
                                <v-icon> mdi-bell-cog-outline </v-icon>
                              </v-list-item-action>
                              <v-list-item-title>
                                Reset Alarm
                              </v-list-item-title>
                            </v-list-item> -->
                            </v-list>
                          </v-menu>
                        </v-card-title>

                        <v-card-actions>
                          <v-row align="center" justify="center">
                            <v-col cols="12">
                              <div id="OnOff">
                                <v-row>
                                  <!-- On-Off -->
                                  <v-col cols="6" class="pt-2 pb-1">
                                    <v-btn
                                      style="color: white"
                                      block
                                      name="off"
                                      :color="
                                        child.runPump === false ? 'red' : 'grey'
                                      "
                                      @click="pumpOff(child)"
                                      v-if="child.Mode == 'Manual'"
                                      :disabled="disabledFn(child)"
                                    >
                                      OFF
                                    </v-btn>
                                    <v-btn
                                      v-else
                                      style="color: white"
                                      block
                                      name="off"
                                      :color="
                                        child.runPump === false ? 'red' : 'grey'
                                      "
                                      @click="pumpOff(child)"
                                      :disabled="(showBT = true)"
                                    >
                                      OFF
                                    </v-btn>
                                  </v-col>
                                  <v-col cols="6" class="pt-2 pb-1">
                                    <v-btn
                                      style="color: white"
                                      block
                                      name="on"
                                      :color="
                                        child.runPump === true
                                          ? 'green'
                                          : 'grey'
                                      "
                                      @click="pumpOn(child)"
                                      v-if="child.Mode == 'Manual'"
                                      :disabled="disabledFn(child)"
                                    >
                                      on
                                    </v-btn>
                                    <v-btn
                                      v-else
                                      style="color: white"
                                      block
                                      name="on"
                                      :color="
                                        child.runPump === true
                                          ? 'green'
                                          : 'grey'
                                      "
                                      @click="pumpOn(child)"
                                      :disabled="(showBT = true)"
                                    >
                                      on
                                    </v-btn>
                                  </v-col>

                                  <!-- Stop Buzzer-->
                                  <v-col cols="12" class="pt-1 pb-1">
                                    <v-btn
                                      block
                                      dark
                                      name="stop"
                                      :class="[isActive ? 'red' : 'grey']"
                                      class="mt-1"
                                      @click="StopBuzzer(child)"
                                    >
                                      stop buzzer
                                    </v-btn>
                                  </v-col>

                                  <!-- reset -->
                                  <v-col cols="12">
                                    <v-btn
                                      block
                                      dark
                                      :class="[isReset ? 'orange' : 'grey']"
                                      @click="resetOpen(child)"
                                    >
                                      <span>Reset Alarm</span>
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </div>

                              <br />

                              <v-divider></v-divider>

                              <div
                                id="mode"
                                :class="
                                  getAccess.role != 'Operator' ? '' : 'mt-5'
                                "
                              >
                                <h4>Mode</h4>
                                <v-row
                                  align="center"
                                  justify="center"
                                  :class="
                                    getAccess.role != 'Operator' ? '' : 'mt-5'
                                  "
                                >
                                  <v-col cols="12" lg="8" md="6" sm="6" xs="6">
                                    <v-btn
                                      small
                                      block
                                      dark
                                      :color="
                                        child.Mode === 'Auto' ? 'green' : 'grey'
                                      "
                                      class="mb-2 mt-2"
                                      @click="setAuto(child)"
                                    >
                                      Auto
                                    </v-btn>
                                    <br v-if="!isMobile" />
                                    <v-btn
                                      small
                                      block
                                      dark
                                      :color="
                                        child.Mode === 'Manual' ? 'red' : 'grey'
                                      "
                                      @click="setManual(child)"
                                    >
                                      Manual
                                    </v-btn>
                                  </v-col>

                                  <v-col
                                    cols="12"
                                    lg="4"
                                    sm="6"
                                    v-if="!isMobile"
                                  >
                                    <v-row
                                      class="mt-2 mb-2"
                                      justify="space-around"
                                    >
                                      <a
                                        @click="setAuto(child)"
                                        v-if="child.Mode == 'Manual'"
                                      >
                                        <img
                                          class="rotate"
                                          src="../assets/control.png"
                                          height="70"
                                          width="70"
                                        />
                                      </a>
                                      <a @click="setManual(child)" v-else>
                                        <img
                                          class="rotate1"
                                          src="../assets/control-off.png"
                                          height="70"
                                          width="70"
                                        />
                                      </a>

                                      <!-- :class="child.Mode == 'ManualO' ? 'rotate1' : 'rotate'" -->
                                    </v-row>
                                  </v-col>
                                </v-row>
                                <br />
                              </div>

                              <v-divider></v-divider>

                              <div
                                id="setPump"
                                :class="
                                  getAccess.role != 'Operator' ? '' : 'mt-3'
                                "
                              >
                                <v-row>
                                  <v-col class="mt-2 pb-0 mx-3">
                                    <v-row class="justify-space-between">
                                      <h4>Set Pump</h4>
                                      <v-btn
                                        v-if="getAccess.role != 'Operator'"
                                        color="#1bb394"
                                        icon
                                        fab
                                        small
                                        @click="setPump(child)"
                                      >
                                        <v-icon v-if="isSetpump">
                                          mdi-close
                                        </v-icon>
                                        <v-icon v-else> mdi-pencil </v-icon>
                                      </v-btn>
                                    </v-row>
                                  </v-col>
                                </v-row>

                                <v-row v-if="!isSetpump">
                                  <v-col cols="6">
                                    <v-text-field
                                      color="#1bb394"
                                      label="Set Low (m.):"
                                      :disabled="!isSetpump"
                                      v-model="child.level.low"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="6">
                                    <v-text-field
                                      color="#1bb394"
                                      label="Set LowLow (m.):"
                                      :disabled="!isSetpump"
                                      v-model="child.level.lowlow"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row v-else>
                                  <v-col cols="6">
                                    <v-text-field
                                      color="#1bb394"
                                      label="Set Low (m.):"
                                      :disabled="!isSetpump"
                                      v-model="setLevel.low"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="6">
                                    <v-text-field
                                      color="#1bb394"
                                      label="Set LowLow (m.):"
                                      :disabled="!isSetpump"
                                      v-model="setLevel.lowlow"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>

                                <v-btn
                                  block
                                  small
                                  color="success"
                                  :disabled="!isSetpump"
                                  @click="setLevelPumps()"
                                  v-if="getAccess.role != 'Operator'"
                                >
                                  set now
                                </v-btn>
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-actions>
                        <!-- </v-card> -->
                        <!-- </v-row> -->
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card>
                </v-col>

                <!-- control pond -->
                <v-col cols="12" sm="12" md="12" lg="12" class="content_setup">
                  <v-card height="270" elevation="4" class="pb-0">
                    <v-card-title>
                      <span>{{ item.pond.name }}</span>
                      <v-spacer></v-spacer>
                      <v-btn
                        icon
                        fab
                        small
                        color="#1bb394"
                        @click="isEdit(item)"
                        v-if="getAccess.role != 'Operator'"
                      >
                        <v-icon v-if="!isEditing">mdi-pencil</v-icon>
                        <v-icon v-else>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>

                    <v-card-text class="pb-0">
                      <v-row v-if="isEditing == false">
                        <v-col cols="6" class="py-0 pl-1 pr-3">
                          <v-text-field
                            color="#1bb394"
                            label="Max (m.):"
                            :disabled="!isEditing"
                            v-model="item.pond.level.max"
                          >
                          </v-text-field>

                          <v-text-field
                            color="#1bb394"
                            :disabled="!isEditing"
                            label="HIGH LEVEL (m.):"
                            v-model="item.pond.level.high"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="py-0 pr-1 pl-0">
                          <v-text-field
                            color="#1bb394"
                            :disabled="!isEditing"
                            label="HIGH HIGH LEVEL (m.):"
                            v-model="item.pond.level.highHigh"
                          ></v-text-field>
                          <v-text-field
                            color="#1bb394"
                            :disabled="!isEditing"
                            label="MEDUIM LEVEL (m.):"
                            v-model="item.pond.level.medium"
                          />
                        </v-col>
                      </v-row>
                      <v-row v-else>
                        <v-col cols="6" class="py-0 pl-1 pr-3">
                          <v-text-field
                            color="#1bb394"
                            label="Max (m.):"
                            :disabled="getAccess.username != 'pmAdmin'"
                            v-model="setLevel.max"
                          ></v-text-field>

                          <v-text-field
                            color="#1bb394"
                            :disabled="!isEditing"
                            label="HIGH LEVEL (m.):"
                            v-model="setLevel.high"
                            type="number"
                            :rules="[
                              setLevel.high <= setLevel.highHigh ||
                                'Value is more than high high level.',
                            ]"
                            :max="item.pond.level.highHigh"
                            :min="item.pond.level.high"
                          />
                        </v-col>

                        <v-col cols="6" class="py-0 pr-1 pl-0">
                          <v-text-field
                            color="#1bb394"
                            :disabled="!isEditing"
                            label="HIGH HIGH LEVEL (m.):"
                            v-model="setLevel.highHigh"
                            type="number"
                            :rules="[
                              setLevel.high <= setLevel.highHigh ||
                                'Value is more than max.',
                            ]"
                            :max="item.pond.level.max"
                            :min="item.pond.level.high"
                          />

                          <v-text-field
                            color="#1bb394"
                            label="MEDUIM LEVEL (m.):"
                            type="number"
                            v-model="setLevel.medium"
                            :disabled="!isEditing"
                            :rules="[
                              setLevel.medium <= setLevel.high ||
                                'Value is more than high level.',
                            ]"
                            :max="item.pond.level.high"
                            :min="0"
                          />
                        </v-col>
                      </v-row>

                      <v-row class="mt-0 pt-0">
                        <v-col cols="1">
                          <span
                            :class="item.pond.warning ? 'dotred' : 'dot'"
                          ></span>
                        </v-col>
                        <v-col cols="3">
                          <v-row class="ml-5 mt-2">
                            <span>warning</span>
                          </v-row>
                        </v-col>
                        <v-col cols="4"></v-col>
                        <v-col cols="3">
                          <v-row align="center" justify="end" class="pt-3">
                            <v-btn
                              :disabled="!isEditing"
                              color="success"
                              block
                              @click="saveDialog = true"
                            >
                              <b color="white"> save </b>
                            </v-btn>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-window-item>
          </v-window>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog persistent v-model="stopDialog" width="400">
      <v-card>
        <v-card-title>
          <span>Confirm Stop Buzzer</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="stopDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <br />

        <v-card-text class="body" style="font-size: 18px">
          <v-row justify="center" align="center">
            <v-icon x-large color="#1bb394"> mdi-alert-octagon-outline</v-icon>
          </v-row>
          <br />
          <v-row justify="center" align="center">
            Are you sure to Stop Buzzer ?
          </v-row>
        </v-card-text>
        <br />
        <v-card-actions>
          <v-btn block text color="#1bb394" @click="stopBuzzerFn()"
            >stop now</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="saveDialog" width="400">
      <v-card>
        <v-card-title>
          <span>Confirm Setting</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="saveDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <br />

        <v-card-text class="body" style="font-size: 18px">
          <v-row justify="center" align="center">
            <v-icon x-large color="#1bb394">
              mdi-content-save-settings-outline
            </v-icon>
          </v-row>
          <br />
          <v-row justify="center" align="center">
            Are you sure to save ?
          </v-row>
        </v-card-text>
        <br />
        <v-card-actions>
          <v-btn block text color="#1bb394" @click="saveSetting()">save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="resetDialog" width="400">
      <v-card>
        <v-card-title>
          <span>Reset Alarm</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="resetDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <br />
        <v-card-text style="font-size: 18px">
          <v-row justify="center" align="center">
            <v-icon x-large color="#1bb394"> mdi-bell-cog-outline </v-icon>
          </v-row>
          <br />
          <v-row justify="center" align="center">
            Are you sure reset alarm pump ?
          </v-row>
        </v-card-text>
        <br />
        <v-card-actions>
          <v-btn block text color="#1bb394" dark @click="resetFn()">
            reset now
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="resetTimeDialog" width="400">
      <v-card>
        <v-card-title>
          <span>Reset Time</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="resetTimeDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <br />
        <v-card-text style="font-size: 18px">
          <v-row justify="center" align="center">
            <v-icon x-large color="#1bb394"> mdi-update </v-icon>
          </v-row>

          <v-row justify="center" align="center">
            Are you sure reset time pump ?
          </v-row>
        </v-card-text>
        <br />
        <v-card-actions>
          <v-btn block text color="#1bb394" @click="resetTimeFn()">
            Reset now
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackBar" top right :color="color">
      <v-row>
        <v-col cols="10"> {{ text }}</v-col>
        <v-col cols="2">
          <v-btn x-small color="white" icon @click="snackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import Sumary from "@/components/sumary.vue";

export default {
  components: {
    Sumary,
  },

  data() {
    return {
      menu: null,
      tabs: null,
      isReset: false,
      isActive: false,
      showBT: false,
      isMobile: false,
      resetTimeDialog: false,
      resetDialog: false,
      btColor: false,
      data: null,
      id: null,
      stopDialog: false,
      saveDialog: false,
      isSetpump: false,
      isEditing: false,
      disabled: false,
      color: null,
      text: null,
      snackBar: false,
      // setLevel: {
      //   id: "",
      //   highHigh: "",
      //   high: "",
      //   medium: "",
      //   max: "",
      //   macAddress: "",
      // },
      setLevel: {
        id: "",
        low: "",
        lowlow: "",
        highHigh: "",
        high: "",
        medium: "",
        max: "",
        macAddress: "",
      },
      data: {
        id: "",
        macAddress: "",
      },
      setOn: {
        id: "",
        On: "",
        macAddress: "",
        pond: "",
      },
      setMode: {
        id: "",
        Mode: "",
        macAddress: "",
        pond: "",
      },
      setReset: {
        id: "",
        reset: "",
        macAddress: "",
      },
      setStopBuzzer: {
        id: "",
        stopBuzzer: "",
        macAddress: " ",
      },
      setResetTime: {
        id: "",
        macAddress: "",
      },
    };
  },

  computed: {
    getAccess() {
      // console.log(this.$store.state.auth.access);
      return this.$store.state.auth.access;
    },
    getPumps() {
      return this.$store.getters.allPumps;
    },
    getStation() {
      console.log(this.$store.getters.stations);
      return this.$store.getters.stations;
    },
  },

  mounted() {
    this.onResize();
  },

  async created() {
    // console.log(this.$route.params.data);
    this.data.id = Cookies.get("stationID");
    this.data.macAddress = Cookies.get("macAddress");
    this.$store.dispatch("getPumps", this.data);
    console.log(this.getPumps);

    // console.log(this.data);
  },

  methods: {
    disabledFn(item) {
      // console.log(item.level.lowlow);
      // console.log(this.getPumps[0].pond.level.value);
      // console.log(item.pumps.level);

      if (item.level.lowlow === this.getPumps[0].pond.level.value) {
        return (this.showBT = true);
      } else {
        return (this.showBT = false);
      }
    },
    tabCheck(data) {
      // console.log(data);
      if (data == 0) {
        if (this.tabData == null) {
          this.tabData = this.getPumps[0].pumps;
          console.log(this.getPumps[0].pumps);
          console.log("ssss");
        }
      }
      if (data == 1) {
        this.tabData = this.getPumps[1];
      }
      if (data == undefined) {
        this.tabData = this.getPumps[0].pumps;
      }
    },

    blink(data) {
      let s = false;
      // console.log(s);

      for (let i = 0; i < data.length; i++) {
        s = data[i].status || s;
      }
      if (s == true) {
        return "blinking";
      } else {
        return "blinkingDown";
      }
    },

    onResize() {
      if (window.innerWidth <= 600) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },

    isEdit(data) {
      this.isEditing = !this.isEditing;

      let level = data.pond.level;

      this.setLevel.id = data.pond.id;
      this.setLevel.highHigh = level.highHigh;
      this.setLevel.high = level.high;
      this.setLevel.medium = level.medium;
      this.setLevel.max = level.max;
      this.setLevel.macAddress = this.data.macAddress;
    },

    saveSetting() {
      //console.log("ss");
      //console.log(this.setLevel);
      if (
        this.setLevel.medium < this.setLevel.high ||
        this.setLevel.high < this.setLevel.highHigh ||
        this.setLevel.highHigh <= this.setLevel.max
      ) {
        this.$store
          .dispatch("setPond", this.setLevel)
          .then(() => {
            this.saveDialog = false;
            this.isEditing = false;
            this.snackBar = true;
            this.text = "Success: Edit level is success";
            this.color = "success";
          })
          .catch((err) => {
            this.saveDialog = false;
            this.isEditing = false;
            this.snackBar = true;
            this.text = err;
            this.color = "error";
          });
      } else {
        this.snackBar = true;
        this.text = "Value is false";
      }
    },
    setPump(data) {
      this.isSetpump = !this.isSetpump;

      let level = data.level;
      console.log(data);
      this.setLevel.id = data.id;
      this.setLevel.low = level.low;
      this.setLevel.lowlow = level.lowlow;
      this.setLevel.macAddress = this.data.macAddress;
    },

    setLevelPumps() {
      // console.log(item);

      // this.setLevel.id = item.id;
      // this.setLevel.low = item.level.low;
      // this.setLevel.lowlow = item.level.lowlow;
      // this.setLevel.macAddress = this.data.macAddress;

      this.$store
        .dispatch("setLow", this.setLevel)
        .then(() => {
          this.saveDialog = false;
          this.isEditing = false;
          this.snackBar = true;
          this.text = "Success: Edit level is success";
          this.color = "success";
          this.isSetpump = false;
        })
        .catch((err) => {
          this.saveDialog = false;
          this.isEditing = false;
          this.snackBar = true;
          this.text = err;
          this.color = "error";
          this.isSetpump = false;
        });
    },

    pumpOn(item) {
      console.log(item);

      this.setOn.id = item.id;
      this.setOn.On = true;
      this.setOn.macAddress = this.data.macAddress;

      console.log(this.setOn);

      this.$store
        .dispatch("setOn", this.setOn)
        .then((res) => {
          this.snackBar = true;
          this.text = "Success: Pump is 'ON' ";
          this.color = "success";
        })
        .catch((err) => {
          this.snackBar = true;
          this.text = "Error: Pump can't 'ON' ";
          this.color = "error";
        });
    },

    pumpOff(item) {
      console.log(item);

      this.setOn.id = item.id;
      this.setOn.On = false;
      this.setOn.macAddress = this.data.macAddress;

      console.log(this.setOn);

      this.$store
        .dispatch("setOn", this.setOn)
        .then(() => {
          this.snackBar = true;
          this.text = "Success: Pump is 'OFF'";
          this.color = "error";
        })
        .catch((err) => {
          this.snackBar = true;
          this.text = "Error: Pump can't 'OFF' ";
          this.color = "error";
        });
    },

    setManual(item) {
      console.log(item);
      console.log(this.getPumps);

      this.setMode.id = item.id;
      this.setMode.Mode = "Manual";
      this.setMode.macAddress = this.data.macAddress;

      console.log(this.setMode);
      this.$store
        .dispatch("setMode", this.setMode)
        .then(() => {
          this.snackBar = true;
          this.text = "Success: Currently using manual mode.";
          this.color = "success";
        })
        .catch((err) => {
          console.log(err);
        });

      console.log(this.setMode);
    },

    setAuto(item) {
      console.log(item);

      this.setMode.id = item.id;
      this.setMode.Mode = "Auto";
      this.setMode.macAddress = this.data.macAddress;
      // console.log(this.setMode);

      this.$store
        .dispatch("setMode", this.setMode)
        .then(() => {
          this.snackBar = true;
          this.text = "Success: Currently using automatric mode.";
          this.color = "success";
        })
        .catch((err) => {
          console.log(err);
        });

      // console.log(this.setMode);
    },

    resetOpen(item) {
      console.log(item);
      this.resetDialog = true;
      this.setReset.id = item.id;
      this.setReset.macAddress = this.data.macAddress;
      this.setReset.reset = true;
    },
    toggleColorReset() {
      if (!this.isReset) {
        this.isReset = true;
        setTimeout(() => {
          this.isReset = false;
        }, 5000);
      }
    },

    resetFn() {
      // console.log(this.setReset);

      this.$store
        .dispatch("setReset", this.setReset)
        .then((res) => {
          this.resetDialog = false;
          this.snackBar = true;
          this.text = "Success:" + " " + res.data.data;
          this.color = "success";
          this.toggleColorReset();
        })
        .catch((err) => {
          this.snackBar = true;
          this.text = "Error: Reset can't success";
          this.color = "error";
        });
    },

    toggleColorStop() {
      if (!this.isActive) {
        this.isActive = true;
        setTimeout(() => {
          this.isActive = false;
        }, 5000);
      }
    },

    StopBuzzer(item) {
      console.log(item);
      this.stopDialog = true;
      this.setStopBuzzer.id = item.id;
      this.setStopBuzzer.stopBuzzer = true;
      this.setStopBuzzer.macAddress = this.data.macAddress;
    },

    stopBuzzerFn() {
      this.$store
        .dispatch("setStopBuzzer", this.setStopBuzzer)
        .then((res) => {
          this.snackBar = true;
          this.text = "Success:" + " " + res.data.data;
          this.color = "success";
          this.stopDialog = false;
          this.toggleColorStop();
        })
        .catch((err) => {
          this.snackBar = true;
          this.text = "Error: Stop buzzer can't success";
          this.color = "error";
          this.stopDialog = false;
        });
    },

    resetTimeOpen(item) {
      console.log(item);
      this.resetTimeDialog = true;
      this.setResetTime.id = item.id;
      this.setResetTime.macAddress = this.data.macAddress;
    },

    resetTimeFn() {
      this.$store
        .dispatch("setResetTime", this.setResetTime)
        .then((res) => {
          this.resetTimeDialog = false;
          this.snackBar = true;
          this.text = "Success:" + " " + res.data.data;
          this.color = "success";
        })
        .catch((err) => {
          this.snackBar = true;
          this.text = "Error: Reset time can't success";
          this.color = "error";
        });
    },
  },
};
</script>

<style scoped>
.dot {
  height: 40px;
  width: 40px;
  background-color: grey;
  border-radius: 50%;
  border: 6px solid rgb(204, 204, 204);
  display: inline-block;
}

.dotred {
  height: 40px;
  width: 40px;
  background-color: red;
  border-radius: 50%;
  border: 6px solid rgb(204, 204, 204);
  display: inline-block;
}

.alarmOff {
  height: 40px;
  width: 40px;
  background-color: grey;
  border-radius: 50%;
  border: 6px solid rgb(204, 204, 204);
  display: inline-block;
}

.alarmOn {
  height: 40px;
  width: 40px;
  background-color: red;
  border-radius: 50%;
  border: 6px solid rgb(204, 204, 204);
  display: inline-block;
}

.blinkingDown {
  background-color: rgb(165, 165, 165);
}

.blinking {
  animation: blinked 1s ease-in-out infinite;
}

@keyframes blinked {
  0% {
    background-color: rgb(165, 165, 165);
  }
  50% {
    background-color: orangered;
    color: #f5f5f5;
  }
  100% {
    background-color: rgb(165, 165, 165);
  }
}

.rotate {
  animation: rotation 0.4s ease-in-out both;
}

.rotate1 {
  animation: rotation1 0.4s ease-in-out both;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-60deg);
  }
}

@keyframes rotation1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(60deg);
  }
}

.content_setup {
  opacity: 0;
  transform: translateX(20px);
  animation: slideIn 0.8s ease-in forwards 0.8s;
}

.content_sumary {
  opacity: 0;
  transform: translateX(20px);
  animation: slideIn 0.8s ease-in forwards 0.6s;
}

#content_control {
  opacity: 0;
  transform: translateX(20px);
  animation: slideIn 0.8s ease-in forwards 0.4s;
}

@keyframes slideIn {
  0% {
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
</style>
