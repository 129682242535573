<template>
  <v-layout v-resize="onResize" column>
    <div>
      <template v-if="!$route.meta.allowAnonymous">
        <template v-if="isMobile">
          <v-app>
            <div class="nav">
              <Navbar />
              <div class="content">
                <v-main>
                  <v-container fuild>
                    <router-view />
                  </v-container>
                </v-main>
              </div>
              <!-- <BottomNav /> -->
            </div>
          </v-app>
        </template>
        <template v-else>
          <v-app>
            <div class="sidebar">
              <Navbar />
              <div class="content">
                <v-main>
                  <v-container fuild>
                    <router-view />
                  </v-container>
                </v-main>
              </div>
            </div>
          </v-app>
        </template>
      </template>
      <template v-else>
        <transition>
          <keep-alive>
            <router-view></router-view>
          </keep-alive>
        </transition>
      </template>
    </div>
  </v-layout>
</template>

<script>
// import Sidebar from "@/components/Sidebar.vue";
import Navbar from "@/components/Navbar.vue";
// import BottomNav from "@/views/bottomNav.vue";

export default {
  name: "Home",
  components: { Navbar },
  data() {
    return {
      isMobile: false,
      breadcrumbs: "",
    };
  },
  methods: {
    onResize() {
      if (window.innerWidth <= 600) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
};
</script>

<style>
.apexcharts-toolbar {
  z-index: 0 !important;
}
</style>
