import axios from "axios";
import authHeader from "./auth-header";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

const apiURL = process.env.VUE_APP_API_URL + "users/";

class authService {
  login(user) {
    return axios
      .post(apiURL + "login", {
        username: user.username,
        password: user.password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          Cookies.set("user", JSON.stringify(response.data));
          return response.data;
        }
      });
  }
  logout() {
    Cookies.remove("user");
    Cookies.remove("nameStation");
    Cookies.remove("idLevel");
    Cookies.remove("nameLevel");
  }
  resetPassword(payload) {
    return axios.put(
      apiURL + "resetPassword/" + payload.id,
      {
        newPassword: payload.newPassword,
      },
      { headers: authHeader() }
    );
  }
  changePassword(payload) {
    return axios.put(
      apiURL + "changePassword/" + payload.id,
      {
        newPassword: payload.newPassword,
        password: payload.password,
      },
      { headers: authHeader() }
    );
  }
  jwtDecode() {
    const data = Cookies.get("user");
    // console.log(data);
    if (data != undefined) {
      const user = JSON.parse(Cookies.get("user"));
      // console.log(user);
      return jwt_decode(user.accessToken);
    }
  }
}
export default new authService();
