import axios from "axios";
import authHeader from "@/service/auth-header";

const apiURL = process.env.VUE_APP_API_URL + "records/";

export const times = {
  state: {
    times: [],
  },
  getters: {
    times: (state) => state.times,
  },
  mutations: {
    setTimes(state, times) {
      state.times = times;
      state.times.options.tooltip.y = {
        formatter: function (val) {
          return val + " นาที";
        },
      };
    },
  },
  actions: {
    getTime({ commit }, data) {
      console.log(data);
      return axios
        .put(
          apiURL + "time/",
          { macAddress: data.macAddress, start: data.start, end: data.end },
          { Headers: authHeader() }
        )
        .then((res) => {
          console.log(res.data.data);
          commit("setTimes", res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
