<template>
  <div v-if="loader">
    <v-progress-linear
      v-if="loader"
      :size="100"
      color="#1bb394"
      :indeterminate="loader"
      absolute
      top
      class="mb-2"
    >
    </v-progress-linear>
  </div>
  <div v-else>
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1080 640"
      style="enable-background: new 0 0 1080 640"
      xml:space="preserve"
    >
      <g id="layouts">
        <g>
          <linearGradient
            id="SVGID_1_"
            gradientUnits="userSpaceOnUse"
            x1="1014.0596"
            y1="640"
            x2="1014.0596"
            y2="252.4503"
          >
            <stop offset="0" style="stop-color: #412312" />
            <stop offset="0.5642" style="stop-color: #8c633a" />
            <stop offset="0.7486" style="stop-color: #a57c52" />
            <stop offset="0.801" style="stop-color: #8a6443" />
            <stop offset="0.8946" style="stop-color: #60402a" />
            <stop offset="0.9636" style="stop-color: #492b1a" />
            <stop offset="1" style="stop-color: #412312" />
          </linearGradient>
          <path
            class="st0"
            d="M924.9,502.1V252.5h-39.7V640H1143v-37.9h-118.1C969.6,602.1,924.9,557.3,924.9,502.1z"
          />

          <linearGradient
            id="SVGID_00000158714721062922364030000011392629269479942570_"
            gradientUnits="userSpaceOnUse"
            x1="904.9868"
            y1="252.4503"
            x2="904.9868"
            y2="236.5563"
          >
            <stop offset="0" style="stop-color: #3c2415" />
            <stop offset="8.782995e-02" style="stop-color: #3d331e" />
            <stop offset="0.2346" style="stop-color: #394527" />
            <stop offset="0.3909" style="stop-color: #2e542f" />
            <stop offset="0.5592" style="stop-color: #1d5f34" />
            <stop offset="0.7481" style="stop-color: #016537" />
            <stop offset="1" style="stop-color: #006838" />
          </linearGradient>
          <path
            style="
              fill: url(#SVGID_00000158714721062922364030000011392629269479942570_);
            "
            d="M916.9,236.6h-31.8v15.9h39.7v0
			C924.9,243.7,921.3,236.6,916.9,236.6z"
          />
        </g>

        <linearGradient
          id="SVGID_00000057848520985942778620000006558400246274571953_"
          gradientUnits="userSpaceOnUse"
          x1="1033.9271"
          y1="602.1192"
          x2="1033.9271"
          y2="252.4503"
        >
          <stop offset="0" style="stop-color: #412312" />
          <stop offset="0.4972" style="stop-color: #8c633a" />
          <stop offset="0.72" style="stop-color: #a57c52" />
          <stop offset="0.8186" style="stop-color: #7b583a" />
          <stop offset="0.9418" style="stop-color: #50321f" />
          <stop offset="1" style="stop-color: #412312" />
        </linearGradient>
        <path
          style="
            fill: url(#SVGID_00000057848520985942778620000006558400246274571953_);
          "
          d="M924.9,252.5v249.7
		c0,55.2,44.8,100,100,100H1143V252.5L924.9,252.5z"
        />
        <path
          class="st3"
          d="M924.9,252.5v249.7c0,55.2,44.8,100,100,100H1143V252.5L924.9,252.5z"
        />
        <polygon
          class="st4"
          points="798.3,618 98.9,618 98.9,22 798.3,22 798.3,117.4 822.1,117.4 822.1,0 75.1,0 75.1,640 822.1,640 
		822.1,228.6 98.9,228.6 98.9,252.5 798.3,252.5 	"
        />
        <rect x="98.9" y="22" class="st5" width="699.3" height="206.6" />
        <rect x="820" y="228.6" class="st4" width="66" height="411.4" />
      </g>
      <g id="water">
        <linearGradient
          id="SVGID_00000153679663267837941990000000649950896474597281_"
          gradientUnits="userSpaceOnUse"
          x1="98.9404"
          y1="435.3003"
          x2="798.2781"
          y2="435.3003"
        >
          <stop offset="0" style="stop-color: #676767" />
          <stop offset="8.250325e-03" style="stop-color: #6f6e6e" />
          <stop offset="3.712930e-02" style="stop-color: #898787" />
          <stop offset="6.983375e-02" style="stop-color: #a2a0a0" />
          <stop offset="0.1068" style="stop-color: #b8b6b6" />
          <stop offset="0.1499" style="stop-color: #cac9c9" />
          <stop offset="0.2033" style="stop-color: #d8d8d8" />
          <stop offset="0.2782" style="stop-color: #e2e2e2" />
          <stop offset="0.486" style="stop-color: #e5e5e5" />
          <stop offset="0.7058" style="stop-color: #e2e2e2" />
          <stop offset="0.785" style="stop-color: #d8d8d8" />
          <stop offset="0.8415" style="stop-color: #cac9c9" />
          <stop offset="0.8871" style="stop-color: #b8b6b6" />
          <stop offset="0.9262" style="stop-color: #a2a0a0" />
          <stop offset="0.9607" style="stop-color: #898787" />
          <stop offset="0.9913" style="stop-color: #6f6e6e" />
          <stop offset="1" style="stop-color: #676767" />
        </linearGradient>
        <rect
          x="98"
          y="252.5"
          style="
            fill: url(#SVGID_00000153679663267837941990000000649950896474597281_);
          "
          width="702"
          height="367"
        />
        <!-- <rect x="98.9" y="252.5" class="st7" width="699.3" height="10" /> -->
        <rect
          x="98.9"
          y="-620"
          :class="
            getPumps[0].pond.level.value >= getPumps[0].pond.level.highHigh 
              ? 'red'
              : 'st7'
          "
          width="699.3"
          height="1em"
          transform="scale(1,-1)"
          v-bind:style="{
            fontSize: fontSizeWater(getPumps[0].pond.level) + 'px',
          }"
        />
      </g>
      <g id="line">
        <line class="st9" x1="75.1" y1="252.5" x2="53" y2="252.5" />
        <line class="st9" x1="75.1" y1="261.6" x2="63.5" y2="261.6" />
        <line class="st9" x1="75.1" y1="270.7" x2="63.5" y2="270.7" />
        <line class="st9" x1="75.1" y1="279.8" x2="63.5" y2="279.8" />
        <line class="st9" x1="75.1" y1="288.9" x2="63.5" y2="288.9" />
        <line class="st9" x1="75.2" y1="298" x2="59.2" y2="298" />
        <line class="st9" x1="75.1" y1="307.1" x2="63.5" y2="307.1" />
        <line class="st9" x1="75.1" y1="316.2" x2="63.5" y2="316.2" />
        <line class="st9" x1="75.1" y1="325.3" x2="63.5" y2="325.3" />
        <line class="st9" x1="75.1" y1="334.4" x2="63.5" y2="334.4" />
        <line class="st9" x1="75.2" y1="343.5" x2="59.2" y2="343.5" />
        <line class="st9" x1="75.1" y1="352.6" x2="63.5" y2="352.6" />
        <line class="st9" x1="75.1" y1="361.7" x2="63.5" y2="361.7" />
        <line class="st9" x1="75.1" y1="370.8" x2="63.5" y2="370.8" />
        <line class="st9" x1="75.1" y1="379.9" x2="63.5" y2="379.9" />
        <line class="st9" x1="75.2" y1="389" x2="59.2" y2="389" />
        <line class="st9" x1="75.1" y1="398.1" x2="63.5" y2="398.1" />
        <line class="st9" x1="75.1" y1="407.2" x2="63.5" y2="407.2" />
        <line class="st9" x1="75.1" y1="416.3" x2="63.5" y2="416.3" />
        <line class="st9" x1="75.1" y1="425.4" x2="63.5" y2="425.4" />
        <line class="st9" x1="75.2" y1="434.5" x2="53" y2="434.5" />
        <line class="st9" x1="75.1" y1="443.6" x2="63.5" y2="443.6" />
        <line class="st9" x1="75.1" y1="452.7" x2="63.5" y2="452.7" />
        <line class="st9" x1="75.1" y1="461.8" x2="63.5" y2="461.8" />
        <line class="st9" x1="75.1" y1="470.9" x2="63.5" y2="470.9" />
        <line class="st9" x1="75.2" y1="480" x2="59.2" y2="480" />
        <line class="st9" x1="75.1" y1="489.1" x2="63.5" y2="489.1" />
        <line class="st9" x1="75.1" y1="498.2" x2="63.5" y2="498.2" />
        <line class="st9" x1="75.1" y1="507.3" x2="63.5" y2="507.3" />
        <line class="st9" x1="75.1" y1="516.4" x2="63.5" y2="516.4" />
        <line class="st9" x1="75.2" y1="525.5" x2="59.2" y2="525.5" />
        <line class="st9" x1="75.1" y1="534.6" x2="63.5" y2="534.6" />
        <line class="st9" x1="75.1" y1="543.7" x2="63.5" y2="543.7" />
        <line class="st9" x1="75.1" y1="552.8" x2="63.5" y2="552.8" />
        <line class="st9" x1="75.1" y1="561.9" x2="63.5" y2="561.9" />
        <line class="st9" x1="75.2" y1="571" x2="59.2" y2="571" />
        <line class="st9" x1="75.1" y1="580.1" x2="63.5" y2="580.1" />
        <line class="st9" x1="75.1" y1="589.2" x2="63.5" y2="589.2" />
        <line class="st9" x1="75.1" y1="598.3" x2="63.5" y2="598.3" />
        <line class="st9" x1="75.1" y1="607.4" x2="63.5" y2="607.4" />
        <line class="st9" x1="75.1" y1="616.5" x2="53" y2="616.5" />
      </g>
      <g id="pumps">
        <path
          v-if="getPumps[0].pumps[0].runPump"
          class="water"
          d="M838,157.1h48.5c68.4,0,131.6,36.3,166,95.4v0H949.3v0c0-21.9-17.8-39.7-39.7-39.7h-15.9L838,157.1z"
        />
        <linearGradient
          id="SVGID_00000012441484093353434530000012745569278883872440_"
          gradientUnits="userSpaceOnUse"
          x1="869.7897"
          y1="156.85"
          x2="852.9848"
          y2="226.4836"
          gradientTransform="matrix(1.2312 -1.2312 1.1089 1.1089 -403.234 1028.7559)"
        >
          <stop offset="0" style="stop-color: #676767" />
          <stop offset="2.776963e-03" style="stop-color: #686868" />
          <stop offset="0.1657" style="stop-color: #959393" />
          <stop offset="0.3063" style="stop-color: #bcbbbb" />
          <stop offset="0.4177" style="stop-color: #d8d8d7" />
          <stop offset="0.486" style="stop-color: #e5e5e5" />
          <stop offset="0.5583" style="stop-color: #d8d8d7" />
          <stop offset="0.6761" style="stop-color: #bcbbbb" />
          <stop offset="0.8247" style="stop-color: #959393" />
          <stop offset="0.9971" style="stop-color: #686868" />
          <stop offset="1" style="stop-color: #676767" />
        </linearGradient>
        <polygon
          style="
            fill: url(#SVGID_00000012441484093353434530000012745569278883872440_);
          "
          points="905,225.3 914.9,215.5 
			834.8,136.2 824.9,146 		"
        />

        <g id="pump01">
          <g id="pipe">
            <linearGradient
              id="SVGID_00000069390012842306281410000009279731622267432611_"
              gradientUnits="userSpaceOnUse"
              x1="410.4729"
              y1="355.7616"
              x2="505.8371"
              y2="355.7616"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.776963e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>

            <rect
              x="410.5"
              y="109.4"
              style="
                fill: url(#SVGID_00000069390012842306281410000009279731622267432611_);
              "
              width="95.4"
              height="492.7"
            />
            <polygon
              class="st4"
              points="505.8,228.6 513.8,220.7 489.9,180.9 			"
            />

            <linearGradient
              id="SVGID_00000147207101858334927200000017975440765802131376_"
              gradientUnits="userSpaceOnUse"
              x1="402.5259"
              y1="127.798"
              x2="513.7841"
              y2="127.798"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.776963e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>

            <rect
              x="402.5"
              y="125.3"
              style="
                fill: url(#SVGID_00000147207101858334927200000017975440765802131376_);
              "
              width="111.3"
              height="5"
            />

            <linearGradient
              id="SVGID_00000047748284422124877010000007282715756890971273_"
              gradientUnits="userSpaceOnUse"
              x1="397.655"
              y1="105.4305"
              x2="518.655"
              y2="105.4305"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.776963e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>

            <rect
              x="397.7"
              y="101.5"
              style="
                fill: url(#SVGID_00000047748284422124877010000007282715756890971273_);
              "
              width="121"
              height="7.9"
            />

            <linearGradient
              id="SVGID_00000087392196198920380240000009081201704832720571_"
              gradientUnits="userSpaceOnUse"
              x1="695.7662"
              y1="220.6622"
              x2="695.7662"
              y2="149.1391"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.776963e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>
            <polygon
              style="
                fill: url(#SVGID_00000087392196198920380240000009081201704832720571_);
              "
              points="513.8,220.7 489.9,180.9 
				505.8,149.1 830.1,149.1 901.6,220.7 			"
            />
          </g>
          <g id="pump">
            <rect x="438.5" y="433" class="st16" width="39.6" height="1.5" />
            <rect x="429.3" y="455.6" class="st16" width="57.9" height="1.5" />
            <rect x="429.3" y="454.1" class="st16" width="57.9" height="1.5" />
            <path
              class="st16"
              d="M476.5,431.5H440c-0.8,0-1.5,0.7-1.5,1.5l0,0H478l0,0C478,432.2,477.4,431.5,476.5,431.5z"
            />
            <path
              :class="pumpStatus(getPumps[0].pumps[0].runPump)"
              d="M426.4,464.6l1.6,4.9c0.7,2.1,1,4.3,1,6.5v42.3h58.6V477c0-2.9,0.6-5.7,1.8-8.4l1.7-4H426.4z"
            />
            <polygon
              class="st16"
              points="487.6,563.8 487.6,563.8 429,563.8 429,563.8 			"
            />
            <polygon
              :class="pumpStatus(getPumps[0].pumps[0].runPump)"
              points="486.8,566.8 485.5,571.4 484.4,575.8 432.3,575.8 431.1,571.4 429.8,566.8 			"
            />
            <path
              class="st18"
              d="M424.7,581.8l-0.5,1.4c-2.8,7-7.4,13.5-13.7,18.9h0H506l0,0c-6.2-5.4-10.9-11.9-13.7-18.9l-0.5-1.4H424.7z"
            />
            <path
              :class="pumpStatus(getPumps[0].pumps[0].runPump)"
              d="M429.3,461.6v-4.5v-3l9.2-19.5V433c0-0.8,0.9-1.5,1.9-1.5h35.7c1.1,0,1.9,0.7,1.9,1.5v1.5l9.2,19.5v3v4.5
				H429.3z"
            />
            <path
              class="st16"
              d="M477.1,431.1l-9-4h-19.5l-9.1,4c-0.6,0.3-1,0.8-1,1.3v2.1H478v-2.1C478,431.9,477.7,431.4,477.1,431.1z"
            />
            <path class="st19" d="M438.4,472.3" />
            <polygon
              :class="pumpStatus(getPumps[0].pumps[0].runPump)"
              points="487.6,563.8 487.8,540.5 497,525.5 418.9,525.5 428.9,540.5 429,563.8 			"
            />
            <path
              class="st20"
              d="M438.1,515.3L438.1,515.3c-1.1,0-1.9-0.9-1.9-1.9v-41.3c0-1.1,0.9-1.9,1.9-1.9h0c1.1,0,1.9,0.9,1.9,1.9
				v41.3C440,514.5,439.2,515.3,438.1,515.3z"
            />
            <path
              class="st20"
              d="M448.8,515.3L448.8,515.3c-1.1,0-1.9-0.9-1.9-1.9v-41.3c0-1.1,0.9-1.9,1.9-1.9h0c1.1,0,1.9,0.9,1.9,1.9
				v41.3C450.7,514.5,449.8,515.3,448.8,515.3z"
            />
            <path
              class="st20"
              d="M458.4,515.3L458.4,515.3c-1.1,0-1.9-0.9-1.9-1.9v-41.3c0-1.1,0.9-1.9,1.9-1.9h0c1.1,0,1.9,0.9,1.9,1.9
				v41.3C460.3,514.5,459.5,515.3,458.4,515.3z"
            />
            <path
              class="st20"
              d="M468.1,515.3L468.1,515.3c-1.1,0-1.9-0.9-1.9-1.9v-41.3c0-1.1,0.9-1.9,1.9-1.9h0c1.1,0,1.9,0.9,1.9,1.9
				v41.3C470,514.5,469.1,515.3,468.1,515.3z"
            />
            <path
              class="st20"
              d="M477.7,515.3L477.7,515.3c-1.1,0-1.9-0.9-1.9-1.9v-41.3c0-1.1,0.9-1.9,1.9-1.9h0c1.1,0,1.9,0.9,1.9,1.9
				v41.3C479.6,514.5,478.8,515.3,477.7,515.3z"
            />
            <rect x="426.4" y="463.1" class="st16" width="64.7" height="1.5" />
            <rect x="426.4" y="461.6" class="st16" width="64.7" height="1.5" />
            <rect
              x="428.8"
              y="575.8"
              :class="pumpStatus(getPumps[0].pumps[0].runPump)"
              width="58.9"
              height="3"
            />
            <rect
              x="419.2"
              y="578.8"
              :class="pumpStatus(getPumps[0].pumps[0].runPump)"
              width="78.2"
              height="3"
            />
            <polygon
              class="st20"
              points="497.4,563.8 497.4,566.8 419.2,566.8 419.2,563.8 429,563.8 429,563.8 487.6,563.8 487.6,563.8 			
				"
            />
            <rect x="416.4" y="518" class="st20" width="84" height="7.5" />
          </g>
          <g id="crop">
            <linearGradient
              id="SVGID_00000035501604833525356870000005083150543644117676_"
              gradientUnits="userSpaceOnUse"
              x1="410.4729"
              y1="510.7285"
              x2="506.033"
              y2="510.7285"
            >
              <stop offset="0" style="stop-color: #676767" />
              <stop offset="2.776963e-03" style="stop-color: #686868" />
              <stop offset="0.1657" style="stop-color: #959393" />
              <stop offset="0.3063" style="stop-color: #bcbbbb" />
              <stop offset="0.4177" style="stop-color: #d8d8d7" />
              <stop offset="0.486" style="stop-color: #e5e5e5" />
              <stop offset="0.5583" style="stop-color: #d8d8d7" />
              <stop offset="0.6761" style="stop-color: #bcbbbb" />
              <stop offset="0.8247" style="stop-color: #959393" />
              <stop offset="0.9971" style="stop-color: #686868" />
              <stop offset="1" style="stop-color: #676767" />
            </linearGradient>
            <path
              style="
                fill: url(#SVGID_00000035501604833525356870000005083150543644117676_);
              "
              d="M410.5,419.3v182.8H506V419.3H410.5z
				 M501.8,557.4c0,18.7-19.5,33.9-43.5,33.9c-12,0-22.9-3.8-30.8-9.9c-7.9-6.1-12.7-14.6-12.7-24v-94.8c0-18.7,19.5-33.9,43.5-33.9
				c24,0,43.5,15.2,43.5,33.9V557.4z"
            />
            <path
              class="st22"
              d="M501.8,462.7v94.8c0,18.7-19.5,33.9-43.5,33.9c-12,0-22.9-3.8-30.8-9.9c-7.9-6.1-12.7-14.6-12.7-24v-94.8
				c0-18.7,19.5-33.9,43.5-33.9C482.3,428.7,501.8,443.9,501.8,462.7z"
            />
          </g>
        </g>
        <text transform="matrix(1 0 0 1 410 93.5098)" class="st39">
          ปั๊มที่ 1
        </text>
      </g>
      <g id="value">
        <text transform="matrix(1 0 0 1 2.5232 228.6094)" class="st38">
          เซนติเมตร
        </text>

        <text transform="matrix(1 0 0 1 2.523 260.3976)" class="st40">
          {{ Math.round(getPumps[0].pond.level.max) * 100 }}
        </text>
        <text transform="matrix(1 0 0 1 2.5229 353.1783)" class="st40">
          {{ Math.round(getPumps[0].pond.level.max / 1.333333333) * 100 }}
        </text>
        <text transform="matrix(1 0 0 1 2.5229 535.1783)" class="st40">
          {{ Math.round(getPumps[0].pond.level.max / 4) * 100 }}
        </text>
        <text transform="matrix(1 0 0 1 28 625.9607)" class="st40">0</text>
      </g>
      <g id="valueH">
        <text x="160" y="460" class="st41">
          {{ getPumps[0].pond.level.value }} เมตร
        </text>
      </g>
      <g id="name">
        <path
          class="st42"
          d="M1000,4.5H826.1v108.4h172.3c29.9,0,54.2-24.3,54.2-54.2v0C1052.6,28.8,1028.3,4.5,998.4,4.5z"
        />
        <text transform="matrix(1 0 0 1 840 43)">
          <tspan x="5" y="25" class="st43">สุขุมวิท</tspan>
          <tspan x="135" y="30" class="st48">49</tspan>
        </text>
      </g>
      <g id="gauge">
        <rect
          v-if="
            getPumps[0].pond.level.highHigh != 0 &&
            getPumps[0].pond.level.max != 0 &&
            getPumps[0].pond.level.highHigh <= getPumps[0].pond.level.max
          "
          x="798.9"
          :y="rectHihi(getPumps[0].pond.level)"
          :class="checkHihi(getPumps[0].pond)"
          class="st44"
          width="86.8"
          height="28.2"
        />
        <rect
          v-if="
            getPumps[0].pond.level.high != 0 &&
            getPumps[0].pond.level.max != 0 &&
            getPumps[0].pond.level.high <= getPumps[0].pond.level.max
          "
          x="798.9"
          :y="rectHigh(getPumps[0].pond.level)"
          :class="checkHi(getPumps[0].pond)"
          class="st44"
          width="86.8"
          height="28.2"
        />
        <rect
          v-if="
            getPumps[0].pond.level.medium != 0 &&
            getPumps[0].pond.level.max != 0 &&
            getPumps[0].pond.level.medium <= getPumps[0].pond.level.max
          "
          x="798.3"
          :y="rectMedium(getPumps[0].pond.level)"
          :class="checkMedium(getPumps[0].pond)"
          width="86.8"
          height="28.2"
        />
        <text
          v-if="
            getPumps[0].pond.level.medium != 0 &&
            getPumps[0].pond.level.max != 0 &&
            getPumps[0].pond.level.medium <= getPumps[0].pond.level.max
          "
          x="818"
          :y="levelMedium(getPumps[0].pond.level)"
          :class="checkMedium(getPumps[0].pond)"
        >
          Medium
        </text>
        <text
          v-if="
            getPumps[0].pond.level.high != 0 &&
            getPumps[0].pond.level.max != 0 &&
            getPumps[0].pond.level.high <= getPumps[0].pond.level.max
          "
          x="825"
          :y="levelHigh(getPumps[0].pond.level)"
          :class="checkHi(getPumps[0].pond)"
        >
          High
        </text>
        <text
          v-if="
            getPumps[0].pond.level.highHigh != 0 &&
            getPumps[0].pond.level.max != 0 &&
            getPumps[0].pond.level.highHigh <= getPumps[0].pond.level.max
          "
          x="812"
          :y="levelHihi(getPumps[0].pond.level)"
          :class="checkHihi(getPumps[0].pond)"
        >
          HighHigh
        </text>
      </g>
      <text transform="matrix(1 0 0 1 930 412)" class="st40">คลองแสนแสบ</text>
    </svg>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  name: "WatercontrolAppStation",

  data() {
    return {
      data: {
        id: "",
        macAddress: "",
      },
      pump: [],
      floor: "",
      loader: true,
    };
  },

  computed: {
    getPumps() {
      return this.$store.getters.allPumps;
    },
    getStation() {
      return this.$store.getters.stations;
    },
  },

  created() {
    // console.log(this.$route.params.data);
    this.data.id = Cookies.get("stationID");
    this.data.macAddress = Cookies.get("macAddress");
    // console.log(this.data);
  },

  mounted() {
    this.$store.dispatch("getPumps", this.data).then((res) => {
      this.loader = false;
    });

    // console.log("data");
  },

  methods: {
    levelLowLow(y) {
      let size = ([y.highHigh] * 425) / y.max;

      // console.log(425 + size);
      return 968 - size;
    },
    levelLow(y) {
      // console.log(y);
      let size = ([y.highHigh] * 425) / y.max;

      // console.log(425 + size);
      return 968 - size;
    },

    levelHihi(y) {
      let size = ([y.highHigh] * 348) / y.max;

      // console.log(425 + size);
      return 620 - size;
    },
    levelHigh(y) {
      // console.log(y.high);
      let size = ([y.high] * 348) / y.max;

      // console.log(size);
      return 620 - size;
    },
    levelMedium(y) {
      // console.log(y.medium);
      let size = ([y.medium] * 348) / y.max;

      return 620 - size;
    },
    rectHihi(y) {
      if (y.max != 0) {
        let size = ([y.highHigh] * 348) / y.max;

        return 600 - size;
      }
    },
    rectHigh(y) {
      let size = ([y.high] * 348) / y.max;

      return 600 - size;
    },
    rectMedium(y) {
      let size = ([y.medium] * 348) / y.max;

      return 600 - size;
    },
    fontSizeWater(d) {
      if (d.value <= d.max) {
        // console.log(this.getPumps[0].pond.level.max);
        let size = (d.value * 348) / d.max;
        return size;
      } else {
        console.log("ปริมาณน้ำมากกว่าความจุบ่อ");
        let size = ([d.max + 0.25] * 348) / d.max;
        return size;
      }
    },
    waterY(h) {
      if (h.value <= h.max) {
        let size = (h.value * 454) / h.max;
        // console.log(998 - size);
        return 1010 - size;
      } else {
        console.log("ปริมาณน้ำมากกว่าความจุบ่อ");
      }
    },
    pumpStatus(p) {
      // console.log(p);
      if (p == true) {
        return "st101";
      } else {
        return "st100";
      }
    },
    checkHihi(l) {
      let level = l.level;
      // console.log(level);
      if (level.value >= level.highHigh && level.value) {
        // console.log("hihi");
        return "hihi";
      } else {
        return "st44";
      }
    },
    checkHi(l) {
      let level = l.level;
      // console.log(level);

      if (level.value >= level.high && level.value < level.highHigh) {
        return "hi";
      } else {
        return "st44";
      }
    },
    checkMedium(l) {
      let level = l.level;
      if (level.value >= level.medium && level.value < level.high) {
        return "me ";
      } else {
        return "st44";
      }
    },
  },
};
</script>

<style scoped>
.red {
  fill: #EF5350;
  opacity: 0.5;
}
.st101 {
  fill: green;
  stroke: #000000;
  stroke-miterlimit: 10;
}
.st100 {
  fill: red;
  stroke: #000000;
  stroke-miterlimit: 10;
}
.st0 {
  fill: url(#SVGID_1_);
}
.st1 {
  fill: url(#SVGID_00000172408455036341968280000002663621791358092173_);
}
.st2 {
  fill: url(#SVGID_00000034791267396850874580000010889906640668736387_);
}
.st3 {
  opacity: 0.76;
  fill: #00aeef;
}
.st4 {
  fill: #676767;
}
.st5 {
  fill: #e0e0e0;
}
.st6 {
  fill: url(#SVGID_00000027566073650597074100000006437705473409185691_);
}
.st7 {
  opacity: 0.3;
  fill: #5dccf5;
}
.st8 {
  fill: #ffffff;
}
.st9 {
  fill: none;
  stroke: #000000;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
.st10 {
  display: none;
}
.st11 {
  display: inline;
}
.st12 {
  fill: url(#SVGID_00000165225121632550080160000003644650133498144907_);
}
.st13 {
  fill: url(#SVGID_00000183947213137625109100000010926192366263447170_);
}
.st14 {
  fill: url(#SVGID_00000070840297688176799320000012609073414963670656_);
}
.st15 {
  fill: url(#SVGID_00000016781991483665849980000015310680758811870342_);
}
.st16 {
  fill: #8f8f8f;
  stroke: #010101;
  stroke-miterlimit: 10;
}
.st17 {
  fill: #c1282d;
  stroke: #010101;
  stroke-miterlimit: 10;
}
.st18 {
  fill: #525252;
  stroke: #010101;
  stroke-miterlimit: 10;
}
.st19 {
  fill: none;
  stroke: #010101;
  stroke-miterlimit: 10;
}
.st20 {
  fill: #010101;
}
.st21 {
  fill: url(#SVGID_00000017489401689881614690000016625601234472451504_);
}
.st22 {
  fill: none;
  stroke: #ffffff;
  stroke-miterlimit: 10;
}
.st23 {
  fill: url(#SVGID_00000034810105559350048870000015974280873313796020_);
}
.st24 {
  fill: url(#SVGID_00000099637085374024467240000014141380278114041791_);
}
.st25 {
  fill: url(#SVGID_00000089553009561799196320000004219330772531750019_);
}
.st26 {
  fill: url(#SVGID_00000084503001867415212950000008390866799071403178_);
}
.st27 {
  fill: url(#SVGID_00000113325296919161900530000002300137857001188768_);
}
.st28 {
  fill: url(#SVGID_00000090259437280906180680000005042583076179898535_);
}
.st29 {
  fill: url(#SVGID_00000105401493840315961130000018149812907183481268_);
}
.st30 {
  fill: url(#SVGID_00000151540825652204829100000005796549395907336072_);
}
.st31 {
  fill: url(#SVGID_00000118369168650414187140000016076879040324949175_);
}
.st32 {
  fill: url(#SVGID_00000135690855311507953330000008610845863305758362_);
}
.st33 {
  fill: url(#SVGID_00000117653868327675149280000010955964077735786631_);
}
.st34 {
  fill: url(#SVGID_00000060753478385779417740000015953194773484155015_);
}
.st35 {
  fill: url(#SVGID_00000139982002042751583290000014663723967806518204_);
}
.st36 {
  fill: url(#SVGID_00000122715361679174830320000004705571424167940768_);
}
.st37 {
  fill: url(#SVGID_00000084499974374736307530000017564076604693899414_);
}
.st38 {
  font-size: 17px;
}
.st39 {
  font-size: 36px;
}
.st40 {
  font-size: 22px;
}
.st41 {
  font-size: 48px;
}
.st42 {
  fill: #ffffff;
  stroke: #000000;
  stroke-width: 8;
  stroke-miterlimit: 10;
}
.st43 {
  font-size: 40px;
}
.st44 {
  fill: #fff;
  stroke: #000;
  stroke-width: 1;
}
.st45 {
  font-size: 35px;
}

.st48 {
  font-size: 45px;
}
.st46 {
  font-size: 18px;
}

.me {
  fill: orange;
  stroke: #000;
  stroke-width: 1;
}
.hi {
  fill: orangered;
  stroke: #000;
  stroke-width: 1;
}
.hihi {
  fill: red;
  stroke: #000;
  stroke-width: 1;
  animation: fadeIn 1s ease-in-out infinite;
}

.th {
  font-size: 18px;
  animation: fadeIn 1s ease-in-out infinite;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.water {
  opacity: 0.76;
  fill: #00aeef;
  animation: waterout 5s ease-in-out both;
}

@keyframes waterout {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.76;
  }
}
</style>
