import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../layout/Home.vue";

import Dashboard from "../views/Dashboard.vue";
import ControlSystem from "../views/ControlSystem.vue";
import Events from "../views/events.vue";
import Reports from "../views/Reports.vue";
import Accounts from "../views/Accounts.vue";

// autrorize
import Login from "../views/Login.vue";
import ResetPassword from "../views/ResetPassword.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { allowAnonymous: true },
    children: [
      {
        path: "",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "/ControlSystem",
        name: "ControlSystem",
        component: ControlSystem,
      },
      {
        path: "/events",
        name: "Events",
        component: Events,
      },
      {
        path: "/reports",
        name: "Reports",
        component: Reports,
      },
      {
        path: "/accounts",
        name: "Accounts",
        component: Accounts,
      },
    ],
  },

  // {
  //   path: "/login",
  //   name: "Login",
  //   component: Login,
  //   meta: {
  //     allowAnonymous: true,
  //   },
  // },

  {
    path: "/resetPassword",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      allowAnonymous: true,
    },
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
