<template>
  <div v-if="loading" v-resize="onResize">
    <v-row>
      <v-progress-linear
        v-if="!loading"
        absolute
        color="#1bb394"
        :size="100"
        :indeterminate="loading"
      >
      </v-progress-linear>
    </v-row>
  </div>
  <div v-else v-resize="onResize">
    <v-card flat id="card" class="ma-0 pa-0">
      <v-row justify="center">
        <!-- graphic station -->
        <v-col v-if="getAccess == null" cols="12" id="card_content">
          <v-card flat elevation="4" class="ma-0 pa-0">
            <v-card-title class="d-flex justify-center mb-5">
              <h4>การทำงานสถานีสูบน้ำ {{ getOneStation.name }}</h4>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="8" lg="9">
                  <Station49 v-if="this.station.macAddress == 'Sukhumvit49'" />
                  <Station40 v-if="this.station.macAddress == 'Sukhumvit40'" />
                  <Station55 v-if="this.station.macAddress == 'Sukhumvit55'" />
                  <Station71 v-if="this.station.macAddress == 'Sukhumvit71'" />
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="3">
                  <!-- <v-card class="pb-2" style="border-radius: 10px"> -->
                  <Sumary />
                  <!-- </v-card> -->
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-else cols="12" xs="12" sm="7" md="7" lg="7" class="mt-1">
          <v-card
            elevation="4"
            :min-height="
              this.station.macAddress === 'Sukhumvit55' ? '912' : '864'
            "
            id="card_content"
            v-if="!isMobile"
          >
            <v-card-title class="d-flex justify-center mx-2">
              <h4>แผงควบคุมระบบสูบน้ำ {{ getOneStation.name }}</h4>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <Station40 v-if="this.station.macAddress == 'Sukhumvit40'" />
                  <Station49 v-if="this.station.macAddress == 'Sukhumvit49'" />
                  <Station55 v-if="this.station.macAddress == 'Sukhumvit55'" />
                  <Station71 v-if="this.station.macAddress == 'Sukhumvit71'" />
                </v-col>
                <v-col cols="12">
                  <div>
                    <Sumary />
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card v-else elevation="4" id="card_content">
            <v-card-title class="d-flex justify-center mb-1">
              <h4>แผงควบคุมระบบสูบน้ำ {{ getOneStation.name }}</h4>
            </v-card-title>
            <v-card-text class="mt-0">
              <v-row>
                <v-col cols="12">
                  <Station40 v-if="this.station.macAddress == 'Sukhumvit40'" />
                  <Station49 v-if="this.station.macAddress == 'Sukhumvit49'" />
                  <Station55 v-if="this.station.macAddress == 'Sukhumvit55'" />
                  <Station71 v-if="this.station.macAddress == 'Sukhumvit71'" />
                </v-col>
                <v-col cols="12">
                  <Sumary />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- control pond -->
        <v-col id="card_control" cols="12" xs="12" sm="4" md="4" lg="3">
          <ControlPond v-if="currentUser" />
        </v-col>
      </v-row>
    </v-card>

    <v-overlay
      :class="isMobile == true ? 'mt-16' : 'mt-16'"
      opacity="0.8"
      :value="overlay"
      v-if="!loading"
    >
      <v-row align="center" justify="center">
        <v-col cols="12" sm="5" xs="5" class="d-flex justify-center">
          <v-img
            max-width="150"
            :src="require('../assets/warning.png')"
            v-if="!isMobile"
          />
          <v-img
            max-width="100"
            :src="require('../assets/warning.png')"
            v-else
          />
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <h3 v-if="!isMobile" style="color: white">
            ระบบ SCADA Web ไม่สามารถเชื่อมต่อได้ โปรดตรวจสอบอุปกรณ์ในพื้นที่
          </h3>
          <h5 v-else style="color: white; justify-item: center">
            <span>ระบบ SCADA Web ไม่สามารถเชื่อมต่อได้</span>
            <span>โปรดตรวจสอบอุปกรณ์ในพื้นที่</span>
          </h5>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <v-btn color="#1bb394" x-large to="/"> กลับสู่หน้าหลัก </v-btn>
        </v-col>
      </v-row>
    </v-overlay>
  </div>
</template>

<script>
import Station40 from "@/components/station/station_40.vue";
import Station49 from "@/components/station/station_49.vue";
import Station55 from "@/components/station/station_55.vue";
import Station71 from "@/components/station/station_71.vue";
import ControlPond from "@/components/control.pond.vue";
import Sumary from "@/components/sumary.vue";

import Cookies from "js-cookie";

export default {
  name: "WatercontrolAppControlSystem",

  components: {
    Station40,
    Station49,
    Station55,
    Station71,
    ControlPond,
    Sumary,
  },

  data() {
    return {
      station: {
        id: "",
        macAddress: "",
      },
      isMobile: false,
      zIndex: null,
      opacity: 0,
      loading: true,
    };
  },

  computed: {
    getAccess() {
      return this.$store.state.auth.access;
    },

    currentUser() {
      return this.$store.state.auth.user;
    },
    getOneStation() {
      return this.$store.getters.station;
    },
    overlay() {
      // console.log(this.$store.getters.station.status);
      return !this.$store.getters.station.status;
    },
  },
  async created() {
    //this.$store.dispatch("getStations");

    // console.log(this.$route.params.data);
    if (this.$route.params.data != null) {
      let station = this.$route.params.data;
      Cookies.set("stationID", station._id);
      Cookies.set("macAddress", station.macAddress);
      if (this.station.id != undefined) {
        this.$store.dispatch("getOneStation", this.station.id).then(() => {
          this.loading = false;
        });
      }
    } else {
      this.station.id = Cookies.get("stationID");
      this.station.macAddress = Cookies.get("macAddress");
      if (this.station.id != undefined) {
        this.$store.dispatch("getOneStation", this.station.id).then(() => {
          this.loading = false;
        });
      }
    }
  },
  // async mounted() {
  //   // this.$store.dispatch("getStations");
  //   // this.$store.dispatch("getOneStation", this.station.id);
  //   // this.$store.dispatch("getStations");
  //   // if (!this.currentUser) {
  //   //   this.$router.push("/login");
  //   // }
  // },

  methods: {
    onResize() {
      if (window.innerWidth < 800) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    changePage() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
#card_content {
  opacity: 0;
  transform: 20px;
  animation: slideUp 0.8s ease-in forwards 0.2s;
}

@keyframes slideUp {
  0% {
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
</style>
