<template>
  <div v-if="loader">
    <v-progress-linear
      v-if="loader"
      :size="100"
      color="#1bb394"
      :indeterminate="loader"
      absolute
      top
      class="mb-2"
    >
    </v-progress-linear>
  </div>

  <div v-else>
    <div style="justify-content: center">
      <v-card flat>
        <v-row align="center" justify="space-around">
          <v-col cols="12" xs="12" sm="12" md="12" lg="6">
            <v-card elevation="4">
              <v-card-title class="d-flex justify-end">
                <h6 class="mt-5 mb-5">{{ getChart.text }}</h6>
                <v-spacer></v-spacer>
                <v-btn-toggle mandatory v-model="toggle_exclusive">
                  <v-btn small @click="setDates(days[0])">1 วัน</v-btn>
                  <v-btn small @click="setDates(days[1])">3 วัน</v-btn>
                  <v-btn small @click="setDates(days[2])">7 วัน</v-btn>

                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="dates"
                    transition="slide-y-transition"
                    left
                    offset-y
                    min-width="auto"
                    style="z-index: 20"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small v-bind="attrs" v-on="on">
                        <v-icon>mdi-calendar</v-icon>
                        <!-- range -->
                      </v-btn>
                    </template>
                    <v-date-picker
                      v-model="dates"
                      color="#1bb394"
                      range
                      scrollable
                      no-title
                    >
                      <v-spacer></v-spacer>
                      <v-btn text @click="cancel()">Cancel</v-btn>
                      <v-btn text color="#1bb394" @click="searchDate()">
                        Ok
                      </v-btn>
                    </v-date-picker>
                  </v-menu>

                  <v-menu
                    v-if="
                      getAccess != null &&
                      (getAccess.role == 'Admin' ||
                        getAccess.role == 'Superadmin')
                    "
                    min-width="auto"
                    left
                    offset-y
                    style="z-index: 20"
                    transition="slide-y-transition"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ attrs, on }">
                      <v-btn small v-bind="attrs" v-on="on">
                        <v-icon>mdi-wrench-outline</v-icon>
                      </v-btn>
                    </template>
                    <v-card class="pa-2">
                      <v-card-text>
                        <v-text-field
                          color="#1bb394"
                          label="Set Time"
                          suffix="min"
                          v-model="setTimeLog.timeLog"
                        ></v-text-field>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          small
                          block
                          color="#1bb394"
                          dark
                          @click="setTime()"
                        >
                          set now
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </v-btn-toggle>
              </v-card-title>
              <v-card-text>
                <div class="justify-center text-center">
                  <v-progress-circular
                    v-if="loadingChart"
                    indeterminate
                    color="teal"
                    style="position: relative; top: 20vh; z-index: 2"
                  ></v-progress-circular>
                </div>
                <apexchart
                  class="d-flex justify-center"
                  ref="chart"
                  type="line"
                  :options="getChart.options"
                  :series="getChart.series"
                  :loading="loading"
                ></apexchart>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="12" lg="6">
            <v-card elevation="4">
              <v-card-title class="d-flex justify-end">
                <h6 class="mt-5 mb-5">{{ getTime.text }}</h6>
                <v-spacer></v-spacer>
                <v-menu
                  style="z-index: 20"
                  ref="menu3"
                  v-model="menu3"
                  :close-on-content-click="false"
                  :return-value.sync="dates"
                  transition="slide-y-transition"
                  left
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-calendar</v-icon>
                      <!-- range -->
                    </v-btn>
                  </template>
                  <v-date-picker
                    v-model="dates"
                    color="#1bb394"
                    range
                    scrollable
                    no-title
                  >
                    <v-spacer></v-spacer>
                    <v-btn text @click="cancel()">Cancel</v-btn>
                    <v-btn text color="#1bb394" @click="searchDateTime()">
                      Ok
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-card-title>
              <v-card-text>
                <div class="justify-center text-center">
                  <v-progress-circular
                    v-if="loadingTime"
                    indeterminate
                    color="teal"
                    style="position: relative; top: 20vh; z-index: 2"
                  ></v-progress-circular>
                </div>
                <apexchart
                  class="d-flex justify-center"
                  ref="chart"
                  type="bar"
                  :options="getTime.options"
                  :series="getTime.series"
                  :loading="loading"
                >
                </apexchart>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="12" lg="6">
            <v-card elevation="4">
              <v-card-title class="d-flex justify-end">
                <h6 class="mt-5 mb-5">{{ getFlow.text }}</h6>
                <v-spacer></v-spacer>
                <v-menu
                  style="z-index: 20"
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :return-value.sync="dates"
                  transition="slide-y-transition"
                  left
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-calendar</v-icon>
                      <!-- range -->
                    </v-btn>
                  </template>
                  <v-date-picker
                    v-model="dates"
                    color="#1bb394"
                    range
                    scrollable
                    no-title
                  >
                    <v-spacer></v-spacer>
                    <v-btn text @click="cancel()">Cancel</v-btn>
                    <v-btn text color="#1bb394" @click="searchDateFlow()">
                      Ok
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-card-title>
              <v-card-text>
                <div class="justify-center text-center">
                  <v-progress-circular
                    v-if="loadingFlow"
                    indeterminate
                    color="teal"
                    style="position: relative; top: 20vh; z-index: 2"
                  ></v-progress-circular>
                </div>
                <apexchart
                  class="d-flex justify-center"
                  ref="chart"
                  type="bar"
                  :options="getFlow.options"
                  :series="getFlow.series"
                  :loading="loading"
                ></apexchart>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <v-snackbar v-model="snackBar" top right :color="color">
      <v-row>
        <v-col cols="10"> {{ text }}</v-col>
        <v-col cols="2">
          <v-btn x-small color="white" icon @click="snackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  name: "WatercontrolAppReport",

  data() {
    return {
      loader: true,
      role: null,
      isMobile: false,
      textField: null,
      toggle_exclusive: 0,
      snackBar: false,
      text: "",
      color: "",
      loading: true,
      loadingChart: true,
      loadingTime: true,
      loadingFlow: true,
      menu3: false,
      menu2: false,
      menu: false,
      dateMenu: false,
      selectDate: false,
      stationID: null,
      dates: [],
      flowData: {
        macAddress: "",
        start: "",
        end: "",
      },
      timeData: {
        macAddress: "",
        start: "",
        end: "",
      },
      chartData: {
        station: "", //idstation
        type: "",
        start: "",
        end: "",
      },
      data: {
        id: "",
        macAddress: "",
      },
      days: ["1d", "3d", "7d"],
      setTimeLog: {
        id: "",
        timeLog: "",
      },
    };
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    getAccess() {
      console.log(this.$store.state.auth.access);
      return this.$store.state.auth.access;
    },
    getReports() {
      return this.$store.getters.reportStation;
    },
    getChart() {
      return this.$store.getters.charts;
    },
    getStation() {
      return this.$store.getters.station;
    },
    getFlow() {
      // console.log(this.$store.getters.records);
      return this.$store.getters.records;
    },
    getTime() {
      console.log(this.$store.getters.times);
      return this.$store.getters.times;
    },
    computedDateFormatted() {
      return this.formatDate(this.dates);
    },
  },

  async created() {
    // console.log(this.$route.params.data);

    this.chartData.station = Cookies.get("stationID");
    this.chartData.type = "1d";

    this.flowData.macAddress = Cookies.get("macAddress");

    this.timeData.macAddress = Cookies.get("macAddress");

    this.stationID = Cookies.get("stationID");
    console.log(this.chartData);
  },

  mounted() {
    this.$store.dispatch("auth/decode", this.role);

    this.$store.dispatch("getOneStation", this.stationID).then((res) => {
      this.setTimeLog.timeLog = res.timeLog;
    });
    this.$store.dispatch("getFlow", this.flowData).then((res) => {
      this.$store.dispatch("getTime", this.timeData).then((res) => {
        this.$store.dispatch("getCharts", this.chartData).then((res) => {
          this.loader = false;
          this.loadingChart = false;
          this.loadingTime = false;
          this.loadingFlow = false;
        });
      });
    });
  },

  methods: {
    loaderPage() {
      this.$store.dispatch("getFlow", this.flowData);
      this.$store.dispatch("getTime", this.timeData);
      this.$store.dispatch("getCharts", this.chartData);
      return false;
    },

    onResize() {
      if (window.innerWidth <= 600) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },

    cancel() {
      this.menu = false;
      this.menu2 = false;
      this.menu3 = false;
      this.dates = null;
    },

    searchDateTime() {
      this.loading = true;

      if (this.dates != "") {
        if (this.dates.length === 2) {
          const data = [this.dates[0], this.dates[1]];
          const dS = new Date(this.dates[0]);
          const yS = dS.getFullYear();
          const mS = dS.getMonth() + 1;
          const ddS = dS.getDate();
          const dE = new Date(this.dates[1]);
          const yE = dE.getFullYear();
          const mE = dE.getMonth() + 1;
          const ddE = dE.getDate();
          //console.log(mS);
          //console.log(mE);
          if (yS > yE) {
            this.dates[0] = data[1];
            this.dates[1] = data[0];
          } else if (yS == yE && mS > mE) {
            this.dates[0] = data[1];
            this.dates[1] = data[0];
          } else if (yS == yE && mS == mE && ddS > ddE) {
            this.dates[0] = data[1];
            this.dates[1] = data[0];
          }

          this.timeData.start = this.dates[0];
          this.timeData.end = this.dates[1];
          // this.flowData.station = this.data.macAddress;
          this.menu3 = false;
          this.loadingTime = true;
          this.$store.dispatch("getTime", this.timeData).then(() => {
            this.loadingTime = false;
          });
        } else {
          this.snackBar = true;
          this.text = "Error: Please choose date end.";
        }
      } else {
        this.menu3 = false;
        this.loadingTime = true;
        this.$store.dispatch("getTime", this.timeData).then(() => {
          this.loadingTime = false;
          this.loading = false;
        });
      }
    },

    searchDateFlow() {
      this.loading = true;
      if (this.dates != "") {
        if (this.dates.length === 2) {
          const data = [this.dates[0], this.dates[1]];
          const dS = new Date(this.dates[0]);
          const yS = dS.getFullYear();
          const mS = dS.getMonth() + 1;
          const ddS = dS.getDate();
          const dE = new Date(this.dates[1]);
          const yE = dE.getFullYear();
          const mE = dE.getMonth() + 1;
          const ddE = dE.getDate();
          //console.log(mS);
          //console.log(mE);
          if (yS > yE) {
            this.dates[0] = data[1];
            this.dates[1] = data[0];
          } else if (yS == yE && mS > mE) {
            this.dates[0] = data[1];
            this.dates[1] = data[0];
          } else if (yS == yE && mS == mE && ddS > ddE) {
            this.dates[0] = data[1];
            this.dates[1] = data[0];
          }
          //console.log(this.dates);
          this.flowData.start = this.dates[0];
          this.flowData.end = this.dates[1];
          // this.flowData.station = this.data.macAddress;
          this.menu2 = false;
          this.loadingFlow = true;
          this.$store.dispatch("getFlow", this.flowData).then(() => {
            this.loadingFlow = false;
          });
        } else {
          this.snackBar = true;
          this.text = "Error: Please choose date end.";
        }
      } else {
        this.menu2 = false;
        this.loadingFlow = true;
        this.$store.dispatch("getFlow", this.flowData).then(() => {
          this.loading = false;
          this.loadingFlow = false;
        });
      }
    },

    searchDate() {
      this.loading = true;
      if (this.dates != "") {
        if (this.dates.length === 2) {
          const data = [this.dates[0], this.dates[1]];
          const dS = new Date(this.dates[0]);
          const yS = dS.getFullYear();
          const mS = dS.getMonth() + 1;
          const ddS = dS.getDate();
          const dE = new Date(this.dates[1]);
          const yE = dE.getFullYear();
          const mE = dE.getMonth() + 1;
          const ddE = dE.getDate();
          //console.log(mS);
          //console.log(mE);
          if (yS > yE) {
            this.dates[0] = data[1];
            this.dates[1] = data[0];
          } else if (yS == yE && mS > mE) {
            this.dates[0] = data[1];
            this.dates[1] = data[0];
          } else if (yS == yE && mS == mE && ddS > ddE) {
            this.dates[0] = data[1];
            this.dates[1] = data[0];
          }
          this.chartData.start = this.dates[0];
          this.chartData.end = this.dates[1];
          this.chartData.type = "";
          // this.chartData.station = this.data.macAddress;
          this.menu = false;
          this.loadingChart = true;
          this.$store.dispatch("getCharts", this.chartData).then(() => {
            this.loadingChart = false;
          });
        } else {
          this.snackBar = true;
          this.text = "Error: Please choose date end.";
        }
      } else {
        this.menu = false;
        this.loadingChart = true;
        this.$store.dispatch("getCharts", this.chartData).then(() => {
          this.loading = false;
          this.loadingChart = false;
        });
      }
    },

    formatDate(dates) {
      if (!dates) return null;
      const dateFormatted = [];
      for (let i = 0; i < dates.length; i++) {
        dateFormatted[i] = this.parseDate(dates[i]);
      }
      if (dateFormatted.length === 2) {
        const [yearStart, monthStart, dayStart] = dateFormatted[0].split("-");
        const [yearEnd, monthEnd, dayEnd] = dateFormatted[1].split("-");
        const data = [dateFormatted[0], dateFormatted[1]];
        if (yearStart > yearEnd) {
          dateFormatted[0] = data[1];
          dateFormatted[1] = data[0];
        } else if (monthStart > monthEnd) {
          dateFormatted[0] = data[1];
          dateFormatted[1] = data[0];
        } else if (dayStart > dayEnd) {
          dateFormatted[0] = data[1];
          dateFormatted[1] = data[0];
        }
      } else if (dateFormatted.length === 1) {
        this.snackBar = true;
        this.text = "Error: Please choose date end.";
        this.color = "error";
      }
      return dateFormatted.join(" ~ ");
    },

    parseDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${year.padStart(2, "0")}-${month.padStart(2, "0")}-${day.padStart(
        2,
        "0"
      )}`;
      // return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
    },

    setDates(d) {
      console.log(d);
      this.loading = true;
      if (d == "1d") {
        this.chartData.type = d;
      } else if (d == "3d") {
        this.chartData.type = d;
      } else if (d == "7d") {
        this.chartData.type = d;
      }
      // this.chartData.macAddress =
      this.chartData.start = "";
      this.chartData.end = "";

      console.log(this.chartData);
      this.loadingChart = true;
      this.$store.dispatch("getCharts", this.chartData).then(() => {
        this.loading = false;
        this.loadingChart = false;
      });
    },

    setTime() {
      console.log(this.setTimeLog.timeLog);
      this.setTimeLog.id = this.stationID;
      this.loadingTime = true;
      this.$store.dispatch("setTime", this.setTimeLog).then(() => {
        this.loading = false;
        this.loadingTime = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-progress-circular {
  top: 1vh;
}
</style>
