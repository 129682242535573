import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import "@mdi/font/css/materialdesignicons.css";
import JsonExcel from "vue-json-excel";

import Notifications from "vue-notification";
import VueBreadcrumbs from "vue-2-breadcrumbs";
import VueApexCharts from "vue-apexcharts";

import VueSocketIO from "vue-socket.io";
import "./registerServiceWorker";
const socketURL = process.env.VUE_APP_SOCKET_URL;

Vue.use(
  new VueSocketIO({
    debug: false,
    connection: socketURL,
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_",
    },
  })
);
Vue.use(VueBreadcrumbs);
Vue.use(VueApexCharts);
Vue.use(Notifications);

Vue.component("apexchart", VueApexCharts);
Vue.component("downloadExcel", JsonExcel);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
