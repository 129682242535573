import axios from "axios";
import authHeader from "@/service/auth-header";

const apiURL = process.env.VUE_APP_API_URL + "events/";

export const events = {
  state: {
    events: [],
  },
  getters: {
    isEvents: (state) => state.events,
  },
  mutations: {
    getEvents(state, events) {
      state.events = events;
    },
  },
  actions: {
    async getEvents(context, id) {
      return axios
        .get(apiURL + id, { headers: authHeader() })
        .then((res) => {
          // console.log(res.data.event);
          context.commit("getEvents", res.data.event);
          return res.data.event;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    setClear(context, id) {
      return axios
        .delete(apiURL + "clear/" + id, { headers: authHeader() })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
